// @flow
import React, {Component} from 'react';
import './Admin.css';
import PageLayout from '../../../../Components/PageLayout/PageLayout';
import AdminPageContentWrap from '../../../../Components/AdminPageContentWrap/AdminPageContentWrap';
import {default as bgImage} from './Images/AdminBackground.jpg';
//import AuthenticationService from "../../../../Services/AuthenticationService";
import {AdminJiraReportsRoute} from "../../../../routeConfig";
import {AdminTelemetryRoute} from "../../../../routeConfig";

//let authenticationService = new AuthenticationService();


class Admin extends Component {

    props: {};

    static defaultProps = {};

    state: {

    };

    constructor(props: $PropertyType<Admin, 'props'>) {
        super(props);

        this.state = {
        };
    }



    // This is just the landing page for all other Admin pages.
    render() {
        return (
            <PageLayout caption="Admin" showBannerImage={true} backgroundImage={bgImage}>
                <AdminPageContentWrap>
                    <ul>
                        <li><a href={AdminTelemetryRoute.path}>Telemetry</a></li>
                        <li><a href={AdminJiraReportsRoute.path}>Jira Reports</a></li>
                    </ul>

                </AdminPageContentWrap>
            </PageLayout>
        );
    }




}

export default Admin;