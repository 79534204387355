// @flow
import React, {Component} from 'react';
import './AdminPageContentWrap.css';
import PortfolioLib from "goyette-portfolio-lib";
import AuthStatusProviderService from "../../Services/AuthStatusProviderService";
import AuthenticationService from "../../Services/AuthenticationService";
import utils from "../../utils";


let authenticationService = new AuthenticationService();

class AdminPageContentWrap extends Component {

    props: {
        children: any
    };


    static defaultProps = {};

    state: {
        authStatus: PortfolioLib.AuthStatusModel,
        authStatusProviderSubscriptionKey: string,

        adminUsername: string,
        adminPassword: string,

        loginTimer: any,
        loginWindow: window,
    };

    constructor(props: $PropertyType<AdminPageContentWrap, 'props'>) {
        super(props);


        this.state = {
            authStatus: AuthStatusProviderService.instance.authStatusModel,
            authStatusProviderSubscriptionKey: "",
            adminUsername: "",
            adminPassword: "",
            loginTimer: null,
            loginWindow: null
        };
    }


    componentWillUnmount() {
        AuthStatusProviderService.instance.unsubscribe(this.state.authStatusProviderSubscriptionKey);
    }

    componentDidMount() {
        this.setState({authStatusProviderSubscriptionKey: AuthStatusProviderService.instance.subscribe((newAuthStatus) => this.setAuthStatus(newAuthStatus))});
        this.updateAuthStatus();
    }


   setAuthStatus(newAuthStatus: PortfolioLib.AuthStatusModel) {
       this.setState({authStatus: newAuthStatus});
   }

    handleUsernameValueChange(event: Event) {
        if (event.target instanceof HTMLInputElement) {
            this.setState({adminUsername: event.target.value});
        }
    }

    handlePasswordValueChange(event: Event) {
        if (event.target instanceof HTMLInputElement) {
            this.setState({adminPassword: event.target.value});
        }
    }

    handleLoginSubmit(event) {
        event.preventDefault();
        authenticationService.login(this.state.adminUsername, this.state.adminPassword)
            .then(() => {
                console.log("Got a server login response")
                this.updateAuthStatus();
            })
            .catch((err) => {
                // TODO
                utils.notifyException(err, "Error getting authentication status");
            });
    }


    render() {
        return (
            <div >

                {!this.state.authStatus.isAuthenticated &&
                <div>
                    <h2>Error</h2>
                    <p>You must be logged in to view this page.</p>
                </div>
                }

                {this.state.authStatus.isAuthenticated && !this.state.authStatus.isAdmin &&
                <div>
                    <h2>Error</h2>
                    <p>You are not authorized to view this page.</p>
                </div>
                }




                { this.state.authStatus.isAdmin &&
                <div>
                    {this.props.children}
                </div>
                }

                <div>
                    {!this.state.authStatus.isAuthenticated &&
                        <div>
                            <form onSubmit={(e) => this.handleLoginSubmit(e)}>
                                <label>
                                    Username
                                    <input
                                        name="adminUsername"
                                        type="text"
                                        value={this.state.adminUsername}
                                        onChange={(e) => this.handleUsernameValueChange(e)}
                                    />
                                </label>
                                <br/>
                                <label>
                                    Password
                                    <input
                                        name="adminPassword"
                                        type="password"
                                        value={this.state.adminPassword}
                                        onChange={(e) => this.handlePasswordValueChange(e)}
                                    />
                                </label>
                                <br/>
                                <button type="submit" className="AdminPageContentWrap-dropdown-menu-item button" >
                                    <span>Login</span>
                                </button>
                            </form>
                        </div>
                    }

                    {this.state.authStatus.isAuthenticated &&
                    <button className="AdminPageContentWrap-dropdown-menu-item button" onClick={(e) => this.logout(e)}>
                        <span>Logout</span>
                    </button>
                    }
                </div>

            </div>
        );
    }





    updateAuthStatus() {
        authenticationService.getAuthStatus()
        .then((authStatus) => {
            this.setState({authStatus: authStatus || PortfolioLib.AuthStatusModel.Empty});
            AuthStatusProviderService.instance.notify(authStatus);
        })
        .catch((err) => {
            // TODO
            utils.notifyException(err, "Error getting authentication status");
        });
    }


    logout(e: Event) {
        e.preventDefault();
        e.stopPropagation();



        authenticationService.logout()
        .then((res, rej) => {
            // There's a minor delay here with setInterval to ensure the menu closes nicely.
            // It just looks ugly if logout executes too fast.
            this.setState({authStatus: PortfolioLib.AuthStatusModel.Empty},
                () => AuthStatusProviderService.instance.notify(this.state.authStatus));

        })
        .catch((err) => {
            utils.notifyException(err, "Error logging out.");
        });
    }


}


export default AdminPageContentWrap;