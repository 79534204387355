// @flow
import React, {Component} from 'react';
import './BlogViewPost.css';
import {Link} from 'react-router-dom';
import {FacebookProvider, Comments} from 'react-facebook';
import ReactRouter from 'react-router-dom'
import PortfolioLib from 'goyette-portfolio-lib';
import BlogService from '../../Services/BlogService';
import AuthStatusProviderService from '../../Services/AuthStatusProviderService';
import Utils from '../../utils';
import {GoPencil} from 'react-icons/go'
import ReactDocMeta from 'react-document-meta';

// For rendering
import '../../Components/BlogTagLink/BlogTagLink';
import AppConstants from "../../appConstants";


let blogService = new BlogService();

class BlogViewPost extends Component {
    props: {
        match: ReactRouter.Match
    };


    static defaultProps = {};

    state: {
        postId: ?number,
        longId: string,
        title: string,
        excerpt: string,
        bannerImageUrl: string,
        isLoadingPosts: boolean,
        rawBody: ?Component<*, *, *>,
        authStatus: PortfolioLib.AuthStatusModel,
        authStatusProviderSubscriptionKey: string
    };


    constructor(props: $PropertyType<BlogViewPost, 'props'>) {
        super(props);

        this.state = {
            postId: null,
            longId: "",
            title: (Utils.getApplication() === AppConstants.Applications.DanGoyette ?  "Dan Goyette Blog" : "Gravia Blog"),
            excerpt: "",
            bannerImageUrl: "",
            isLoadingPosts: true,
            rawBody: null,
            authStatus: AuthStatusProviderService.instance.authStatusModel,
            authStatusProviderSubscriptionKey: ""
        };
    }

    updateAuthStatus(newAuthStatus: PortfolioLib.AuthStatusModel) {
        this.setState({authStatus: newAuthStatus});
    }

    componentWillUnmount() {
        AuthStatusProviderService.instance.unsubscribe(this.state.authStatusProviderSubscriptionKey);
        window.prerenderReady = true;
    }

    componentDidMount() {
        window.prerenderReady = false;
        this.setState({authStatusProviderSubscriptionKey: AuthStatusProviderService.instance.subscribe((newAuthStatus) => this.updateAuthStatus(newAuthStatus))});

        this.loadPost(this.props);

        document.title = this.state.title;
    }


    componentWillReceiveProps(newProps: $PropertyType<BlogViewPost, 'props'>) {
        this.loadPost(newProps);
    }


    resetState() {
        this.setState({
            isLoadingPosts: false
        });
    }

    loadPost(props: $PropertyType<BlogViewPost, 'props'>) {

        this.setState({isLoadingPosts: true});

        blogService.fetchViewPostModel(this.props.match.params.postId)
            .then((model) => {
                this.resetState();
                let reactComponent = Utils.renderHtmlAsComponent(model.postId, model.longId, model.title, model.html, model.css, model.date, model.tags, model.bannerImageUrl,);

                // Now that we have a react component, we set it to the state.
                // Our render() method includes a "{this.state.outputPreview}", which causes the
                // component to be rendered.

                this.setState({
                    rawBody: reactComponent,
                    postId: model.postId,
                    longId: model.longId || "",
                    title: model.title || "",
                    bannerImageUrl: model.bannerImageUrl || "",
                    excerpt: model.excerpt || ""},
                    () => {
                        setTimeout(() => {window.prerenderReady = true;}, 1000);
                    }
                    );
            })
            .catch((err) => {
                this.resetState();
                Utils.notifyException(err, "Error fetching Post content");
            });
    }


    render() {

        let canonicalUrl = Utils.getCanonicalBlogUrl(this.state.postId, this.state.longId);

        let metaTags = [
            {name: "description", content: this.state.excerpt},
            {property: "og:title", content: this.state.title},
            {property: "og:type", content: "article"},
            {property: "og:description", content: this.state.excerpt},
            {property: "og:image", content: this.state.bannerImageUrl},
            {property: "og:url", content: canonicalUrl},
            {property: "fb:app_id", content: Utils.getFacebookAppId()}
        ];

        return (

            <ReactDocMeta tags={metaTags}>
                {this.state.postId &&
                <div>
                    <script async src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>

                    <ins className="adsbygoogle"
                         style={{display: 'block'}}
                         data-ad-client="ca-pub-9615262499156919"
                         data-ad-slot="5438237868"
                         data-ad-format="auto"></ins>
                    <script>
                        (adsbygoogle = window.adsbygoogle || []).push({});
                    </script>


                    {this.state.authStatus && this.state.authStatus.isAdmin &&
                    <div style={{textAlign: "right"}}>
                        <Link
                            to={PortfolioLib.SharedUtils.replaceRouteParam(PortfolioLib.SharedRoutes.UI.Blog.EditPost.Path, PortfolioLib.SharedRoutes.UI.Blog.EditPost.PostIdParam, this.state.postId)}>
                            <GoPencil className="icon-button-icon "/>
                        </Link>
                    </div>
                    }


                    {this.state.rawBody && this.state.rawBody}

                    <div className="spacer-row"/>
                    <div className="spacer-row"/>


                    <FacebookProvider appId={Utils.getFacebookAppId()}>
                        <Comments href={canonicalUrl} colorScheme="dark" width="100%"/>
                    </FacebookProvider>

                </div>
                }
            </ReactDocMeta>
        );
    }
}

export default BlogViewPost;
