// @flow
import Utils from '../utils';
import PortfolioLib from 'goyette-portfolio-lib';

class TelemetryService {

    fetchPlayerNames(): Promise<Array<PortfolioLib.KeyValuePairModel>> {
        return Utils.ajaxGet(Utils.getApiRoute(PortfolioLib.SharedRoutes.Server.Telemetry.TelemetryPlayerNames.Path))
            .then(res => {
                return res.data.map((rawValue) => {
                    let kvpModel = PortfolioLib.KeyValuePairModel.Empty;
                    Object.assign(kvpModel, rawValue);
                    return kvpModel;
                });
            })
            .catch((err) => {
                Utils.logAndRethrow(err, "Error fetching player names");
            });
    }

    fetchLevelIds(): Promise<Array<string>> {
        return Utils.ajaxGet(Utils.getApiRoute(PortfolioLib.SharedRoutes.Server.Telemetry.TelemetryLevelIds.Path))
        .then(res => {
            return res.data;
        })
        .catch((err) => {
            Utils.logAndRethrow(err, "Error fetching level IDs");
        });
    }

    fetchTelemetryTabularData(model: PortfolioLib.TelemetrySearchModel): Promise<Array<any>> {
        //console.log(model);
        return Utils.ajaxPost(Utils.getApiRoute(PortfolioLib.SharedRoutes.Server.Telemetry.TelemetryTabularData.Path), model)
        .then(res => {
            return res.data;
        })
        .catch((err) => {
            Utils.logAndRethrow(err, "Error fetching tabular data");
        });
    }



    fetchSecretFoundTabularData(): Promise<Array<any>> {
        return Utils.ajaxPost(Utils.getApiRoute(PortfolioLib.SharedRoutes.Server.Telemetry.SecretFoundTabularData.Path))
        .then(res => {
            return res.data;
        })
        .catch((err) => {
            Utils.logAndRethrow(err, "Error fetching tabular data");
        });
    }


    fetchCutscenePlayedTabularData(): Promise<Array<any>> {
        return Utils.ajaxPost(Utils.getApiRoute(PortfolioLib.SharedRoutes.Server.Telemetry.CutscenePlayedTabularData.Path))
            .then(res => {
                return res.data;
            })
            .catch((err) => {
                Utils.logAndRethrow(err, "Error fetching tabular data");
            });
    }


    fetchTelemetryLevelCompletionAnalysisData(model: PortfolioLib.TelemetrySearchModel): Promise<Array<any>> {
        return Utils.ajaxPost(Utils.getApiRoute(PortfolioLib.SharedRoutes.Server.Telemetry.TelemetryLevelCompletionAnalysisData.Path), model)
        .then(res => {
            return res.data;
        })
        .catch((err) => {
            Utils.logAndRethrow(err, "Error fetching level completion data");
        });
    }

    fetchTelemetryPlayerRetentionAnalysisData(model: PortfolioLib.TelemetrySearchModel): Promise<Array<any>> {
        return Utils.ajaxPost(Utils.getApiRoute(PortfolioLib.SharedRoutes.Server.Telemetry.TelemetryPlayerRetentionAnalysisData.Path), model)
        .then(res => {
            return res.data;
        })
        .catch((err) => {
            Utils.logAndRethrow(err, "Error fetching player retention data");
        });
    }

    downloadTelemetryBinaryData(model: PortfolioLib.TelemetrySearchModel, fileName: ?string): Promise<PortfolioLib.ActionCompleteModel> {
        //console.log(model);
        return Utils.ajaxPost(Utils.getApiRoute(PortfolioLib.SharedRoutes.Server.Telemetry.TelemetryBinaryData.Path), model)
        .then(res => {
            console.log(res.data);
            console.log(res.data.numberOfRecords + " records downloaded");
            if (res.data.numberOfRecords === 0) {
                alert("No data was found matching these filters.");
            } else {
                const linkSource = `data:application/zip;base64,${res.data.fileContents}`;
                const downloadLink = document.createElement("a");
                downloadLink.href = linkSource;
                downloadLink.download = fileName + ".zip";
                downloadLink.click();
            }
        })
        .catch((err) => {
          //  Utils.logAndRethrow(err, "Error downloading telemetry data");
        });
    }
}

export default TelemetryService
