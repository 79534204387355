// @flow
import React, {Component} from 'react';
import './GameDev.css';
import PageLayout from '../../../Components/PageLayout/PageLayout'
import {default as bgImage} from './Images/Myvatn.jpg';
import {default as cubeDropImage} from './Images/CubeDropIcon.jpg';
import {default as graviaLogo} from './Images/GraviaLogoLarge.jpg';
import {Link} from 'react-router-dom'
import {DanGoyetteCubeDropRoute} from '../../../routeConfig.js';
import ExternalLink from '../../../Components/ExternalLink/ExternalLink'


class GameDev extends Component {
    render() {
        return (
            <div>
                <PageLayout caption="Game Development" backgroundImage={bgImage} opacity={0.6} grayscale={0.0}>

                    <h2>Games:</h2>
                    <div className="GameDev-item-container">
                        <GameLink url="https://www.graviagame.com" label="Gravia" image={graviaLogo}/>
                        <GameLink route={DanGoyetteCubeDropRoute.path} label="Cube-Drop" image={cubeDropImage}/>
                    </div>

                </PageLayout>
            </div>
        );
    }
}


class GameLink extends Component {
    props: {
        url: string,
        route: string,
        label: string,
        image: any
    };

    render() {
        return (

            <div className="GameDev-item">
                {this.props.route &&
                <Link to={this.props.route}>
                    <div className="GameDev-item-wrap">

                        <div className="GameDev-item-icon">
                            <img className="GameDev-item-icon-image" alt={this.props.label} src={this.props.image}/>
                        </div>

                        <p className="GameDev-item-label">{this.props.label}</p>
                    </div>
                </Link>
                }
                {this.props.url &&
                <ExternalLink url={this.props.url} showIcon={false}>
                    <div className="GameDev-item-wrap">

                        <div className="GameDev-item-icon">
                            <img className="GameDev-item-icon-image" alt={this.props.label} src={this.props.image}/>
                        </div>

                        <p className="GameDev-item-label">{this.props.label}</p>
                    </div>
                </ExternalLink>
                }
            </div>

        );
    }
}

export default GameDev;