// @flow
import React, {Component} from 'react';
import './CubeDrop.css';
import PageLayout from '../../../Components/PageLayout/PageLayout'
import {default as bgImage} from './Images/Banner.jpg';
import ExternalLink from '../../../Components/ExternalLink/ExternalLink';
import YouTubeVideo from '../../../Components/YouTubeVideo/YouTubeVideo';
import ImagePreview from '../../../Components/ImagePreview/ImagePreview';

import {default as iceImageThumb} from './Images/Thumbs/Ice_tn.jpg';
import {default as iceImage} from './Images/Ice.jpg';

import {default as fireImageThumb} from './Images/Thumbs/Fire_tn.jpg';
import {default as fireImage} from './Images/Fire.jpg';

import {default as levelSelectionImageThumb} from './Images/Thumbs/LevelSelection_tn.jpg';
import {default as levelSelectionImage} from './Images/LevelSelection.jpg';

import {default as mainMenuImageThumb} from './Images/Thumbs/MainMenu_tn.jpg';
import {default as mainMenuImage} from './Images/MainMenu.jpg';

import {default as simpleMatchesImageThumb} from './Images/Thumbs/SimpleMatches_tn.jpg';
import {default as simpleMatchesImage} from './Images/SimpleMatches.jpg';




import {default as AppIcon} from './Images/CubeDropAppIcon.jpg';
import {default as AppIconThumb} from './Images/Thumbs/CubeDropAppIcon_tn.jpg';
import {default as CubeDropLogo} from './Images/CubeDropLogo.jpg';
import {default as CubeDropLogoThumb} from './Images/Thumbs/CubeDropLogo_tn.jpg';
import {default as CubeDropTitle} from './Images/CubeDropTitle.jpg';
import {default as CubeDropTitleThumb} from './Images/Thumbs/CubeDropTitle_tn.jpg';
import {default as Fire01} from './Images/Fire01.jpg';
import {default as Fire01Thumb} from './Images/Thumbs/Fire01_tn.jpg';
import {default as Fire02} from './Images/Fire02.jpg';
import {default as Fire02Thumb} from './Images/Thumbs/Fire02_tn.jpg';
import {default as FlashFreeze01} from './Images/FlashFreeze01.jpg';
import {default as FlashFreeze01Thumb} from './Images/Thumbs/FlashFreeze01_tn.jpg';
import {default as FlashFreeze02} from './Images/FlashFreeze02.jpg';
import {default as FlashFreeze02Thumb} from './Images/Thumbs/FlashFreeze02_tn.jpg';
import {default as GoldCubes} from './Images/GoldCubes.jpg';
import {default as GoldCubesThumb} from './Images/Thumbs/GoldCubes_tn.jpg';
import {default as HardMode} from './Images/HardMode.jpg';
import {default as HardModeThumb} from './Images/Thumbs/HardMode_tn.jpg';
import {default as IAP_Cubes} from './Images/IAP_Cubes.jpg';
import {default as IAP_CubesThumb} from './Images/Thumbs/IAP_Cubes_tn.jpg';
import {default as IAP_Options} from './Images/IAP_Options.jpg';
import {default as IAP_OptionsThumb} from './Images/Thumbs/IAP_Options_tn.jpg';
import {default as IAP_PowerUps} from './Images/IAP_PowerUps.jpg';
import {default as IAP_PowerUpsThumb} from './Images/Thumbs/IAP_PowerUps_tn.jpg';
import {default as IceChallenge} from './Images/IceChallenge.jpg';
import {default as IceChallengeThumb} from './Images/Thumbs/IceChallenge_tn.jpg';
import {default as IceCubes} from './Images/IceCubes.jpg';
import {default as IceCubesThumb} from './Images/Thumbs/IceCubes_tn.jpg';
import {default as LevelSelection} from './Images/LevelSelection.jpg';
import {default as LevelSelectionThumb} from './Images/Thumbs/LevelSelection_tn.jpg';
import {default as MainMenu} from './Images/MainMenu.jpg';
import {default as MainMenuThumb} from './Images/Thumbs/MainMenu_tn.jpg';
import {default as MoltenCubes} from './Images/MoltenCubes.jpg';
import {default as MoltenCubesThumb} from './Images/Thumbs/MoltenCubes_tn.jpg';
import {default as Objectives01} from './Images/Objectives01.jpg';
import {default as Objectives01Thumb} from './Images/Thumbs/Objectives01_tn.jpg';
import {default as Objectives02} from './Images/Objectives02.jpg';
import {default as Objectives02Thumb} from './Images/Thumbs/Objectives02_tn.jpg';
import {default as Objectives03} from './Images/Objectives03.jpg';
import {default as Objectives03Thumb} from './Images/Thumbs/Objectives03_tn.jpg';
import {default as Objectives04} from './Images/Objectives04.jpg';
import {default as Objectives04Thumb} from './Images/Thumbs/Objectives04_tn.jpg';
import {default as Objectives05} from './Images/Objectives05.jpg';
import {default as Objectives05Thumb} from './Images/Thumbs/Objectives05_tn.jpg';
import {default as Obstacles} from './Images/Obstacles.jpg';
import {default as ObstaclesThumb} from './Images/Thumbs/Obstacles_tn.jpg';
import {default as SimpleMatches01} from './Images/SimpleMatches01.jpg';
import {default as SimpleMatches01Thumb} from './Images/Thumbs/SimpleMatches01_tn.jpg';
import {default as SimpleMatches02} from './Images/SimpleMatches02.jpg';
import {default as SimpleMatches02Thumb} from './Images/Thumbs/SimpleMatches02_tn.jpg';
import {default as SimpleMatches03} from './Images/SimpleMatches03.jpg';
import {default as SimpleMatches03Thumb} from './Images/Thumbs/SimpleMatches03_tn.jpg';
import {default as SimpleMatches04} from './Images/SimpleMatches04.jpg';
import {default as SimpleMatches04Thumb} from './Images/Thumbs/SimpleMatches04_tn.jpg';
import {default as SimpleMatches05} from './Images/SimpleMatches05.jpg';
import {default as SimpleMatches05Thumb} from './Images/Thumbs/SimpleMatches05_tn.jpg';
import {default as SimpleMatches06} from './Images/SimpleMatches06.jpg';
import {default as SimpleMatches06Thumb} from './Images/Thumbs/SimpleMatches06_tn.jpg';
import {default as StageFailed} from './Images/StageFailed.jpg';
import {default as StageFailedThumb} from './Images/Thumbs/StageFailed_tn.jpg';
import {default as Upgrades} from './Images/Upgrades.jpg';
import {default as UpgradesThumb} from './Images/Thumbs/Upgrades_tn.jpg';



class CubeDrop extends Component {
    render() {
        return (
            <PageLayout caption="Cube-Drop" backgroundImage={bgImage} opacity={0.2} grayscale={0.0}>


                <h2>Description</h2>
                <p>In Cube-Drop, you control a "Collector" in the middle of the screen. Cubes of different colors constantly move in from the left, the right, and from above. Drag and tap to move and rotate the Collector to catch the incoming cubes. Line up three of the same color to make a match and clear them out.</p>
                <p>Cube-Drop's controls are unique and satisfying. Dragging moves the Collector in six directions, while arrow buttons allow you to rotate the Collector in 3D. You don't control the incoming cubes, but you can control where they land by moving the Collector. It feels kind of like of like Tetris, in 3D, in reverse...</p>
                <p>At first, you’ll just be making matches, but as you progress the game introduces new themes and features: ice cubes that need to be smashed, fire that can spread to nearby cubes, and altered gravity that literally turns the game upside-down. Cube-Drop is a fast-paced game where each level has slightly different objectives and rules. Complete stages to unlock more difficult versions of the levels, or see how long you can last on “Endless” mode.</p>


                <h2>Fact Sheet</h2>
                <ul>
                    <li><span className="CubeDropPressKit-fact-sheet-title">Developer:</span> <span className="CubeDropPressKit-fact-sheet-value">Dan Goyette</span></li>
                    <li><span className="CubeDropPressKit-fact-sheet-title">Platforms:</span> <span className="CubeDropPressKit-fact-sheet-value">iOS, Android</span></li>
                    <li><span className="CubeDropPressKit-fact-sheet-title">Released:</span> <span className="CubeDropPressKit-fact-sheet-value">iOS: April 6, 2017; Android: April 10, 2017</span></li>
                    <li><span className="CubeDropPressKit-fact-sheet-title">Website:</span> <a href="https://www.dan-goyette.com/gameDev/Cube-Drop">https://www.dan-goyette.com/gameDev/Cube-Drop</a></li>
                    <li><span className="CubeDropPressKit-fact-sheet-title">Price:</span> <span className="CubeDropPressKit-fact-sheet-value">Free-to-play (In-app Purchases available)</span></li>
                    <li><span className="CubeDropPressKit-fact-sheet-title">Press Contact:</span> <span className="CubeDropPressKit-fact-sheet-value">dan.goyette@gmail.com</span></li>
                </ul>

                <h2>Store Links</h2>
                <ul>
                    <li><ExternalLink
                        url="https://itunes.apple.com/WebObjects/MZStore.woa/wa/viewSoftware?id=1208226854&mt=8">App
                        Store (iOS) (No Longer Published)</ExternalLink></li>
                    <li><ExternalLink url="https://play.google.com/store/apps/details?id=com.danGoyette.cubeDrop">Play
                        Store (Android)</ExternalLink></li>
                </ul>

                <h2>Features</h2>
                <ul>
                    <li>Cube-Drop's most distinctive feature is the 3D movement and rotation control. Catch incoming cubes by moving and rotating the "Collector" in three dimensions. (It feels a little like playing with a Rubik's Cube.)</li>
                    <li>Line up cubes of the same color to make a "match", which scores points and removes the cubes.</li>
                    <li>Challenging and fast-paced gameplay with distinct objectives and rules in each level.</li>
                    <li>
                        Five different "areas", each with their own levels, visual style, and distinct gameplay mechanics:
                        <ul style={{marginBottom: "0"}}>
                            <li>Simple Matches - Get the hang of Cube-Drop's core rules.</li>
                            <li>Obstacles - Indestructable blocks get in the way, and other restrictions make things more difficult.</li>
                            <li>Ice - "Ice" cubes fill the Collector, and Flash Freezes lock up the controls temporarily. Break the ice by hitting it with a cube.</li>
                            <li>Fire - "Molten" cubes ignite nearby cubes, and must be must be extinguished quickly to avoid the fire spreading.</li>
                            <li>Gravity - Gravity affects the cubes in the Collector, fundamentally changing the way you think about rotation.</li>
                        </ul>
                    </li>
                    <li>After you finish all stages in an area:
                        <ul style={{marginBottom: "0"}}>
                            <li>Harder versions of those levels are unlocked for added challenge.</li>
                            <li>"Endless" mode is unlocked. Each area has its own Endless mode, in three difficulties. Every few matches advances the level, increasing the speed of the cubes, and every few levels adds another type of cube. See how far you can get.</li>
                        </ul>
                    </li>
                    <li>
                        Earn "Gold Cubes" the first time a level is completed, and occasionally for making matches.
                        <ul style={{marginBottom: "0"}}>
                            <li>Use Gold Cubes to purchase "Upgrades", which are permanent effects to help you advance.</li>
                            <li>Gold Cubes can also be used to purchase one-time power-ups to assist you with a difficult level</li>

                        </ul>
                    </li>
                    <li>Free to play.</li>
                </ul>




                <h2>Videos</h2>

                <div className="CubeDropPressKit-video-container">
                    <div className="CubeDropPressKit-video">
                        <YouTubeVideo url="https://www.youtube.com/embed/NO_72de6KuA" title="App Store Preview"
                                      classes="CubeDropPressKit-embedded-video"/>
                        <p className="CubeDropPressKit-video-description">App Store Preview</p>
                    </div>

                    <div className="CubeDropPressKit-video">
                        <YouTubeVideo url="https://www.youtube.com/embed/oaS3YwaVxiQ" title="30+ Minute Gameplay footage"
                                      classes="CubeDropPressKit-embedded-video"/>
                        <p className="CubeDropPressKit-video-description">30+ Minute Gameplay footage</p>
                    </div>

                    <div className="CubeDropPressKit-video">
                        <YouTubeVideo url="https://www.youtube.com/embed/px3Eu-F6h6w" title="Cube-Drop Soundtrack"
                                      classes="CubeDropPressKit-embedded-video"/>
                        <p className="CubeDropPressKit-video-description">The complete soundtrack, with some gameplay footage</p>
                    </div>

                </div>


                <div className="spacer-row" />
                <h2>Screenshots</h2>

                <div className="CubeDrop-screenshot-container">

                    <ImagePreview imagePath={CubeDropTitle} thumbnailPath={CubeDropTitleThumb} caption="The Cube-Drop Logo / Splash Screen"/>
                    <ImagePreview imagePath={MainMenu} thumbnailPath={MainMenuThumb} caption="Main Menu"/>
                    <ImagePreview imagePath={LevelSelection} thumbnailPath={LevelSelectionThumb} caption="Level selection for the first group of stages"/>
                    <ImagePreview imagePath={HardMode} thumbnailPath={HardModeThumb} caption="Level selection for the first group of stages, with 'Hard' mode unlocked"/>
                    <ImagePreview imagePath={SimpleMatches01} thumbnailPath={SimpleMatches01Thumb} caption="Gameplay in the 'Simple Matches' group of stages"/>
                    <ImagePreview imagePath={SimpleMatches02} thumbnailPath={SimpleMatches02Thumb} caption="Gameplay in the 'Simple Matches' group of stages"/>
                    <ImagePreview imagePath={SimpleMatches03} thumbnailPath={SimpleMatches03Thumb} caption="Gameplay in the 'Simple Matches' group of stages"/>
                    <ImagePreview imagePath={SimpleMatches04} thumbnailPath={SimpleMatches04Thumb} caption="Gameplay in the 'Simple Matches' group of stages"/>
                    <ImagePreview imagePath={SimpleMatches05} thumbnailPath={SimpleMatches05Thumb} caption="Gameplay in the 'Simple Matches' group of stages"/>
                    <ImagePreview imagePath={SimpleMatches06} thumbnailPath={SimpleMatches06Thumb} caption="Gameplay in the 'Simple Matches' group of stages"/>
                    <ImagePreview imagePath={StageFailed} thumbnailPath={StageFailedThumb} caption="You see this when you lose"/>
                    <ImagePreview imagePath={GoldCubes} thumbnailPath={GoldCubesThumb} caption="Gold cubes, the in-game currency, being awarded for completing a level"/>
                    <ImagePreview imagePath={Upgrades} thumbnailPath={UpgradesThumb} caption="Spend gold cubes here to acquire upgrades"/>
                    <ImagePreview imagePath={IAP_Cubes} thumbnailPath={IAP_CubesThumb} caption="Players can buy more cubes via in-app purchases"/>
                    <ImagePreview imagePath={IAP_PowerUps} thumbnailPath={IAP_PowerUpsThumb} caption="Power-ups can also be purchased for gold cubes"/>
                    <ImagePreview imagePath={IAP_Options} thumbnailPath={IAP_OptionsThumb} caption="Some one-time IAPs to remove ads or speed up life regeneration"/>
                    <ImagePreview imagePath={Obstacles} thumbnailPath={ObstaclesThumb} caption="Gameplay in the 'Obstacles' group of stages"/>
                    <ImagePreview imagePath={IceCubes} thumbnailPath={IceCubesThumb} caption="The 'Ice' stages feature ice cubes which must can be shattered"/>
                    <ImagePreview imagePath={IceChallenge} thumbnailPath={IceChallengeThumb} caption="A particularly complicated 'Ice' level"/>
                    <ImagePreview imagePath={FlashFreeze01} thumbnailPath={FlashFreeze01Thumb} caption="The 'Flash Freeze' effect about to occur..."/>
                    <ImagePreview imagePath={FlashFreeze02} thumbnailPath={FlashFreeze02Thumb} caption="A 'Flash Freeze' adds ice cubes to the Collector, and locks it up for a moment"/>
                    <ImagePreview imagePath={MoltenCubes} thumbnailPath={MoltenCubesThumb} caption="The 'Fire' stages feature molten cubes, which catch other cubes on fire"/>
                    <ImagePreview imagePath={Fire01} thumbnailPath={Fire01Thumb} caption="A few molten cubes in the Collector"/>
                    <ImagePreview imagePath={Fire02} thumbnailPath={Fire02Thumb} caption="Things have gotten out of hand here with the molten cubes..."/>
                    <ImagePreview imagePath={Objectives01} thumbnailPath={Objectives01Thumb} caption="Some of the different objectives and status effects in Cube-Drop"/>
                    <ImagePreview imagePath={Objectives02} thumbnailPath={Objectives02Thumb} caption="Some of the different objectives and status effects in Cube-Drop"/>
                    <ImagePreview imagePath={Objectives03} thumbnailPath={Objectives03Thumb} caption="Some of the different objectives and status effects in Cube-Drop"/>
                    <ImagePreview imagePath={Objectives04} thumbnailPath={Objectives04Thumb} caption="Some of the different objectives and status effects in Cube-Drop"/>
                    <ImagePreview imagePath={Objectives05} thumbnailPath={Objectives05Thumb} caption="Some of the different objectives and status effects in Cube-Drop"/>
                </div>


                <div className="spacer-row" />
                <h2>Logos and Icons</h2>
                <div className="CubeDropPressKit-screenshot-container">
                    <ImagePreview thumbnailPath={CubeDropLogoThumb} imagePath={CubeDropLogo}
                                  caption="Cube-Drop Logo / Title Card"/>
                    <ImagePreview thumbnailPath={AppIconThumb} imagePath={AppIcon}
                                  caption="Cube-Drop App Icon"/>
                </div>


                <div className="spacer-row" />
                <h2>All Assets</h2>
                <p>Download all of the assets from this page in PNG and JPG format: <a href="/Files/CubeDropPressKitAssets.zip"
                                                                                       download="CubeDropPressKitAssets.zip">Cube-Drop PressKit Assets</a></p>


                <h2>The Developer</h2>
                <p>I am Dan Goyette, the developer of Cube-Drop. I've been a professional software engineer since 2002, and got into game development in 2016. I designed and built Cube-Drop, and composed the music. I'm currently working on my new game, <a href="https://www.graviagame.com">Gravia</a>.</p>



                <h2>Developing Cube-Drop</h2>


                <ImagePreview thumbnailPath={simpleMatchesImageThumb} imagePath={simpleMatchesImage} floatRight={true}
                              caption="An early Cube-Drop level, where the player just has to make some matches."/>

                <p>I developed Cube-Drop in Unity, with most of the development effort taking place between February and
                    April of 2017. Coding the game itself was fairly straightforward, since there isn't that much
                    complexity to how things interact. For the most part, the incoming cubes need to detect collisions
                    with other cubes in the Collector and with the Collector itself. Movement and Rotation of the
                    Collector is performed using simple translation and rotation. All of the levels in
                    Cube-Drop are defined as individual C# classes that implement a common base class which supports
                    all of the configurable properties of a level (for example, the different kinds of cubes used on
                    that level, the win conditions, and other status effects in play). In that sense, adding a new level
                    requires, minimally, the creation of a single C# class.</p>


                <p>There were two primary challenges in developing Cube-Drop, and the vast majority of development
                    effort
                    went towards these two areas: Fine-tuning the controls, and crafting the initial user
                    experience.</p>


                <div className="clear-both" />
                <h2>Controls</h2>



                <p>The idea for Cube-Drop came to me in a brainstorming session: "Move and rotate a box in 3D and catch
                    incoming blocks." It was simple enough to describe, but it wasn't at all obvious that such a game
                    would be either playable or enjoyable. I budgetted a few days to build a prototype to test out the
                    core concept. I found that although there was a bit of a learning curve, once I got used to it I
                    could
                    move the Collector pretty easily. But there would be a lot of refinement to come.</p>

                <ImagePreview thumbnailPath={mainMenuImageThumb} imagePath={mainMenuImage} floatLeft={true}
                              caption="The Cube-Drop Main Menu, which is a combination of 2D and 3D elements."/>

                <p>The very first version of Cube-Drop handled movement and rotation entirely with a single finger.
                    Dragging relatively slowly in a direction would move the Collector in that direction. Dragging
                    quickly (swiping) would rotate the Collection instead. This felt okay in the prototype, but it
                    started to fall apart as the levels got faster. Quick drags were getting treated as swipes, causing
                    rotation when the player intended movement. Given
                    that 3D motion and rotation is already complex enough to think about, I wanted to ensure that the
                    controls were "perfect", in the sense that they were always reliable, and responded exactly
                    as expected. (It's very annoying to perform an action, and for something different to happen than
                    you intended.)</p>

                <p>The next iteration explored using 2-finger swipes for rotating. This initially seemed pretty good to
                    me. However, there were two issues. First, when executing a 2-finger swipe quickly, sometimes the
                    device didn't pick up both fingers, resulting in movment instead of rotation.
                    Second, cycling between 1- and 2-finger swiping proved to be cognitively challenging. Especially
                    when things were moving faster, I found it was relatively common
                    for me to rotate when I meant to move, and vice versa. This is because the actions were so similar:
                    they both involved touching and dragging in a direction.</p>



                <p>I had tried out button-based rotation as a test to see if one play-tester had an easier time with it.
                    I
                    initially disliked it, because I felt it was slower than swiping. But that was because of a fairly
                    critical mistake I made. At first, the rotation buttons were always in the same place on the screen.
                    If you moved the Collector around, the rotation arrows stayed fixed in one place. The result of this
                    decision was that the rotation buttons seemed "far" from the Collector, and it took time to
                    physically move my finger to the button. Also, because the button position was static, it didn't
                    reinforce how the button would behave when it was used. So I initially dismissed button-based
                    rotation as not the right approach.</p>

                <ImagePreview thumbnailPath={levelSelectionImageThumb} imagePath={levelSelectionImage} floatRight={true}
                              caption="Stages are organized into 'Groups' that each have a common theme and visual feel."/>
                <p>When the other control schemes weren't working out, I gave more thought to the buttons. It was
                    then that I attached the buttons directly to the Collector, so that they would move as the Collector
                    moved. This immediately improved the behavior. Now the buttons were close, and easy to access. And
                    they were physically placed at a spot that intuitively indicated how the Collector would rotate when
                    the button was pressed: press the button at the bottom-left to spin the Collector towards that
                    button.</p>


                <p>I iterated for a few weeks on the controls while building out the rest of the game. The whole time I
                    was concerned that I wouldn't find a control scheme that "just worked", and I would have to abandon
                    the project. But ultimately the controls responded very naturally, or as naturally as I think they
                    can
                    given the complexity of what I'm trying to allow.</p>


                <div className="clear-both" />
                <h2>Initial User Experience</h2>


                <p>Even more challenging than refining the controls was introducing new players to Cube-Drop in a way
                    that didn't discourage them. Cube-Drop isn't exactly simple or intuitive. There is a distinct
                    learning curve to the game. Much effort was put into smoothing out that curve.</p>

                <ImagePreview thumbnailPath={iceImageThumb} imagePath={iceImage} floatRight={true}
                              caption="Ice stages introduce frozen cubes which must be struck multiple times to shatter."/>

                <p>Early versions of Cube-Drop just put the user in complete control of the Collector and expected them
                    to make matches. This is reasonable for a prototype, but not for a finished game. After observing
                    several play testers first trying the game, I quickly realized I was overwhelming them with the
                    number of skills they needed to develop simultaneously:</p>

                <ul>
                    <li>General spacial awareness in a 3D environment</li>
                    <li>3D Movement</li>
                    <li>3D Rotation</li>
                    <li>Techniques for making matches</li>
                    <li>Responding quickly</li>
                </ul>


                <p>I spent a couple of weeks building a dedicated tutorial that introduced each of these ideas
                    individually, and I thought that would be sufficient. What I found, though, was that players really
                    needed a bit of time getting used to one thing before I could introduce a new game mechanic.
                    The initial tutorial asked the user to move the Collector a few times, and then immediately asked
                    them to rotate it. That was too much. Players seemed to get the hang of movement fairly quickly if
                    that's all they are asked to do, but
                    rotation is a much more complex task, cognitively.</p>

                <ImagePreview thumbnailPath={fireImageThumb} imagePath={fireImage} floatLeft={true}
                              caption="Fire stages feature 'Molten' cubes which gradually ignite nearby cubes, spreading quickly through the Collector."/>
                <p>So I broke the tutorial out into an entire series of levels, where the player initially
                    only moves the Collector. After a few levels of just moving the Collector I introduce the concept
                    of rotation, and only rotation around a single axis. After a few more levels do we introduce
                    full rotation, by which point players seem to be able to handle it. Early levels also move much
                    more slowly than later levels, giving new players plenty of time to see how their actions affect the
                    game.</p>


                <p>This approach led to much less player frustration and much less initial failure. However, an
                    unexpected downside to this approach is that was a little <em>too</em> slow for players who are
                    experienced gamers. The initial levels felt a bit sluggish and boring to players who already had
                    strong experience with 3D games. I didn't want experienced players to abandon the game after a few
                    minutes because it wasn't enticing enough.</p>

                <p>Ultimately, designing the new user experience of a game is absolutely critical. However, it often
                    comes fairly late in the development process. When starting development of a new game, I want to see
                    how the
                    concept will play out in more complex scenarios. I don't want to spend a lot of time building a
                    tutorial if I'm not sure where the game is even headed. But a player's experience in the first five
                    minutes of playing a game can determine whether they delete the game, or stick with it.</p>


            </PageLayout>
        );
    }
}


export default CubeDrop;