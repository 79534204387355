// @flow
import React, {Component} from 'react';
import './AdminJiraReports.css';
import PageLayout from '../../../../Components/PageLayout/PageLayout';
import WaitOverlay from '../../../../Components/WaitOverlay/WaitOverlay';
import {TiUpload} from 'react-icons/ti'
import {TiChartLine} from 'react-icons/ti'
import {TiTime} from 'react-icons/ti'
import {GoTools} from 'react-icons/go'
import Base64 from 'base-64';
import Xml2js from 'xml2js';
import utils from '../../../../utils';
import Moment from 'moment';
import classNames from 'classnames';
import Lodash from 'lodash';
import {BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, AreaChart, Area, Brush, } from 'recharts';
import AdminPageContentWrap from '../../../../Components/AdminPageContentWrap/AdminPageContentWrap';

class JiraWorkLogEntry {
    issueId: number;
    date: Date;
    doneBy: string;
    hours: number;
}

class JiraIssue {
    constructor() {
        this.fixVersions = [];
        this.workLogs = [];
    }

    id: number;
    issueKey: string;
    storyPoints: number;
    issueType: string;
    status: string;
    projectKey: string;
    createdDate: ?Date;
    updatedDate: ?Date;
    resolvedDate: ?Date;
    originalEstimate: ?number;
    remainingEstimate: ?number;
    timeSpent: number;
    sprint: string;
    fixVersions: string[];
    workLogs: JiraWorkLogEntry[];
}

class JiraFixVersion {
    id: number;
    name: string;
}

class TimeTrackingReportRow {
    date: Date;
    displayDate: string;
    dayOfWeek: string;
    weekNumber: number;
    isFirstDayOfWeek: bool;
    isLastDayOfWeek: bool;

    graviaHours: number;
    consultingHours: number;
    otherHours: number;

    weeklyGraviaHours: number;
    weeklyConsultingHours: number;
    weeklyOtherHours: number;

    weeklyTotalHours: number;
}

class StoryPointCompletionRow {
    date: Date;
    remainingStoryPoints: number;
    completedStoryPoints: number;
    totalStoryPoints: number;
}


class AdminJiraReports extends Component {

    props: {};

    static defaultProps = {};

    fileInput: HTMLInputElement;

    state: {
        newFileDataUri: any,
        newFileName: string,
        newFileType: string,

        isProcessing: boolean,

        selectedReportIndex: number,

        jiraIssues: JiraIssue[],
        timeTrackingReportRows: TimeTrackingReportRow[],
        storyPointCompletionRows: StoryPointCompletionRow[],
        currentMilestoneStoryPointCompletionRows: StoryPointCompletionRow[],
        fixVersions: JiraFixVersion[],
        daysOfWeek: string[],
        currentFixVersion: ?string,
        weekEndDay: string,
        hoursPerStoryPoint: number,
        totalHoursWorked: number,
        totalStoryPointsWorked: number
    };

    constructor(props: $PropertyType<AdminJiraReports, 'props'>) {
        super(props);


        this.state = {
            newFileDataUri: null,
            newFileName: "",
            newFileType: "",
            isProcessing: false,
            jiraIssues: [],
            timeTrackingReportRows: [],
            storyPointCompletionRows: [],
            currentMilestoneStoryPointCompletionRows: [],
            dailyTimeTrackingGraphData: [],
            weeklyTimeTrackingGraphData: [],
            selectedReportIndex: -1,
            fixVersions: [],
            daysOfWeek: [],
            currentFixVersion: null,
            weekEndDay: "Friday",
            hoursPerStoryPoint: 0,
            totalHoursWorked: 0,
            totalStoryPointsWorked: 0
        };
    }


    activateFileUpload() {
        this.fileInput.click();
    }

    render() {


        let report0classNames = classNames("AdminJiraReports-report-container-tab", this.state.selectedReportIndex === 0 ? "selected" : "");
        let report1classNames = classNames("AdminJiraReports-report-container-tab", this.state.selectedReportIndex === 1 ? "selected" : "");
        let report2classNames = classNames("AdminJiraReports-report-container-tab", this.state.selectedReportIndex === 2 ? "selected" : "");

        //let timeTrackingBarLabelFormatter = (val) => {
        //    if (val === 0 || val === "0") {
        //        return "";
        //    } else {
        //        return val;
        //    }
        //};

        return (
            <PageLayout caption="Admin Jira Reports" showBannerImage={false}>
                <AdminPageContentWrap>
                    <h3>
                        Jira Reports

                        <button className="icon-button" style={{marginLeft: "20px"}}
                                onClick={(e) => this.activateFileUpload()} title="Upload a Jira Issue Export XML file">
                            <TiUpload className="icon-button-icon"/>
                        </button>
                    </h3>

                    <input
                        style={{display: "none"}}
                        ref={(input) => {
                            this.fileInput = input;
                        }}
                        type="file"
                        accept=".xml"
                        onChange={(e) => this.handleFileInputChange(e)}/>


                    {this.state.jiraIssues.length > 0 &&
                    <div>
                        <p>The uploaded spreadsheet contains {this.state.jiraIssues.length} issues.</p>

                        <div>
                            <span>Fix Version: </span>
                            <select className="AdminJiraReports-fix-version-select"
                                    value={this.state.currentFixVersion}
                                    onChange={(e) => this.handleFixVersionChange(e)}>
                                <option key={-1} value={null}>All</option>
                                {this.state.fixVersions.map((fv, i) => {
                                    return <option key={fv.id} value={fv.name}>
                                        {fv.name}
                                    </option>
                                })}
                            </select>
                        </div>

                        <div>
                            <span>Weeks Ends On: </span>
                            <select className="AdminJiraReports-week-end-day-select"
                                    value={this.state.weekEndDay}
                                    onChange={(e) => this.handleWeekEndDayChange(e)}>
                                <option key={-1} value={null}>All</option>
                                {this.state.daysOfWeek.map((v) => {
                                    return <option key={v} value={v}>
                                        {v}
                                    </option>
                                })}
                            </select>

                        </div>


                        <div className="spacer-row"/>

                        <div className="AdminJiraReports-report-container">
                            <button className={report0classNames} onClick={() => this.setReportTabIndex(0)}>
                                <TiTime/>
                                <span>Time Tracking</span>
                            </button>
                            <button className={report1classNames} onClick={() => this.setReportTabIndex(1)}>
                                <TiChartLine/>
                                <span>Story Points</span>
                            </button>
                            <button className={report2classNames} onClick={() => this.setReportTabIndex(2)}>
                                <GoTools/>
                                <span>Effort Vs Estimate</span>
                            </button>


                        </div>
                        {this.state.selectedReportIndex === 0 &&
                        <div className="spacer-row">


                            <h3>Weekly Hours</h3>
                            <div className="AdminJiraReports-chart-container">
                                <BarChart width={1000} height={600}
                                          data={Lodash.filter(this.state.timeTrackingReportRows, (r) => r.isLastDayOfWeek)}
                                          margin={{top: 5, right: 30, left: 20, bottom: 100}}>
                                    <XAxis dataKey="displayDate" height={100} angle={-45} textAnchor="end"/>
                                    <YAxis/>
                                    <CartesianGrid strokeDasharray="3 3"/>
                                    <Tooltip/>
                                    <Legend verticalAlign="top"/>
                                    <Bar dataKey="weeklyGraviaHours" stackId="a" fill="#46094D"
                                         isAnimationActive={false}>
                                        {/*<LabelList dataKey="weeklyGraviaHours" position="insideTop"*/}
                                        {/*formatter={timeTrackingBarLabelFormatter}/>*/}
                                    </Bar>
                                    <Bar dataKey="weeklyConsultingHours" stackId="a" fill="#96094D"
                                         isAnimationActive={false}>
                                        {/*<LabelList dataKey="weeklyConsultingHours" position="insideTop"*/}
                                        {/*formatter={timeTrackingBarLabelFormatter}/>*/}
                                    </Bar>
                                    <Bar dataKey="weeklyOtherHours" stackId="a" fill="#4609FD"
                                         isAnimationActive={false}>
                                        {/*<LabelList dataKey="weeklyOtherHours" position="insideTop"*/}
                                        {/*formatter={timeTrackingBarLabelFormatter}/>*/}
                                    </Bar>
                                    <Bar dataKey="weeklyTotalHours" stackId="b" fill="#7679CD"
                                         isAnimationActive={false}>
                                        {/*<LabelList dataKey="weeklyTotalHours" position="insideTop"*/}
                                        {/*formatter={timeTrackingBarLabelFormatter}/>*/}
                                    </Bar>
                                    <Brush/>
                                </BarChart>
                            </div>

                            <h3>Raw Data</h3>
                            <table>
                                <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Day</th>
                                    <th>Gravia</th>
                                    <th>&nbsp;&nbsp;&nbsp;&nbsp;</th>
                                    <th>Consulting</th>
                                    <th>&nbsp;&nbsp;&nbsp;&nbsp;</th>
                                    <th>Other</th>
                                    <th>&nbsp;&nbsp;&nbsp;&nbsp;</th>
                                    <th>Total</th>
                                    <th>&nbsp;&nbsp;&nbsp;&nbsp;</th>

                                </tr>
                                </thead>
                                <tbody>
                                {this.state.timeTrackingReportRows.map(ttrr =>
                                    <tr key={Moment(ttrr.date).format("YYYY-MM-DD")}
                                        className={classNames("AdminJiraReports-report-raw-data-row", ttrr.isFirstDayOfWeek ? "start-of-week" : "")}>
                                        <td><span>{Moment(ttrr.date).format("YYYY-MM-DD")}</span></td>
                                        <td><span>{ttrr.dayOfWeek}</span></td>
                                        <td>
                                            <span>{ttrr.graviaHours}</span>
                                        </td>
                                        <td>
                                            <span>{ttrr.isLastDayOfWeek ? (ttrr.weeklyGraviaHours) : ""}</span>
                                        </td>
                                        <td>
                                            <span>{ttrr.consultingHours}</span>
                                        </td>
                                        <td>
                                            <span>{ttrr.isLastDayOfWeek ? (ttrr.weeklyConsultingHours) : ""}</span>
                                        </td>
                                        <td>
                                            <span>{ttrr.otherHours}</span>
                                        </td>
                                        <td>
                                            <span>{ttrr.isLastDayOfWeek ? (ttrr.weeklyOtherHours) : ""}</span>
                                        </td>
                                        <td>
                                            <span>{ttrr.graviaHours + ttrr.consultingHours + ttrr.otherHours}</span>
                                        </td>
                                        <td>
                                            <span>{ttrr.isLastDayOfWeek ? (ttrr.weeklyTotalHours ) : ""}</span>
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </div>
                        }


                        {this.state.selectedReportIndex === 1 &&
                        <div className="spacer-row">
                            <h3>Remaining vs Completed Story Points</h3>


                            <div className="AdminJiraReports-chart-container">
                                <AreaChart width={1000} height={600}
                                           data={this.state.storyPointCompletionRows}
                                           margin={{top: 5, right: 30, left: 20, bottom: 100}}>
                                    <XAxis dataKey="date" height={100} angle={-45} textAnchor="end"/>
                                    <YAxis/>
                                    <CartesianGrid strokeDasharray="3 3"/>
                                    <Tooltip/>
                                    <Legend verticalAlign="top"/>
                                    {/*<Area dataKey='totalStoryPoints' stackId="2" stroke='#333333FF' fill='#33333300'*/}
                                    {/*isAnimationActive={false}>*/}
                                    {/*/!*<LabelList dataKey="totalStoryPoints" position="top"*!/*/}
                                    {/*/!*formatter={timeTrackingBarLabelFormatter}/>*!/*/}
                                    {/*</Area>*/}
                                    <Area dataKey='remainingStoryPoints' stackId="1" stroke='red' fill='red'
                                          isAnimationActive={false}>
                                        {/*<LabelList dataKey="remainingStoryPoints" position="bottom"*/}
                                        {/*formatter={timeTrackingBarLabelFormatter}/>*/}
                                    </Area>
                                    <Area dataKey='completedStoryPoints' stackId="1" stroke='green' fill='green'
                                          isAnimationActive={false}>
                                        {/*<LabelList dataKey="completedStoryPoints" position="bottom"*/}
                                        {/*formatter={timeTrackingBarLabelFormatter}/>*/}
                                    </Area>


                                    <Brush/>
                                </AreaChart>
                            </div>


                        </div>
                        }


                        {this.state.selectedReportIndex === 2 &&
                        <div className="spacer-row">
                            <h3>Effort Vs Estimate</h3>

                            <div>
                                <span>Hours Worked: {this.state.totalHoursWorked}</span>
                            </div>
                            <div>
                                <span>Story Points Completed: {this.state.totalStoryPointsWorked}</span>
                            </div>
                            <div>
                                <span>Hours per Story Point: {this.state.hoursPerStoryPoint}</span>
                            </div>
                        </div>
                        }
                    </div>
                    }
                </AdminPageContentWrap>

                {this.state.isProcessing &&
                <WaitOverlay/>
                }


            </PageLayout>
        );
    }





    handleFixVersionChange(event: Event) {
        if (event.target instanceof HTMLSelectElement) {
            for (let i = 0; i < event.target.options.length; ++i) {
                let opt = event.target.options[i];
                if (opt.selected) {
                    this.setState({currentFixVersion: opt.value}, () => {
                        if (this.state.selectedReportIndex === 1) {
                            this.renderStoryPointReport();
                        } else if (this.state.selectedReportIndex === 2) {
                            this.renderEffortVsEstimateReport();
                        }
                    });
                }
            }

        }
    }

    handleWeekEndDayChange(event: Event) {
        if (event.target instanceof HTMLSelectElement) {
            for (let i = 0; i < event.target.options.length; ++i) {
                let opt = event.target.options[i];
                if (opt.selected) {
                    this.setState({weekEndDay: opt.value}, () => {
                        if (this.state.selectedReportIndex === 1) {
                            this.renderStoryPointReport();
                        } else if (this.state.selectedReportIndex === 2) {
                            this.renderEffortVsEstimateReport();
                        }
                    });
                }
            }

        }
    }

    setReportTabIndex(tabIndex: number) {
        this.setState({selectedReportIndex: tabIndex},
            () => {
                // Render Report.
                switch (this.state.selectedReportIndex) {
                    case 0:
                        this.renderTimeTrackingReport();
                        break;
                    case 1:
                        this.renderStoryPointReport();
                        break;
                    case 2:
                        this.renderEffortVsEstimateReport();
                        break;

                    default:
                        utils.notifyError("No report exist for report index " + this.state.selectedReportIndex);
                }
            });
    }


    getJiraIssuesForCurrentFixVersion() {
        return Lodash.filter(this.state.jiraIssues, (ji) => ji.projectKey === "GRAV"
            && (this.state.currentFixVersion === null || this.state.currentFixVersion === "All" || ji.fixVersions.includes(this.state.currentFixVersion)));

    }

    renderEffortVsEstimateReport() {
        let jiraIssues = this.getJiraIssuesForCurrentFixVersion();

        // Iterate over all issues, and total up the number of story points and the amount of effort.
        // The final value this report produces is a simple number that shows hours per story point.

        let storyPointCount = 0;
        let effortCount = 0;
        for (let issueIndex = 0; issueIndex < jiraIssues.length; issueIndex++) {
            let jiraIssue = jiraIssues[issueIndex];
            if (jiraIssue.resolvedDate) {
                if (jiraIssue.storyPoints) {
                    storyPointCount += parseFloat(jiraIssue.storyPoints);
                    for (let workLogIndex = 0; workLogIndex < jiraIssue.workLogs.length; workLogIndex++) {
                        let workLog = jiraIssue.workLogs[workLogIndex];
                        effortCount += parseFloat(workLog.hours);
                    }
                }
            }
        }

        let finalAverage = 0;
        if (storyPointCount !== 0) {
            finalAverage = effortCount / storyPointCount;
        }

        this.setState({
            hoursPerStoryPoint: Lodash.round(finalAverage, 4),
            totalHoursWorked: effortCount,
            totalStoryPointsWorked: storyPointCount
        });
    }

    getNextDay(day: string) {
        let index = (this.state.daysOfWeek.indexOf(day) + 1) % 7;
        return this.state.daysOfWeek[index];
    }

    renderTimeTrackingReport() {
        let timeTrackingReportRows = [];

        let lastDayOfWeek = this.state.weekEndDay;
        let firstDayOfWeek = this.getNextDay(lastDayOfWeek);

        for (let i = 0; i < this.state.jiraIssues.length; i++) {
            let jiraIssue = this.state.jiraIssues[i];
            for (let j = 0; j < jiraIssue.workLogs.length; j++) {
                let logWork = jiraIssue.workLogs[j];
                // Subtract 7 hours to adjust for Syndey timezone used in log files.
                logWork.date = Moment(logWork.date).subtract({ hours: 7 }).toDate();
                let timeTrackingRow = Lodash.find(timeTrackingReportRows, (r) => {
                    return Moment(logWork.date).toDate().getFullYear() === r.date.getFullYear()
                        && Moment(logWork.date).toDate().getMonth() === r.date.getMonth()
                        && Moment(logWork.date).toDate().getDate() === r.date.getDate();
                });
                if (!timeTrackingRow) {
                    timeTrackingRow = new TimeTrackingReportRow();
                    timeTrackingRow.date = new Date(Moment(logWork.date).toDate().getFullYear(), Moment(logWork.date).toDate().getMonth(), Moment(logWork.date).toDate().getDate());
                    timeTrackingRow.displayDate = Moment(timeTrackingRow.date).format("YYYY-MM-DD");
                    timeTrackingRow.graviaHours = 0;
                    timeTrackingRow.consultingHours = 0;
                    timeTrackingRow.otherHours = 0;
                    timeTrackingRow.dayOfWeek = Moment(logWork.date).format("dddd");
                    timeTrackingRow.weekNumber = Moment(logWork.date).week();
                    timeTrackingRow.isFirstDayOfWeek = timeTrackingRow.dayOfWeek === firstDayOfWeek;
                    timeTrackingRow.isLastDayOfWeek = timeTrackingRow.dayOfWeek === lastDayOfWeek;
                    timeTrackingReportRows.push(timeTrackingRow);
                }

                if (jiraIssue.projectKey === "GRAV") {
                    timeTrackingRow.graviaHours = parseFloat(timeTrackingRow.graviaHours) + parseFloat(logWork.hours);
                } else if (jiraIssue.projectKey === "CON") {
                    timeTrackingRow.consultingHours = parseFloat(timeTrackingRow.consultingHours) + parseFloat(logWork.hours);
                } else {
                    timeTrackingRow.otherHours = parseFloat(timeTrackingRow.otherHours) + parseFloat(logWork.hours)
                }
            }
        }

        // All data is entered. Fill in any missing days.
        let marchingDate = Lodash.minBy(timeTrackingReportRows, (r) => r.date).date;
        let maxDate = Lodash.maxBy(timeTrackingReportRows, (r) => r.date).date;


        let getRowsFunc = (targetDate) => Lodash.find(timeTrackingReportRows, (r) => Moment(r.date).isSame(Moment(targetDate)));

        while (marchingDate < maxDate) {
            let existingRow = getRowsFunc(marchingDate);

            if (!existingRow) {
                let emptyTimeTrackingRow = new TimeTrackingReportRow();
                emptyTimeTrackingRow.date = new Date(marchingDate.getFullYear(), marchingDate.getMonth(), marchingDate.getDate());
                emptyTimeTrackingRow.displayDate = Moment(emptyTimeTrackingRow.date).format("YYYY-MM-DD");
                emptyTimeTrackingRow.graviaHours = 0;
                emptyTimeTrackingRow.consultingHours = 0;
                emptyTimeTrackingRow.otherHours = 0;
                emptyTimeTrackingRow.dayOfWeek = Moment(marchingDate).format("dddd");
                emptyTimeTrackingRow.weekNumber = Moment(marchingDate).week();
                emptyTimeTrackingRow.isFirstDayOfWeek = emptyTimeTrackingRow.dayOfWeek === firstDayOfWeek;
                emptyTimeTrackingRow.isLastDayOfWeek = emptyTimeTrackingRow.dayOfWeek === lastDayOfWeek || Moment(marchingDate).isSame(Moment(maxDate));
                timeTrackingReportRows.push(emptyTimeTrackingRow);
            }

            marchingDate = Moment(marchingDate).add(1, 'days').toDate();
        }


        // Now sort the results.
        timeTrackingReportRows = Lodash.sortBy(timeTrackingReportRows, (r) => r.date);


        // Reset marching date, to march through again lookinf for weekly totals.
        marchingDate = Lodash.minBy(timeTrackingReportRows, (r) => r.date).date;

        let weeklyGraviaTotal = 0;
        let weeklyConsultingTotal = 0;
        let weeklyOtherTotal = 0;
        while (marchingDate <= maxDate) {
            let existingRow = getRowsFunc(marchingDate);

            if (existingRow.isFirstDayOfWeek) {
                weeklyGraviaTotal = 0;
                weeklyConsultingTotal = 0;
                weeklyOtherTotal = 0;
            }

            weeklyGraviaTotal = parseFloat(weeklyGraviaTotal) + parseFloat(existingRow.graviaHours);
            weeklyConsultingTotal = parseFloat(weeklyConsultingTotal) + parseFloat(existingRow.consultingHours);
            weeklyOtherTotal = parseFloat(weeklyOtherTotal) + parseFloat(existingRow.otherHours);

            // If this is the last day of the week, set the tally.
            if (existingRow.isLastDayOfWeek || Moment(existingRow.date).isSame(Moment(maxDate))) {
                existingRow.isLastDayOfWeek = true;
                existingRow.weeklyGraviaHours = weeklyGraviaTotal;
                existingRow.weeklyConsultingHours = weeklyConsultingTotal;
                existingRow.weeklyOtherHours = weeklyOtherTotal;
                existingRow.weeklyTotalHours = weeklyGraviaTotal + weeklyConsultingTotal + weeklyOtherTotal;
            }

            marchingDate = Moment(marchingDate).add(1, 'days').toDate();
        }


        this.setState({
            timeTrackingReportRows: timeTrackingReportRows,
            isProcessing: false
        });
    }

    renderStoryPointReport() {
        let storyPointRows = [];
        let currentMilestoneStoryPointRows = [];

        let jiraIssues = this.getJiraIssuesForCurrentFixVersion();

        //console.log("There are " + jiraIssues.length + " issues for fix version " + (this.state.currentFixVersion || ""));

        let minCreatedDate = Lodash.minBy(jiraIssues, (ji) => ji.createdDate).createdDate;
        let maxDate = Moment().startOf('day').toDate();

        let currentDate = minCreatedDate;

        let remainingStoryPoints = 0;
        let completedStoryPoints = 0;

        let currentMilestoneRemainingStoryPoints = 0;
        let currentMilestoneCompletedStoryPoints = 0;
        let dayIndex = 0;
        let currentMilestone = "Conference Demo";
        while (currentDate <= maxDate) {

            Lodash.forEach(jiraIssues, (jiraIssue) => {
                if (Moment(jiraIssue.createdDate).isSame(Moment(currentDate))) {
                    if (jiraIssue.storyPoints) {
                        remainingStoryPoints += jiraIssue.storyPoints;

                        if (jiraIssue.fixVersions.includes(currentMilestone)) {
                            currentMilestoneRemainingStoryPoints += jiraIssue.storyPoints;
                        }
                    }
                }

                if (Moment(jiraIssue.resolvedDate).isSame(Moment(currentDate))) {
                    if (jiraIssue.storyPoints) {
                        completedStoryPoints += jiraIssue.storyPoints;
                        remainingStoryPoints -= jiraIssue.storyPoints;

                        if (jiraIssue.fixVersions.includes(currentMilestone)) {
                            currentMilestoneCompletedStoryPoints += jiraIssue.storyPoints;
                            currentMilestoneRemainingStoryPoints -= jiraIssue.storyPoints;
                        }
                    }
                }
            });

            if (dayIndex % 7 === 0 || Moment(maxDate).isSame(Moment(currentDate))) {
                let newRow = new StoryPointCompletionRow();
                newRow.date = Moment(currentDate).format("YYYY-MM-DD");
                newRow.remainingStoryPoints = remainingStoryPoints;
                newRow.completedStoryPoints = completedStoryPoints;
                newRow.totalStoryPoints = remainingStoryPoints + completedStoryPoints;

                storyPointRows.push(newRow);


                let newMilestoneRow = new StoryPointCompletionRow();
                newMilestoneRow.date = Moment(currentDate).format("YYYY-MM-DD");
                newMilestoneRow.remainingStoryPoints = currentMilestoneRemainingStoryPoints;
                newMilestoneRow.completedStoryPoints = currentMilestoneCompletedStoryPoints;
                newMilestoneRow.totalStoryPoints = currentMilestoneRemainingStoryPoints + currentMilestoneCompletedStoryPoints;

                currentMilestoneStoryPointRows.push(newMilestoneRow);
            }
            currentDate = Moment(currentDate).add(1, 'days').toDate();
            dayIndex++;
        }


        this.setState({
            storyPointCompletionRows: storyPointRows,
            currentMilestoneStoryPointCompletionRows: currentMilestoneStoryPointRows,
            isProcessing: false
        });

    }


    handleFileInputChange(event: Event) {

        if (event.target instanceof HTMLInputElement && event.target.files.length > 0) {

            const reader = new FileReader();
            const file = event.target.files[0];

            reader.onload = (upload) => {
                this.setState({
                    newFileDataUri: upload.target.result,
                    newFileName: file.name,
                    newFileType: file.type
                }, () => {
                    if (this.state.newFileDataUri) {
                        this.setState({
                            jiraIssues: [],
                            isProcessing: true,
                            selectedReportIndex: -1
                        }, () => this.processUploadedXml());
                    }
                });
            };

            reader.readAsDataURL(file);

        }
    }


    processUploadedXml() {
        try {
            let indexOfBase64 = this.state.newFileDataUri.indexOf(";base64,") + 8;
            let base64Data = this.state.newFileDataUri.slice(indexOfBase64);
            let decodedData = Base64.decode(base64Data);

            this.setState({isProcessing: true});

            Xml2js.parseString(decodedData, (err, result) => {
                this.processXmlData(result);
            });


        } catch (err) {
            utils.notifyException(err, "Error parsing CSV");
        }
    }

    processXmlData(xmlData: any) {
        let rawIssues = xmlData["entity-engine-xml"].Issue;
        let rawWorklog = xmlData["entity-engine-xml"].Worklog;
        let rawStatuses = xmlData["entity-engine-xml"].Status;
        let rawVersions = xmlData["entity-engine-xml"].Version;
        //let rawNodeAssociations = xmlData["entity-engine-xml"].NodeAssociation;
        let rawIssueTypes = xmlData["entity-engine-xml"].IssueType;
        let rawCustomFields = xmlData["entity-engine-xml"].CustomField;
        let rawCustomFieldValues = xmlData["entity-engine-xml"].CustomFieldValue;
        let rawFixVersions = xmlData["entity-engine-xml"].FixVersion;

        let storyPointCustomField = 0;

        let issues = [];
        let worklogs = [];

        let statuses = {};
        let issueTypes = {};
        let versions = {};


        // Parse Custom Fields
        for (let customFieldIndex = 0; customFieldIndex < rawCustomFields.length; customFieldIndex++) {
            if (rawCustomFields[customFieldIndex]["$"].name === "Story Points") {
                storyPointCustomField = rawCustomFields[customFieldIndex]["$"].id;
            }
        }

        // Parse Status Types
        Lodash.forEach(rawStatuses, (st) => {
            statuses[st["$"].id] = st["$"].name;
        });

        // Parse Issue Types
        Lodash.forEach(rawIssueTypes, (it) => {
            issueTypes[it["$"].id] = it["$"].name;
        });

        // Parse Issue Types
        Lodash.forEach(rawVersions, (v) => {
            versions[v["$"].id] = v["$"].name;
        });


        // Parse Worklogs
        for (let worklogIndex = 0; worklogIndex < rawWorklog.length; worklogIndex++) {
            let worklog = new JiraWorkLogEntry();
            worklog.issueId = rawWorklog[worklogIndex]["$"].issue;
            worklog.date = rawWorklog[worklogIndex]["$"].startdate ? Moment(rawWorklog[worklogIndex]["$"].startdate).startOf('day').toDate() : null;
            worklog.doneBy = rawWorklog[worklogIndex]["$"].author;
            worklog.hours = rawWorklog[worklogIndex]["$"].timeworked / 3600.0;
            worklogs.push(worklog);
        }

        // Parse Issues
        for (let issueIndex = 0; issueIndex < rawIssues.length; issueIndex++) {
            let issue = new JiraIssue();

            issue.id = rawIssues[issueIndex]["$"].id;
            issue.issueKey = rawIssues[issueIndex]["$"].number;
            issue.issueType = issueTypes[rawIssues[issueIndex]["$"].type];
            issue.status = statuses[rawIssues[issueIndex]["$"].status];
            issue.projectKey = rawIssues[issueIndex]["$"].projectKey;
            issue.createdDate = rawIssues[issueIndex]["$"].created ? Moment(rawIssues[issueIndex]["$"].created).startOf('day').toDate() : null;
            issue.updatedDate = rawIssues[issueIndex]["$"].updated ? Moment(rawIssues[issueIndex]["$"].updated).startOf('day').toDate() : null;
            issue.resolvedDate = rawIssues[issueIndex]["$"].resolutiondate ? Moment(rawIssues[issueIndex]["$"].resolutiondate).startOf('day').toDate() : null;
            issue.originalEstimate = rawIssues[issueIndex]["$"].timeoriginalestimate;
            issue.remainingEstimate = rawIssues[issueIndex]["$"].timeestimate;
            issue.timeSpent = rawIssues[issueIndex]["$"].timespent;

            Lodash.forEach(rawCustomFieldValues, (cfv) => {
                if (cfv["$"].customfield === storyPointCustomField && cfv["$"].issue === issue.id) {
                    issue.storyPoints = parseFloat(cfv["$"].numbervalue);
                }
            });

            // Find any worklogs for this issue.
            issue.workLogs = Lodash.filter(worklogs, (wl) => wl.issueId === issue.id);

            // Find any fix versions


            // Old way:
            //Lodash.forEach(rawNodeAssociations, (na) => {
            //    Lodash.forEach(versions, (version, versionId) => {
            //        if (na["$"].sourceNodeId === issue.id && na["$"].sourceNodeEntity === "Issue"
            //            && na["$"].sinkNodeId === versionId && na["$"].sinkNodeEntity === "Version") {
            //            issue.fixVersions.push(version);
            //        }
            //    });
            //});

            // New Way is easier.
            Lodash.forEach(rawFixVersions, (rfa) => {
                Lodash.forEach(versions, (version, versionId) => {
                    if (rfa["$"].issue === issue.id
                        && rfa["$"].version === versionId) {
                        issue.fixVersions.push(version);
                    }
                });
            });
//


            issues.push(issue);
        }

        let fixVersions = Lodash.map(versions, (name, id) => {
            let fixVersion = new JiraFixVersion();
            fixVersion.id = id;
            fixVersion.name = name;
            return fixVersion;
        });

        let daysOfWeek = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
        ];

        this.setState({
            jiraIssues: issues,
            isProcessing: false,
            fixVersions: fixVersions,
            daysOfWeek: daysOfWeek
        });
    }

}

export default AdminJiraReports;