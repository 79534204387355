// @flow
import Utils from '../utils';
import PortfolioLib from 'goyette-portfolio-lib';


class BlogService {

    fetchPostPreviews(requestModel: PortfolioLib.DataPageRequestModel): Promise<PortfolioLib.DataPageResponseModel> {

        let callback = ( res ) => {
            let dataPage = PortfolioLib.DataPageResponseModel.Empty;
            Object.assign(dataPage, res.data.dataPage);

            // Convert data to actual records.
            dataPage.records = dataPage.records.map((postData, index) => {
                let model = PortfolioLib.BlogPostPreviewModel.Empty;
                Object.assign(model, postData);
                return model;
            });

            return dataPage;
        };


        if (requestModel.pageIndex === 0
            && !requestModel.filter
            && !requestModel.tag) {
            // Due to some weird issue I don't understand, the initial POST to get post previews
            // is stalling out in prod. So I'm just going to do a GET for the first page for now.
            let route = PortfolioLib.SharedUtils.replaceRouteParam(PortfolioLib.SharedRoutes.Server.Blog.InitialPostPreviews.Path, PortfolioLib.SharedRoutes.Server.Blog.InitialPostPreviews.RecordsPerPageParam, requestModel.recordsPerPage);

            return Utils.ajaxGet(Utils.getApiRoute(route))
                .then(callback)
                .catch((err) => {
                    Utils.logAndRethrow(err, "Error fetching initial post previews");
                });
        } else {
            return Utils.ajaxPost(Utils.getApiRoute(PortfolioLib.SharedRoutes.Server.Blog.PostPreviews.Path), requestModel)
                .then(callback)
                .catch((err) => {
                    Utils.logAndRethrow(err, "Error fetching post previews");
                });
        }
    }


    fetchViewPostModel(postId: string): Promise<PortfolioLib.BlogViewPostModel> {

        let route = PortfolioLib.SharedUtils.replaceRouteParam(PortfolioLib.SharedRoutes.Server.Blog.ViewPost.Path, PortfolioLib.SharedRoutes.Server.Blog.ViewPost.PostIdParam, postId);

        return Utils.ajaxGet(Utils.getApiRoute(route))
            .then(res => {
                let model = PortfolioLib.BlogViewPostModel.Empty;
                Object.assign(model, res.data.model);
                return model;
            })
            .catch((err) => {
                Utils.logAndRethrow(err, "Error fetching post view data");
            });
    }


    getNewPostId(): Promise<number> {
        let route = PortfolioLib.SharedRoutes.Server.Blog.NewPostId.Path;

        return Utils.ajaxGet(Utils.getApiRoute(route))
            .then(res => {
                return res.data.newPostId;
            })
            .catch((err) => {
                Utils.logAndRethrow(err, "Error getting new Post ID");
            });
    }

    fetchEditPostModel(postId: number): Promise<PortfolioLib.BlogEditPostModel> {
        let route = PortfolioLib.SharedUtils.replaceRouteParam(PortfolioLib.SharedRoutes.Server.Blog.EditPost.Path, PortfolioLib.SharedRoutes.Server.Blog.EditPost.PostIdParam, postId);

        return Utils.ajaxGet(Utils.getApiRoute(route))
            .then(res => {
                let model = PortfolioLib.BlogEditPostModel.Empty;
                Object.assign(model, res.data.model);

                return model;
            })
            .catch((err) => {
                Utils.logAndRethrow(err, "Error fetching post edit data");
            });
    }

    fetchTags(): Promise<Array<string>> {

        return Utils.ajaxGet(Utils.getApiRoute(PortfolioLib.SharedRoutes.Server.Blog.Tags.Path))
            .then(res => {
                return res.data.tags;
            })
            .catch((err) => {
                Utils.logAndRethrow(err, "Error fetching tags");
            });
    }


    fetchAllTags(): Promise<Array<string>> {

        return Utils.ajaxGet(Utils.getApiRoute(PortfolioLib.SharedRoutes.Server.Blog.AllTags.Path))
            .then(res => {
                return res.data.tags;
            })
            .catch((err) => {
                Utils.logAndRethrow(err, "Error fetching all tags");
            });
    }

    fetchImages(postId: number): Promise<Array<PortfolioLib.BlogImageModel>> {

        return Utils.ajaxGet(Utils.getApiRoute(PortfolioLib.SharedUtils.replaceRouteParam(PortfolioLib.SharedRoutes.Server.Blog.Images.Path, PortfolioLib.SharedRoutes.Server.Blog.Images.PostIdParam, postId)))
            .then(res => {
                let retval = [];
                for (let rawData of res.data.images) {
                    let model = PortfolioLib.BlogImageModel.Empty;
                    Object.assign(model, rawData);
                    retval.push(model);
                }
                return retval;
            })
            .catch((err) => {
                Utils.logAndRethrow(err, "Error fetching images");
            });
    }


    uploadImageBatch(imageBatchUploadModel: PortfolioLib.BlogImageBatchUploadModel): Promise<PortfolioLib.BlogImageModel[]> {
        return Utils.ajaxPost(Utils.getApiRoute(PortfolioLib.SharedRoutes.Server.Blog.ImageUpload.Path), imageBatchUploadModel)
            .then(res => {
                let retval = [];

                for (let i = 0; i < res.data.blogImageModels.length; i++ ) {
                    let model = PortfolioLib.BlogImageModel.Empty;
                    retval.push( Object.assign(model, res.data.blogImageModels[i]));
                }
                return retval;
            })
            .catch((err) => {
                Utils.logAndRethrow(err, "Error uploading blog images");
            });
    }

    deleteImage(postId: number, imageName: string): Promise<PortfolioLib.ActionCompleteModel> {
        let route = PortfolioLib.SharedRoutes.Server.Blog.ImageDelete.Path;

        route = PortfolioLib.SharedUtils.replaceRouteParam(route, PortfolioLib.SharedRoutes.Server.Blog.ImageDelete.PostIdParam, postId);
        route = PortfolioLib.SharedUtils.replaceRouteParam(route, PortfolioLib.SharedRoutes.Server.Blog.ImageDelete.ImageNameParam, imageName);
        return Utils.ajaxPost(Utils.getApiRoute(route))
            .then(res => {
                let model = PortfolioLib.ActionCompleteModel.Empty;
                Object.assign(model, res.data.result);
                return model;
            })
            .catch((err) => {
                Utils.logAndRethrow(err, `Error deleting post image ${imageName} for postId ${postId}`);
            });
    }

    saveBlogEditModel(model: PortfolioLib.BlogEditPostModel): Promise<PortfolioLib.ActionCompleteModel> {
        return Utils.ajaxPost(Utils.getApiRoute(PortfolioLib.SharedRoutes.Server.Blog.EditPost.Path), model)
            .then(res => {
                let model = PortfolioLib.ActionCompleteModel.Empty;
                Object.assign(model, res.data.result);
                return model;
            })
            .catch((err) => {
                Utils.logAndRethrow(err, "Error updating Blog Edit Post Model");
            });
    }


    deletePost(postId: number): Promise<PortfolioLib.ActionCompleteModel> {
        return Utils.ajaxPost(Utils.getApiRoute(PortfolioLib.SharedRoutes.Server.Blog.DeletePost.Path), {postId: postId})
            .then(res => {
                let model = PortfolioLib.ActionCompleteModel.Empty;
                Object.assign(model, res.data.result);
                return model;
            })
            .catch((err) => {
                Utils.logAndRethrow(err, `Error deleteing Blog Post ID ${postId.toString()}`);
            });
    }


    fetchBlogAdminEditModel(): Promise<PortfolioLib.BlogAdminEditModel> {
        return Utils.ajaxGet(Utils.getApiRoute(PortfolioLib.SharedRoutes.Server.Blog.Admin.Path))
            .then(res => {
                let model = PortfolioLib.BlogAdminEditModel.Empty;
                Object.assign(model, res.data.blogAdminEditModel);
                return model;
            })
            .catch((err) => {
                Utils.logAndRethrow(err, "Error getting Blog Admin Edit Model");
            });
    }

    saveBlogAdminEditModel(model: PortfolioLib.BlogAdminEditModel): Promise<PortfolioLib.ActionCompleteModel> {

        return Utils.ajaxPost(Utils.getApiRoute(PortfolioLib.SharedRoutes.Server.Blog.Admin.Path), model)
            .then(res => {
                let model = PortfolioLib.ActionCompleteModel.Empty;
                Object.assign(model, res.data.result);
                return model;
            })
            .catch((err) => {
                Utils.logAndRethrow(err, "Error updating Blog Admin Edit Model");
            });
    }

    fetchDraftPosts(postId: number ) : Promise<Array<PortfolioLib.BlogDraftPostSummaryModel>> {
        let route = PortfolioLib.SharedUtils.replaceRouteParam(PortfolioLib.SharedRoutes.Server.Blog.DraftPosts.Path, PortfolioLib.SharedRoutes.Server.Blog.DraftPosts.PostIdParam, postId);

        return Utils.ajaxGet(Utils.getApiRoute(route))
            .then(res => {
                // Convert data to actual records.
                return res.data.drafts.map((draft, index) => {
                    let model = PortfolioLib.BlogDraftPostSummaryModel.Empty;
                    Object.assign(model, draft);
                    return model;
                });
            })
            .catch((err) => {
                Utils.logAndRethrow(err, "Error getting Blog Draft Posts");
            });
    }


    fetchDraftPost(draftGuid: string): Promise<PortfolioLib.BlogEditPostModel> {
        let route = PortfolioLib.SharedUtils.replaceRouteParam(PortfolioLib.SharedRoutes.Server.Blog.DraftPost.Path, PortfolioLib.SharedRoutes.Server.Blog.DraftPost.DraftGuidParam, draftGuid);

        return Utils.ajaxGet(Utils.getApiRoute(route))
            .then(res => {
                let model = PortfolioLib.BlogEditPostModel.Empty;
                Object.assign(model, res.data.model);

                return model;
            })
            .catch((err) => {
                Utils.logAndRethrow(err, "Error fetching draft post");
            });
    }

    saveDraftPost(model: PortfolioLib.BlogEditPostModel): Promise<PortfolioLib.ActionCompleteModel> {
        return Utils.ajaxPost(Utils.getApiRoute(PortfolioLib.SharedRoutes.Server.Blog.DraftPost.Path), model)
            .then(res => {
                let model = PortfolioLib.ActionCompleteModel.Empty;
                Object.assign(model, res.data.result);
                return model;
            })
            .catch((err) => {
                Utils.logAndRethrow(err, "Error updating Blog Draft Post");
            });
    }
}

export default BlogService
