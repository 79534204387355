// @flow
import React, {Component} from 'react';
import Lodash from 'lodash';
import {Link, Route} from 'react-router-dom';
import SlideInPanel from '../SlideInPanel/SlideInPanel';
import {DanGoyetteRoutes, GraviaGameRoutes} from '../../routeConfig';
import './AppHeaderBar.css';
import classNames from 'classnames';
import {FaBars} from 'react-icons/fa'
import Utils from '../../utils';
import AppConstants from "../../appConstants";


class AppHeader extends Component {

    lastScrollYPosition: number;

    state: {
        mainMenuOpen: boolean,
        socialMenuOpen: boolean,
        navBarVisible: boolean,
    };

    constructor(props: any) {
        super(props);
        this.lastScrollYPosition = 0;
        this.state = {
            mainMenuOpen: false,
            socialMenuOpen: false,
            navBarVisible: true
        };
    }

    render() {
        let routes = Utils.getApplication() === AppConstants.Applications.DanGoyette ? DanGoyetteRoutes : GraviaGameRoutes;

        let navBarClasses = classNames('AppHeaderBar-main-bar',
            this.state.navBarVisible === true ? '' : 'collapsed'
        );

        return (
            <div>
                <div className={navBarClasses}>
                    <div className="AppHeaderBar-navbar">
                        <div className="AppHeaderBar-navbox">
                            {/* Nav links */}
                            {routes.filter((r) => r.isNavigation).map((route, i) => (

                                <NavBarLink
                                    key={route.path}
                                    cssClass={route.isSignature ? 'AppHeaderBar-navbar-signature' : 'AppHeaderBar-navbar-item'}
                                    to={route.path}
                                    label={route.label}
                                    imageSource={route.imageSource}
                                    imageWidth={route.imageWidth}
                                    imageMarginLeft={route.imageMarginLeft}
                                    imageMarginTop={route.imageMarginTop}
                                    onItemClick={(e) => this.handleMainMenuClosed()}/>
                            ))}
                        </div>

                        <div className="AppHeaderBar-navbox AppHeaderBar-nav-right">

                            {/*<div className="AppHeaderBar-social-menu-button-wrap">*/}
                                {/*<SocialMenuButton/>*/}
                            {/*</div>*/}

                            <button
                                className={classNames('AppHeaderBar-navbar-menu-button', this.state.mainMenuOpen ? 'open' : '')}
                                onClick={(e) => this.openMainMenu(e)}>
                                <FaBars size={40}/>
                            </button>

                        </div>
                    </div>
                </div>

                <SlideInPanel isOpen={this.state.mainMenuOpen} onClose={() => this.handleMainMenuClosed()}>
                    <MobileMainMenuPanel onMenuClosed={() => this.handleMainMenuClosed()}/>
                </SlideInPanel>

            </div>
        );
    }

    componentDidMount() {
        window.addEventListener('scroll',  Lodash.throttle((e) => this.handleScroll(e), 200));
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', Lodash.throttle((e) => this.handleScroll(e), 200));
    }

    handleScroll(event: any) {
        let didScrollUp = this.lastScrollYPosition > window.scrollY;
        this.lastScrollYPosition = window.scrollY;

        this.setState((prevState, props) => ({
            navBarVisible: window.scrollY <= 0 || didScrollUp
        }));
    }


    openMainMenu(e: Event) {
        e.preventDefault();

        this.setState((prevState, props) => ({
            mainMenuOpen: !prevState.mainMenuOpen
        }));
    }



    handleMainMenuClosed() {
        this.setState({mainMenuOpen: false});
    }
}

class NavBarLink extends Component {

    props: {
        to: string,
        activeOnlyWhenExact: boolean,
        label: string,
        imageSource: string,
        imageWidth: number,
        imageMarginLeft: number,
        imageMarginTop: number,
        cssClass: string,
        onItemClick: (e: Event) => void
    };

    static defaultProps = {
        to: "",
        activeOnlyWhenExact: false,
        label: "",
        cssClass: "AppHeaderBar-navbar-item",
        onItemClick: function (e) {
        }
    };


    render() {
        let shouldShowImage = this.props.imageSource
            && this.props.imageSource.length > 0;

        return (
            <Route path={this.props.to} exact={this.props.activeOnlyWhenExact} children={({match}) => (
                <Link className={classNames(this.props.cssClass, match ? 'matches' : '')} to={this.props.to}
                      onClick={(e) => this.props.onItemClick(e)}>
                    <div>
                        {shouldShowImage &&
                            <img src={this.props.imageSource} style={{width: this.props.imageWidth, "marginLeft": this.props.imageMarginLeft, "marginTop": this.props.imageMarginTop}} alt={this.props.label} />
                        }
                        {!shouldShowImage &&
                            <div>{this.props.label}</div>
                        }
                    </div>
                </Link>
            )}/>
        )
    }
}

class MobileMainMenuPanel extends Component {
    render() {
        let routes = Utils.getApplication() === AppConstants.Applications.DanGoyette ? DanGoyetteRoutes : GraviaGameRoutes;

        return (
            <div className="AppHeaderBar-mobile-main-menu-panel">
                {routes.filter((r) => r.isNavigation && r.showInMobile).map((route, i) => (
                    <NavBarLink key={route.path} cssClass="AppHeaderBar-mobile-main-menu-link" to={route.path}
                                label={route.label} onItemClick={(e) => this.onMenuItemClick(e)}/>
                ))}

            </div>
        )
    }

    onMenuItemClick(e: Event) {
        this.props.onMenuClosed();
    }
}


export default AppHeader;