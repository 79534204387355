// @flow
import React, {Component} from 'react';
import './YouTubeVideo.css';
import {TiSocialYoutube} from 'react-icons/ti'


// The main reason for this class is to introduce a delay in rendering embedded YouTube videos after the page
// has loaded, in order to avoid the accompanying framerate drop while the page's initial animations are running.
class YouTubeVideo extends Component {
    props: {
        url: string,
        title: string,
        classes: string
    };


    static defaultProps = {
        showIcon: true,
        title: "",
        classes: "YouTubeVideo-embedded-video"
    };


    state: {
        isVisible: boolean,
        dots: string,
        timer: any
    };

    constructor(props: any) {
        super(props);
        this.state = {
            isVisible: false,
            dots: "",
            timer: null
        };
    }

    componentDidMount() {
        this.setState({timer: setTimeout(() => {
            this.setState({isVisible: true})
        }, 1000)});
    }

    componentWillUnmount() {
        if (this.state.timer) {
            clearTimeout(this.state.timer);
            this.setState({timer: null});
        }
    }

    render() {

        return (
            <div className={this.props.classes}>
                {!this.state.isVisible &&
                    <div className="YouTubeVideo-loading-container">
                        <TiSocialYoutube className="YouTubeVideo-logo-icon"/>
                    </div>
                }
                {this.state.isVisible &&
                <iframe allowFullScreen="true" className="YouTubeVideo-video" src={this.props.url} title={this.props.title}/>
                }
            </div>
        );
    }
}

export default YouTubeVideo;