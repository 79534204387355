// @flow
import React, {Component} from 'react';
import './BlogAdmin.css';
import PortfolioLib from 'goyette-portfolio-lib';
import BlogService from '../../Services/BlogService';
import WaitOverlay from '../../Components/WaitOverlay/WaitOverlay';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/xml/xml';
import 'codemirror/mode/css/css';
import AuthStatusProviderService from '../../Services/AuthStatusProviderService';
import Utils from '../../utils';

let blogService = new BlogService();

class BlogAdmin extends Component {
    props: {};

    static defaultProps = {};

    state: {
        isInitialized: boolean,
        authStatus: PortfolioLib.AuthStatusModel,
        tagsValue: string,
        authStatusProviderSubscriptionKey: string
    };


    constructor(props: $PropertyType<BlogAdmin, 'props'>) {
        super(props);


        this.state = {
            isInitialized: false,
            authStatus: AuthStatusProviderService.instance.authStatusModel,
            tagsValue: "",
            authStatusProviderSubscriptionKey: ""
        };
    }

    updateAuthStatus(newAuthStatus: PortfolioLib.AuthStatusModel) {
        let action = this.state.authStatus.isAdmin === newAuthStatus.isAdmin
            ? () => {} : () => this.doInitialization();
        this.setState({authStatus: newAuthStatus}, action);
    }


    doInitialization() {
        if (this.state.authStatus.isAdmin) {
            this.setState({isInitialized: false});

            // Continue loading.
            Promise.all([
                this.fetchEditModel()
            ]).then(() => {
                this.setState({isInitialized: true});
            })
            .catch((err) => {
                Utils.notifyException(err, "Error Initializing Blog Admin");
            });
        } else {
            // Initialization is done, but will result in no access granted.
            this.setState({isInitialized: true});
        }

    }

    componentWillUnmount() {
        AuthStatusProviderService.instance.unsubscribe(this.state.authStatusProviderSubscriptionKey);
    }

    componentDidMount() {
        this.setState({authStatusProviderSubscriptionKey: AuthStatusProviderService.instance.subscribe((newAuthStatus) => this.updateAuthStatus(newAuthStatus))});

        this.doInitialization();

        document.title = "Blog Admin";
    }


    fetchEditModel() {
        return new Promise((res, rej) => {
            blogService.fetchBlogAdminEditModel()
                .then((adminEditModel) => {
                    this.setState({tagsValue: adminEditModel.tags.join(", ")});
                    res();
                })
                .catch((err) => {
                    Utils.notifyException(err, "Error fetching Edit Model");
                });
        });

    }

    save() {
        let tags = this.state.tagsValue.split(",").map((t) => t.trim());
        let editModel = new PortfolioLib.BlogAdminEditModel(tags);
        blogService.saveBlogAdminEditModel(editModel)
            .then((success) => {
                Utils.notifyMessage('Saved successfully');
            })
            .catch((err) => {
                Utils.notifyException(err, "Error saving Edit Model");
            });
    }

    render() {

        return (
            <div style={{position: "relative"}}>


                {this.state.authStatus.isAdmin && this.state.isInitialized &&
                <div>
                    <button className="BlogAdmin-save-button standard-button" onClick={(e) => this.save()}>Save
                    </button>
                    <h2>Blog Admin</h2>


                    <h3>Tags</h3>
                    <textarea
                        style={{flex: "1"}}
                        className="BlogAdmin-tags-input"
                        value={this.state.tagsValue}
                        onChange={(e) => this.handleTagsChange(e)}/>

                </div>
                }

                {!this.state.authStatus.isAuthenticated &&
                <div>
                    <h2>Error</h2>
                    <p>You must be logged in to view this page.</p>
                </div>
                }

                {this.state.authStatus.isAuthenticated && !this.state.authStatus.isAdmin && this.state.isInitialized &&
                <div>
                    <h2>Error</h2>
                    <p>You are not authorized to view this page.</p>
                </div>
                }

                {!this.state.isInitialized &&
                <WaitOverlay/>
                }

            </div>
        );
    }

    handleTagsChange(event: Event) {
        if (event.target instanceof HTMLTextAreaElement) {
            this.setState({tagsValue: event.target.value});
        }
    }


}

export default BlogAdmin;