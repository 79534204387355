// @flow
import React, {Component} from 'react';
import './FAQ.css';
import AppConstants from '../../../../../appConstants';
import PageLayout from '../../../../../Components/PageLayout/PageLayout'
import AccordionControl from '../../../../../Components/AccordionControl/AccordionControl';
import Utils from '../../../../../utils';
import ExternalLink from '../../../../../Components/ExternalLink/ExternalLink';
import {default as bgImage} from './Images/BasicExam.jpg';
import ReactDocMeta from 'react-document-meta';


class FAQ extends Component {

    state: {};


    constructor(props: any) {
        super(props);

        this.state = {};
    }


    render() {
        let canonicalUrl = "https://www.graviagame.com/Support/FAQ";
        let metaDescription = "Frequently Asked Questions";
        let metaTags = [
            {name: "description", content: metaDescription},
            {property: "og:title", content: "Gravia - FAQ"},
            {property: "og:type", content: "website"},
            {property: "og:description", content: metaDescription},
            {property: "og:image", content: "https://www.graviagame.com" + bgImage},
            {property: "og:url", content: canonicalUrl},
            {property: "fb:app_id", content: Utils.getFacebookAppId()}
        ];
        let highlightedQuestionId = this.props.location.hash;


        return (
            <PageLayout caption="FAQ" title="FAQ" backgroundImage={bgImage}
                        opacity={0.1} grayscale={0.0}>
                <ReactDocMeta tags={metaTags}>
                    <div>

                        {/*I decided not to make this data-driven. I thought it would be useful to be able */}
                            {/*to have the answers be arbitrary HTML.*/}
                        <div className="spacer-row" />

                        <AccordionControl
                            question={<div>
                                Is there a free demo?
                            </div>}
                            questionId="free-demo"
                            highlightedQuestionId={highlightedQuestionId}>
                            <p>
                                Yes. If you visit the Steam page, you can install the free demo. The demo contains the first few levels
                                of the game.
                            </p>
                        </AccordionControl>

                        <AccordionControl
                            question={<div>
                                Can I play the demo if I already own the full game?
                            </div>}
                            questionId="demo-with-full-game"
                            highlightedQuestionId={highlightedQuestionId}>
                            <p>
                                Technically yes, but it's tricky. Steam has some kind of policy where once you own a game, the associated
                                demo isn't easily accessible. The easiest approach is to create an alternate Steam account, and use it
                                to download the demo. After that point, both the Demo and the full game should be available on that
                                computer.
                            </p>

                            <p>
                                There's also an approach where you can use SteamCmd to perform a <pre>force_install_dir</pre> to get the
                                demo installed. But drop by the Discord to ask about that if you'd like to try it.
                            </p>
                        </AccordionControl>

                        <AccordionControl
                            question={<div>
                                When will Gravia be finished?
                            </div>}
                            questionId="release-date"
                            highlightedQuestionId={highlightedQuestionId}>
                            <p>
                                Gravia went into Early Access on March 15, 2021. I'm hoping to complete the remaining work, with a full release about a year after that.
                            </p>
                        </AccordionControl>



                        <AccordionControl
                            question={<div>
                                What game engine is Gravia developed in?
                            </div>}
                            questionId="game-engine"
                            highlightedQuestionId={highlightedQuestionId}>
                            <p>
                                Gravia is made in Unity 2019, using HDRP. I eventually plan to move the project to Unity 2021 LTS some time in early 2022, assuming there are enough advantages to doing so.
                            </p>
                        </AccordionControl>



                        <AccordionControl
                            question={<div>
                                What are the minumum system requirements to run Gravia?
                            </div>}
                            questionId="system-specs"
                            highlightedQuestionId={highlightedQuestionId}>
                            <div>
                                <p>
                                    I've included a large number of graphics options in Gravia, which means that it should run fairly well even on somewhat older hardware as long as you turn down/off some of the settings. Minimally, the following are required:
                                </p>
                                <ul>
                                    <li>A Graphic Card that support Shader Model 5.0 or higher.</li>
                                    <li>About 2 GB of RAM</li>
                                    <li>About 3GB of disk space</li>
                                </ul>
                                <p>
                                    On better systems, you can turn on/up all of the graphics settings for the best visual experience. For comparison, I run Gravia on an i7-7820HK CPU, with a GTX 1070 GPU, at 1920 x 1080 resolution, with maxed settings, and normally run at at least 60 FPS. I also run Gravia on a 2015 MacBook with a built-in GPU, at low resolution, and it runs pretty well (usually between 30 and 45 FPS).
                                </p>

                                <p>
                                    One simple way to see how Gravia will run on your computer is to try the free demo. The demo generally has the performance and graphics settings as the full game.
                                </p>
                            </div>
                        </AccordionControl>


                        <AccordionControl
                            question={<div>
                                What platforms are supported?
                            </div>}
                            questionId="platforms"
                            highlightedQuestionId={highlightedQuestionId}>
                            <p>
                                I'm focusing on Windows PC during most of development, along with a MacOS version. After
                                the full release, I may look at porting to consoles. Gravia features full gamepad support
                                for those who prefer it, so porting to consoles should be relatively simple.
                            </p>
                        </AccordionControl>


                        <AccordionControl
                            question={<div>
                                Will VR be supported?
                            </div>}
                            questionId="vr"
                            highlightedQuestionId={highlightedQuestionId}>
                            <p>
                                I'm not planning on supporting VR at this time. Maybe in the future. In general, the
                                gameplay in Gravia doesn't seem like a great fit for VR. You're constantly being moved
                                around by Rifts, which tends to be nauseating in VR. I'll reevaluate adding VR support
                                sometime down the road, after the release of the full game.
                            </p>
                        </AccordionControl>


                        <AccordionControl
                            question={<div>
                                What about multiplayer?
                            </div>}
                            questionId="multiplayer"
                            highlightedQuestionId={highlightedQuestionId}>
                            <p>
                                I experimented with multiplayer early on, and it just didn't feel right. Gravia has
                                a lot of precise movement, which depends on players developing an intuition about how
                                their Rift will move them. As soon as another player was present, all of that precise
                                movement was completely disrupted by the other player's Rifts. It mostly just felt like
                                you were moving around at random. I ultimately scrapped the idea of having multiplayer,
                                since I couldn't come up with an approach that seemed fun, and building the game with
                                multiplayer support would have required a great deal of extra engineering.
                            </p>
                        </AccordionControl>




                        <AccordionControl
                            question={<div>
                                I have an idea you might want to use. What should I do?
                            </div>}
                            questionId="feedback"
                            highlightedQuestionId={highlightedQuestionId}>
                            <p>
                                I always welcome feedback and suggestions. A lot of the best parts of Gravia grew out of
                                feedback that players have provided. If you have some ideas, try stopping by the Discord
                                server and letting me know: <ExternalLink url={AppConstants.ExternalURLs.Discord} showIcon={true} title={AppConstants.ExternalURLs.Discord}>{AppConstants.ExternalURLs.Discord}</ExternalLink>
                            </p>
                        </AccordionControl>

                        <AccordionControl
                            question={<div>
                                What about "white holes"?
                            </div>}
                            questionId="white-holes"
                            highlightedQuestionId={highlightedQuestionId}>
                            <p>
                                Funny you should ask...
                            </p>
                        </AccordionControl>


                        <AccordionControl
                            question={<div>
                                How difficult is Gravia?
                            </div>}
                            questionId="difficulty"
                            highlightedQuestionId={highlightedQuestionId}>
                            <div>
                                <p>
                                    My current goal is for Gravia to be very accessible to players of a wide range of gaming
                                    skill. Moving around using a Rift is something you need to get used to, and it can be
                                    a bit tricky at first. But most levels are designed to test the player's problem solving
                                    skills more than their physical reflexes. Ideally, people less experienced with first-person
                                    shooter-style games should not find the gameplay too difficult for them.
                                </p>
                                <p>
                                    But that's just for the "main" game. For the hardcode players, there are optional secret areas
                                    hidden throughout the game. It's not uncommon for some players to go the entire game without
                                    even knowing that any secret areas exist. Secrets tend to either be very well hidden, requiring
                                    a lot of exploration, or extremely difficult to reach. For players who want the "full" Gravia
                                    challenge, reaching all of the secrets areas will be for them.
                                </p>
                                <p>
                                    In practice, this means that if I find that some part of the game is too difficult, I'm
                                    likely to convert it into an optional secret area, so that typical players won't get stuck.
                                </p>
                            </div>
                        </AccordionControl>

                        <AccordionControl
                            question={<div>
                                How long is the game? How much content is there? Is there any replay value?
                            </div>}
                            questionId="game-length"
                            highlightedQuestionId={highlightedQuestionId}>
                            <div>
                                <p>
                                    The average playthrough time for the "core" game is somewhere around 6 hours, with the goal being around 8 hours by the full release.
                                    Beyond that, players who try to find all the hidden secrets tend to spend another 10-15 hours on that.
                                </p>
                                <p>
                                    In terms of replay value, after you complete a certain portion of the game, you unlock
                                    "Time Trial" mode, which allows you to replay existing level as fast as possible, while racing the clock.
                                    I'm hoping to add other modes to the game as well, to improve replayability.
                                </p>
                            </div>
                        </AccordionControl>

                        <AccordionControl
                            question={<div>
                                So, about these "Secrets"... What else is there to know?
                            </div>}
                            questionId="secrets"
                            highlightedQuestionId={highlightedQuestionId}>
                            <div>
                                <p>
                                    Secrets are usually much more difficult to reach than the "normal" goal of each level. Gravia lets you replay earlier levels to look for secrets, but you don't get to bring any abilities back with you. So, all secrets can be completed the first time you reach a level. They simply require insight and precise timing.
                                </p>
                                <p>
                                    Secrets represent the most difficult challenges Gravia has to offer, but the difficulty of each secret varies. Some secrets don't require any great "skill" at all to reach; just a decision to explore where most people wouldn't think to look. Other secrets require extremely precise platforming skills. And others require solving some of the most difficult puzzles in the game. Probably my favorite secrets are the ones that require all three of these at once.
                                </p>
                                <p>
                                    Secrets have also been carefully arranged such that they never require the player to die in order to either reach them or return from them. In cases where a level has more than one secret, getting one secret does not prevent the player from getting the other secrets in the level. In other words, it's technically possible to 100% no-death Gravia, getting every secret and not dying a single time.
                                </p>
                            </div>
                        </AccordionControl>

                        <AccordionControl
                            question={<div>
                                Is it okay to stream the game, or make videos on it?
                            </div>}
                            questionId="streaming"
                            highlightedQuestionId={highlightedQuestionId}>
                            <p>
                                Sure. And feel free to share it with me, as I enjoy watching people play through the game.
                                The only thing you might want to mention to your viewers is that Gravia is heavily
                                puzzle-based, so most gameplay will be a bit spoilery if they choose to watch.
                            </p>
                        </AccordionControl>

                        <AccordionControl
                            question={<div>
                                Any helpful suggestions for Streamers/YouTubers?
                            </div>}
                            questionId="streaming-suggestions"
                            highlightedQuestionId={highlightedQuestionId}>
                            <p>
                                <ul>
                                    <li>Generally, the bottom-left corner of the screen isn't heavily used, and is a good place for your overlay.</li>
                                    <li>There are some Graphics Settings you should probably disable while streaming, as they tend to add some visual noise that lowers the quality of the stream/recording. Those are:
                                        <ul>
                                            <li><b>Rift Force Camera Shake</b> - This adds some screen shake, which you probably want to disable.</li>
                                            <li><b>Grain</b> - Adds a "filmic" quality, but also some visual noise.</li>
                                            <li><b>Motion Blur</b> - Up to you.</li>
                                        </ul>
                                    </li>
                                </ul>
                            </p>
                        </AccordionControl>

                        <AccordionControl
                            question={<div>
                                Does Gravia have a story?
                            </div>}
                            questionId="story"
                            highlightedQuestionId={highlightedQuestionId}>
                            <div>
                                <p>
                                    Yes, but it's not really in the game yet. I'm focusing on the gameplay right now, and I'm waiting a bit before I include most of the story elements in the game.
                                </p>
                                <p>
                                    But Gravia does have an original, linear sci-fi story with a small cast of characters. The story
                                    is mainly revealed through communications that pop up at various points throughout the
                                    game, as well as featuring optional story elements the player can choose to collect if
                                    they want to dig a bit deeper into the lore. The story will guide the player through the
                                    high-tech facility that has developed Gravia Glove technology, among other advanced
                                    technologies.
                                </p>
                            </div>
                        </AccordionControl>



                        <AccordionControl
                            question={<div>
                                How bad is motion sickness for most players?
                            </div>}
                            questionId="motion-sickness"
                            highlightedQuestionId={highlightedQuestionId}>
                            <p>
                                Most of the time, motion sickness isn't bad. If you're experiencing motion sickness while playing, my advice is:
                                <ul>
                                    <li>Lower the Graphics settings to increase framerates. High framerates usually make the game feel more responsive (less laggy), and dramatically help with motion sickness.</li>
                                    <li>Toggle the 'V-Sync' setting in Graphics Settings. Some players prefer this to be on, others prefer it to be off.</li>
                                    <li>Trying adjusting the FOV in Graphics Settings. Some players have fewer issues with motion sickness at lower FOV, others feel better at high FOV. It's a personal preference.</li>
                                    <li>If all else fails, try the Motion Sickness Bars option in General Settings. This is an experimental feature that simply adds some horizontal bars to the screen. I'm not yet sure how big of a difference this makes, though.</li>
                                </ul>
                            </p>
                        </AccordionControl>


                        <AccordionControl
                            question={<div>
                                Are you looking for Alpha/Beta Testers?
                            </div>}
                            questionId="testers"
                            highlightedQuestionId={highlightedQuestionId}>
                            <p>
                                "Alpha" officially ended on March 15, 2021, with the release of Early Access. Right now there
                                isn't an official "beta" program, but I'll reevaluate that in the future.
                            </p>
                        </AccordionControl>


                        <AccordionControl
                            question={<div>
                                Who is on the dev team?
                            </div>}
                            questionId="dev-team"
                            highlightedQuestionId={highlightedQuestionId}>
                            <p>
                                Gravia is a solo project. I do almost everything personally. I did hire out for a small
                                number of things: The UI for menus was designed by my girlfriend Danica; and I hired
                                a 3D Artist (Joao Silva - <ExternalLink url="https://www.flatdepth.com" showIcon={true} title="https://www.flatdev.com">https://www.flatdepth.com</ExternalLink>)
                                for the 3D Character models, including the player's hands/glove.
                            </p>
                        </AccordionControl>


                        <AccordionControl
                            question={<div>
                                How long has Gravia been in development?
                            </div>}
                            questionId="development-history"
                            highlightedQuestionId={highlightedQuestionId}>
                            <p>
                                I first had the idea for Gravia in 2011, but I did not yet have the skills to try to
                                build it. In 2016 I started learning Unity, and decided to make a simple prototype of
                                Gravia as a learning project. In my free time, I worked on adding more features to the
                                prototype. In 2018 I decided to really commit to Gravia, and started working on it full-time.
                                So, Gravia has been in active, full-time development for about three years as of March 15, 2021, with
                                about two years of part-type prototyping before that.
                            </p>
                        </AccordionControl>


                        <AccordionControl
                            question={<div>
                                Is any personal data collected while I play the game?
                            </div>}
                            questionId="privacy"
                            highlightedQuestionId={highlightedQuestionId}>
                            <div>
                                <p>
                                    Being a Unity game, certain data is captured and submitted to Unity, mainly in the form of system specs to aid in troubleshooting. You can view Unity's privacy policy, and opt-out of this kind of information gathering, on their site: <ExternalLink url="https://unity3d.com/legal/privacy-policy" showIcon={true} title="https://unity3d.com/legal/privacy-policy">https://unity3d.com/legal/privacy-policy</ExternalLink>)
                                </p>
                                <p>
                                    Independent of Unity's analytics, Gravia also captures some non-personal, in-game data about how the game is played, which I refer to as "Telemetry". This data contains data on what your character does in the game, such as creating Rifts, moving around, where your character dies, etc. This helps me to better understand how people are playing Gravia. This doesn't contain any sensitive information. The only personal information it may contain is your Name and Email Address, and only if you choose to enter that data into a Feedback form. (Neither are required.)
                                </p>
                            </div>
                        </AccordionControl>
                        {/*
                        <AccordionControl
                            question={<div>

                            </div>}
                            questionId=""
                            highlightedQuestionId={highlightedQuestionId}>
                            <p>

                            </p>
                        </AccordionControl>
                        */}

                        <div className="spacer-row" />
                    </div>
                </ReactDocMeta>
            </PageLayout>
        );
    }
}


export default FAQ;