// @flow
import React, {Component} from 'react';
import './BlogAllPosts.css';
import ReactRouter from 'react-router-dom'
import BlogService from '../../Services/BlogService';
import PortfolioLib from 'goyette-portfolio-lib';
import WaitOverlay from '../../Components/WaitOverlay/WaitOverlay';
import BlogTagLink from '../../Components/BlogTagLink/BlogTagLink';
import {Link} from 'react-router-dom'
import Utils from '../../utils';

let blogService = new BlogService();


class BlogAllPosts extends Component {
    props: {
        match: ReactRouter.Match
    };


    static defaultProps = {};

    state: {
        isLoadingPosts: boolean,
        postPreviews: Array<PortfolioLib.BlogPostPreviewModel>,
        nextLinkPath: ?string,
        previousLinkPath: ?string,
    };


    constructor(props: $PropertyType<BlogAllPosts, 'props'>) {
        super(props);

        this.state = {
            isLoadingPosts: true,
            postPreviews: [],
            nextLinkPath: null,
            previousLinkPath: null
        };
    }

    componentDidMount() {
        this.loadPostsPreviews(this.props);
    }



    componentWillReceiveProps(newProps : $PropertyType<BlogAllPosts, 'props'>) {
        this.loadPostsPreviews(newProps);

        document.title = `All Posts - Page ${this.props.match.params.pageNumber}`;
    }

    loadPostsPreviews(props: $PropertyType<BlogAllPosts, 'props'>) {
        let currentPageNumber = parseInt(props.match.params.pageNumber || 1, 10);

        this.setState({isLoadingPosts: true});


        let recordsPerPage = 100;
        let pageIndex = currentPageNumber - 1;
        let sortBy = "createDate";
        let sortAsc = false;
        let requestModel = new PortfolioLib.DataPageRequestModel(recordsPerPage,
            pageIndex,
            sortBy,
            sortAsc);

        // A convenient way to clear/reset settings whether on success or error.
        let resetState = () => {
            this.setState({
                postPreviews: [],
                isLoadingPosts: false,
                nextLinkPath: null,
                previousLinkPath: null
            });
        };

        blogService.fetchPostPreviews(requestModel)
            .then((dataPage) => {
                resetState();
                this.setState({
                    postPreviews: dataPage.records,
                    nextLinkPath: (dataPage.totalPages > currentPageNumber) ? PortfolioLib.SharedUtils.replaceRouteParam(PortfolioLib.SharedRoutes.UI.Blog.AllPosts.Path, PortfolioLib.SharedRoutes.UI.Blog.AllPosts.PageNumberParam, (currentPageNumber + 1).toString()) : null,
                    previousLinkPath: currentPageNumber > 1 ? PortfolioLib.SharedUtils.replaceRouteParam(PortfolioLib.SharedRoutes.UI.Blog.AllPosts.Path, PortfolioLib.SharedRoutes.UI.Blog.AllPosts.PageNumberParam, (currentPageNumber - 1).toString()) : null

                });
            })
            .catch((err) => {
                Utils.notifyException(err, "Error getting Posts content");
                resetState();
            });
    }


    render() {

        return (
            <div style={{position: "relative"}}>

                <h2>All Posts</h2>


                {!this.state.isLoadingPosts &&
                <div>
                    {this.state.postPreviews &&
                    <div>
                        {this.state.postPreviews.map((postPreview, i) => (
                            <div key={i} style={{marginBottom: "15px"}}>
                                <div>
                                    <Link
                                        rel="canonical"
                                        to={PortfolioLib.SharedUtils.replaceRouteParam(PortfolioLib.SharedUtils.replaceRouteParam(PortfolioLib.SharedRoutes.UI.Blog.ViewPost.Path, PortfolioLib.SharedRoutes.UI.Blog.ViewPost.PostIdParam, postPreview.postId), PortfolioLib.SharedRoutes.UI.Blog.ViewPost.LongIdParam, postPreview.longId) }>
                                        <span className="BlogAllPosts-post-title">{postPreview.title}</span>
                                    </Link>
                                </div>
                                <div> {PortfolioLib.SharedUtils.dateToShortDateString(postPreview.date)}
                                    &nbsp;-&nbsp;<BlogTagLink tags={postPreview.tags} /></div>
                            </div>
                        ))}
                        <div style={{textAlign: "center"}}>
                            {this.state.previousLinkPath &&
                            <Link
                                to={this.state.previousLinkPath}>
                                <span>&lt; Previous</span>
                            </Link>
                            }

                            {this.state.nextLinkPath &&
                            <Link
                                style={{marginLeft: "10px"}}
                                to={this.state.nextLinkPath}>
                                <span>Next &gt;</span>
                            </Link>
                            }
                        </div>
                    </div>
                    }

                    {this.state.postPreviews.length === 0 &&
                    <p>No results found.</p>
                    }

                </div>
                }


                {this.state.isLoadingPosts &&
                <WaitOverlay />
                }

            </div>
        );
    }
}

export default BlogAllPosts;