// @flow
import React, {Component} from 'react';
import './Presskit.css';
import PageLayout from '../../../../../Components/PageLayout/PageLayout'
import {default as bgImage} from './Images/VRChair.jpg';
import Utils from "../../../../../utils";
import AppConstants from "../../../../../appConstants";
import ExternalLink from '../../../../../Components/ExternalLink/ExternalLink';
import ReactDocMeta from 'react-document-meta';
import ImagePreview from '../../../../../Components/ImagePreview/ImagePreview';

class Presskit extends Component {

    render() {
        let canonicalUrl = "https://www.graviagame.com/support/presskit";
        let metaDescription= "Gravia info and resources for creators and reviewers";
        let metaTags = [
            {name: "description", content: metaDescription},
            {property: "og:title", content: "Gravia - Presskit"},
            {property: "og:type", content: "website"},
            {property: "og:description", content: metaDescription},
            {property: "og:image", content: "https://www.graviagame.com" + bgImage},
            {property: "og:url", content: canonicalUrl},
            {property: "fb:app_id", content: Utils.getFacebookAppId()}
        ];


        return (
            <PageLayout caption="Presskit" backgroundImage={bgImage} opacity={0.15} grayscale={0.1}>
                <ReactDocMeta tags={metaTags}>
                    <div>
                        <p>
                            If you're reviewing Gravia, or creating content on it, the information and resources here may be useful. If you're creating content about Gravia, please provide a link to the game. Let me know if you're looking for something that I haven't included here.
                        </p>

                        <div className="spacer-row" />
                        <h2>Fact Sheet</h2>

                        <ul>
                            <li><b>Developer - </b>Dan Goyette</li>
                            <li><b>Release Date - </b>March 15, 2021 (Early Access)</li>
                            <li><b>Platforms - </b>PC and Mac, currently, with plans for consoles</li>
                            <li><b>Mode - </b>Single-player</li>
                            <li><b>Genre - </b>Action/Puzzle</li>
                            <li><b>Controls - </b>Mouse &amp; Keyboard recommended, but full controller/gamepad support.</li>
                            <li><b>Engine/Programming Language - </b>Unity (HDRP) / C#</li>
                            <li><b>Website - </b>https://www.graviagame.com</li>
                            <li><b>Price - </b>$19.99 during Early Access</li>
                            <li><b>Languages - </b>English</li>
                            <li><b>Social Media - </b>
                                <ExternalLink url={AppConstants.ExternalURLs.Discord} showIcon={false} title="Discord">
                                    Discord
                                </ExternalLink>, &nbsp;

                                <ExternalLink url={AppConstants.ExternalURLs.Twitter} showIcon={false} title="Twitter">
                                    Twitter
                                </ExternalLink>, &nbsp;

                                <ExternalLink url={AppConstants.ExternalURLs.Reddit} showIcon={false} title="Reddit">
                                    Reddit
                                </ExternalLink>, &nbsp;

                                <ExternalLink url={AppConstants.ExternalURLs.Instagram} showIcon={false} title="Instagram">
                                    Instagram
                                </ExternalLink>, &nbsp;

                                <ExternalLink url={AppConstants.ExternalURLs.Facebook} showIcon={false} title="Facebook">
                                    Facebook
                                </ExternalLink>
                            </li>
                            <li><b>Primary Contact - </b><ExternalLink url="mailto:dan@graviagame.com" showIcon={false} title="Email">
                                dan@graviagame.com
                            </ExternalLink>
                            </li>
                        </ul>

                        <h2>Overview</h2>

                        <p>Gravia is a first-person action/puzzle game where you create "black holes" called "Rifts". The game is set up as a linear progression through about 50 levels, each incoporating the base mechanic of Rifts, along with many other mechanics to complement this behavior.</p>
                        <p>In addition to the main game experience, Gravia also contains a bunch of optional "secrets" to find. These are generally very difficult to reach, and are intended for players who want to face the biggest challenges in the game.</p>
                        <p>Gravia is a solo-dev project. I do the design, programming, music, and most of the artwork. I've outsourced a couple of things: My girlfriend Danica built the UI for the game, and she does a lot of the Social Media work. I also hired an arist to make the character models: Joao Silva - <ExternalLink url="https://www.flatdepth.com" showIcon={true} title="https://www.flatdev.com">https://www.flatdepth.com</ExternalLink></p>

                        <h2>Logos and Resources</h2>

                        <p>You can download Gravia logos and some screenshots as a zip file here: <a href="/Files/GraviaPresskitAssets.zip"
                                                                                               download="GraviaPresskitAssets.zip">Gravia Presskit Assets</a></p>

                        <h2>History</h2>

                        <p>
                            Thanks to the magic of email history, I can trace Gravia's history to an email from August 2011, where I told my friend about a new idea I had:
                        </p>

                        <div className="Presskit-original-gravia-email">
                            <p>
                                So, the basic game mechanic is that the player has basically one tool at his disposal, which I'll call a Black Hole Gun. The player can adjust the "G" of the gun, but the basic idea is, the player fires the gun, which releases a somewhat fast-moving projectile, and then clicks a secondary-fire button to cause the projectile to stop where it is, and become a mini-black hole. That is, it starts acting like a gravity well, drawing the player, and other objects, toward it. The player can kill it at any time by pressing the secondary-fire button again. Possible uses for this tool include:
                            </p>
                            <ul>
                                <li>Allowing the player to jump over a pit they otherwise could not.</li>
                                <li>Moving around objects in the environment.</li>
                                <li>Completely destroying objects that fall into the black hole (which give an opportunity for it to be a combat-oriented weapon)</li>
                                <li>Maybe it'll have an anti-gravity mode, where it expels things away from it</li>
                                <li>Maybe you can fire the device into some kind of receptacle that causes the gravity to be evenly distributed over some area, like a ceiling or a wall, instead of being focused in a sphere.</li>
                            </ul>

                            <div className="Presskit-images-container">
                                <ImagePreview thumbnailPath={require('./Images/GraviaWhiteBoard.jpg')} imagePath={require('./Images/GraviaWhiteBoard.jpg')}
                                              caption="The first whiteboard sketch of the idea that would go on to become Gravia, from 2011"/>

                            </div>

                        </div>

                        <p>That was as far as things went at the time, since I didn't have any game development experience, and I estimated it would take years just to gain even the most basic skills. It seemed like an impossible undertaking, so I set it aside.</p>

                        <p>About five years later, in 2016, I had been playing around with game development, and I realized that making Gravia wasn't totally out of reach for me anymore. I ended up building a one-room proof-of-concept over a single weekend, which let me fire a projectile, convert it into a Rift, and pull objects into it. That was extremely encouraging, and I started to think there was a chance I could actually make the game.</p>

                        <p>Over the course of about a year, I worked on a prototype (called "ProtoGravia") in my spare time. It had about 20 levels, and introduced many of the gameplay mechanics that would be part of the full game. I had friends and family try out ProtoGravia, and it was encouraging how much they seemed to enjoy the concept. I was enjoying working on the game in my free time, but once again I had the feeling that I would never be able to complete the game if I was only working on it in my spare time. So I made the decision to leave my job, and work on Gravia full time. This can easily be categorized as a "bad idea" in most cases, but I felt it wasn't totally absurd. I had some money saved that I felt would last me a couple of years if I was careful, and this was something I just really wanted to do.</p>

                        <p>So in December 2017, I stopped working at my "real" job, and focused on Gravia full time. I started with a few months of design work and technical research, and then decided to create a playable demo to represent a "vertical slice" of the game. The "Conference Demo" took me a few months to make. When it was complete, I started working on the actual full game some time in 2018.</p>

                    </div>
                </ReactDocMeta>
            </PageLayout>
        );
    }
}


export default Presskit;