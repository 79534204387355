// @flow
import React, {Component} from 'react';
import './FadeBackground.css';
import classNames from 'classnames';
import Utils from '../../utils';

class FadeBackground extends Component {
    props: {
        isClosing: boolean,
        onClick: () => void
    };

    static defaultProps = {
        isClosing: false,
        onClick: () => { }
    };

    componentDidMount() {
        // Create a delay so CSS will animate
        requestAnimationFrame(() => this.setState({isOpen: true}));
        Utils.disableBodyScroll();
    };

    componentWillUnmount() {
        Utils.enableBodyScroll();
        if (this.state.timer) {
            clearTimeout(this.state.timer);
            this.setState({timer: null});
        }
    }

    componentWillReceiveProps(nextProps: any) {
        if (nextProps.isClosing) {

            Utils.enableBodyScroll();

            this.setState({timer: setTimeout(() => {
                this.setState({isOpen: false});
            }, 500)});
        }
    }

    state: {
        isOpen: boolean,
        timer: any
    };

    constructor(props: any) {
        super(props);
        this.state = {
            isOpen: false,
            timer: null
        };
    }




    render() {
        let backgroundContainerClasses = classNames('FadeBackground-container-background',
            this.state.isOpen ? 'open' : '',
            this.props.isClosing ? 'closing' : ''
        );

        return (
            <div className={backgroundContainerClasses} onClick={(e) => this.onClickHandler()} />
        );
    }

    onClickHandler() {
        this.props.onClick();
    }

}

export default FadeBackground;