// @flow
import React, {Component} from 'react';
import './Gravia.css';
import AppConstants from '../../../appConstants';
import MessageProviderService from '../../../Services/MessageProviderService';
import AppMessageModel from '../../../Models/AppMessageModel';
import PageLayout from '../../../Components/PageLayout/PageLayout'
import ImagePreview from '../../../Components/ImagePreview/ImagePreview';
import YouTubeVideo from '../../../Components/YouTubeVideo/YouTubeVideo';
import ExternalLink from '../../../Components/ExternalLink/ExternalLink';
import MiscService from '../../../Services/MiscService';
import {Link} from 'react-router-dom';
import {GraviaGameEarlyAccessRoute} from "../../../routeConfig";
import Utils from '../../../utils';
import {FaTwitter} from 'react-icons/fa'
import {FaSteamSymbol} from 'react-icons/fa'
import {FaRedditAlien} from 'react-icons/fa'
import {FaDiscord} from 'react-icons/fa'
import {FaFacebook} from 'react-icons/fa'
import {FaInstagram} from 'react-icons/fa'
import {GoMail} from 'react-icons/go'
import {default as bgImage} from '../Media/Images/GraviaLogo.jpg';
import ReactDocMeta from 'react-document-meta';

let miscService = new MiscService();

class Gravia extends Component {

    state: {
        userEmailAddress: string,
        subscribeButtonDisable: boolean
    };


    constructor(props: any) {
        super(props);


        this.state = {
            userEmailAddress: "",
            subscribeButtonDisable: false
        };
    }


    handleEmailAddressValueChange(event: Event) {
        if (event.target instanceof HTMLInputElement) {
            this.setState({userEmailAddress: event.target.value});
        }
    }

    handleEmailAddressInputKeyDown(event: Event) {
        if (event.target instanceof HTMLInputElement) {
            if (event.key === "Enter") {
                this.subscribeToNews();
            }
        }
    }

    subscribeToNews() {
        if (this.state.userEmailAddress.trim() === "" || this.state.userEmailAddress.indexOf("@") < 0) {
            MessageProviderService.instance.notify(new AppMessageModel("Please enter a valid email address", null, true));
        } else {
            miscService.subscribeToGraviaNews(this.state.userEmailAddress)
            .then((success) => {
                Utils.notifyMessage("Thanks! We'll let you know about big Gravia announcements.");

                // Disable the button for a while.
                this.setState({userEmailAddress: "", subscribeButtonDisable: true});

                setTimeout(
                    function() {
                        this.setState({subscribeButtonDisable: false});
                    }
                    .bind(this),
                    3000
                );
            })
            .catch((err) => {
                Utils.notifyException(err, "Error subscribing");
            });
        }
    }

    render() {
        let canonicalUrl = "https://www.graviagame.com/";
        let metaDescription= "Gravia is a gravity-themes action/puzzle game that allows you to create small 'black holes' called Rifts.";
        let metaTags = [
            {name: "description", content: metaDescription},
            {property: "og:title", content: "Gravia - Are you ready to try on the Glove?"},
            {property: "og:type", content: "website"},
            {property: "og:description", content: metaDescription},
            {property: "og:image", content: "https://www.graviagame.com" + bgImage},
            {property: "og:url", content: canonicalUrl},
            {property: "fb:app_id", content: Utils.getFacebookAppId()}
        ];


        return (
            <PageLayout caption="" title="Gravia" showBannerImage={false} opacity={0.1} grayscale={0.0}>
                <ReactDocMeta tags={metaTags}>
                    <div>
                        <YouTubeVideo url="https://www.youtube.com/embed/pvzW-DiIC4k?autoplay=1&mute=1" title="Gravia Early Access Trailer"
                                      classes="Gravia-embedded-video"/>

                        <div className="spacer-row" />

                        <div className="Gravia-big-link-wrap">
                            <a className="Gravia-big-link-button" target="_blank" href={AppConstants.ExternalURLs.Steam} rel="noopener noreferrer">
                                <div className="Gravia-big-link">
                                    <div className="Gravia-big-link-icon"><FaSteamSymbol/></div>
                                    <div className="Gravia-big-link-text">Buy on Steam / Try the Demo</div>
                                </div>
                            </a>
                        </div>
                        <div className="spacer-row" />
                        <div className="Gravia-big-link-wrap">
                            <a className="Gravia-big-link-button" target="_blank" href={AppConstants.ExternalURLs.Discord} rel="noopener noreferrer">
                                <div className="Gravia-big-link">
                                    <div className="Gravia-big-link-icon"><FaDiscord/></div>
                                    <div className="Gravia-big-link-text">Join us on Discord</div>
                                </div>
                            </a>

                        </div>

                        <div className="spacer-row" />

                        <p>Gravia is a gravity-themed action/puzzle game where you create small "black holes" called Rifts. You'll use this power to destroy obstacles, solve puzzles, move yourself at high speeds, and hopefully not destroy yourself in the process. Most of the gameplay is a blend of physics-based action and puzzle-oriented problem solving, requiring you to come up with clever solutions.</p>

                        <div className="Gravia-images-container">
                            <ImagePreview thumbnailPath={require('../Media/Images/EarlyAccess-0.7.0/Thumbs/Destroyer_tn.jpg')} imagePath={require('../Media/Images/EarlyAccess-0.7.0/Destroyer.jpg')}
                                          caption="Gravitation"/>
                            <ImagePreview thumbnailPath={require('../Media/Images/EarlyAccess-0.7.0/Thumbs/Anomaly_Close_tn.jpg')} imagePath={require('../Media/Images/EarlyAccess-0.7.0/Anomaly_Close.jpg')}
                                          caption="The force isn't too bad when it's stable, but those pulses..."/>

                        </div>

                        <div className="spacer-row" />
                        <p>Gravia uses a realistic physics system that simulates the gravitational effects of having a black hole in a closed environment. From the instant a Rift forms, it begins to pull any loose or flexible objects as they "fall" into the Rift. Even reinforced objects might bend and break if the forces are strong enough. </p>

                        <div className="Gravia-images-container">
                            <ImagePreview thumbnailPath={require('../Media/Images/EarlyAccess-0.7.0/Thumbs/Pipes_tn.jpg')} imagePath={require('../Media/Images/EarlyAccess-0.7.0/Pipes.jpg')}
                                          caption="Bending and breaking, Rifts even absorb the steam"/>

                            <ImagePreview thumbnailPath={require('../Media/Images/EarlyAccess-0.7.0/Thumbs/RealityCheck_tn.jpg')} imagePath={require('../Media/Images/EarlyAccess-0.7.0/RealityCheck.jpg')}
                                          caption="This is generally not something you want to see."/>

                        </div>

                        <div className="spacer-row" />
                        <h3>Features &amp; Mechanics</h3>
                        <ul>
                            <li><b>Gravia Glove - </b> Throughout most of the game, your main ability is creating Rifts with the Gravia Glove. You'll use their gravity to do some incredible things.</li>
                            <li><b>Core Game -</b> Gravia has around 55 unique levels so far, providing about 6 hours of gameplay on average.</li>
                            <li><b>Secrets -</b> Most levels contain optional "Secrets", which are generally very difficult to reach, and require mastery of the game's mechanics.</li>
                            <li><b>Time Trial Mode -</b> Replay levels while racing the clock to beat the goal times, or lower your own personal best.</li>
                        </ul>

                        <div className="spacer-row"/>
                        <h3>Early Access</h3>
                        <p>Gravia entered Early Access on March 15, 2021. For more specifics on what's currently in the game, and what's planned for the full release, please take a look at the <Link to={GraviaGameEarlyAccessRoute.path}>Early Access</Link> page. </p>

                        <div className="spacer-row" />




                        <div className="Gravia-follow-wrap">
                            <div className="Gravia-follow-item-wrap">
                                <ExternalLink url={AppConstants.ExternalURLs.Steam} showIcon={false} title="Steam">
                                    <div className="Contact-item-icon"><FaSteamSymbol/></div>
                                </ExternalLink>
                            </div>

                            <div className="Gravia-follow-item-wrap">
                                <ExternalLink url={AppConstants.ExternalURLs.Discord} showIcon={false} title="Discord">
                                    <div className="Contact-item-icon"><FaDiscord /></div>
                                </ExternalLink>
                            </div>

                            <div className="Gravia-follow-item-wrap">
                                <ExternalLink url={AppConstants.ExternalURLs.Twitter} showIcon={false} title="Twitter">
                                    <div className="Contact-item-icon"><FaTwitter/></div>
                                </ExternalLink>
                            </div>

                            <div className="Gravia-follow-item-wrap">
                                <ExternalLink url={AppConstants.ExternalURLs.Reddit} showIcon={false} title="Reddit">
                                    <div className="Contact-item-icon"><FaRedditAlien /></div>
                                </ExternalLink>
                            </div>

                            <div className="Gravia-follow-item-wrap">
                                <ExternalLink url={AppConstants.ExternalURLs.Instagram} showIcon={false} title="Instagram">
                                    <div className="Contact-item-icon"><FaInstagram/></div>
                                </ExternalLink>
                            </div>

                            <div className="Gravia-follow-item-wrap">
                                <ExternalLink url={AppConstants.ExternalURLs.Facebook} showIcon={false} title="Facebook">
                                    <div className="Contact-item-icon"><FaFacebook /></div>
                                </ExternalLink>
                            </div>

                            <div className="Gravia-follow-item-wrap">
                                <ExternalLink url="mailto:dan@graviagame.com" showIcon={false} title="Email">
                                    <div className="Contact-item-icon"><GoMail /></div>
                                </ExternalLink>
                            </div>
                        </div>


                    </div>
                </ReactDocMeta>
            </PageLayout>
        );
    }
}


export default Gravia;