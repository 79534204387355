// @flow
import React, {Component} from 'react';
import './Home.css';
import PageLayout from '../../../Components/PageLayout/PageLayout'
import {Link} from 'react-router-dom'
import {default as bgImage} from './Images/Dan.jpg';
import {DanGoyetteConsultingRoute, DanGoyetteMusicRoute, BlogRoute} from '../../../routeConfig.js';
import ExternalLink from '../../../Components/ExternalLink/ExternalLink';

class Home extends Component {
    render() {
        return (
            <div>
                <PageLayout caption="Dan Goyette" backgroundImage={bgImage} opacity={0.5} grayscale={0.0}
                            title="Dan Goyette">
                    <p>
                        I'm a professional software engineer and game developer. I've worked in software for more than 15 years. As of November 2016 I transitioned to a fully remote lifestyle. I'm currently living and working in Seattle, Washington.
                    </p>
                    <p>
                        I'm currently focused on building my game, <ExternalLink url="https://www.graviagame.com">Gravia</ExternalLink>.
                    </p>
                    <p>
                        I also have an interest in Personal Finance and Lifestyle Design, which I write about on my <Link to={BlogRoute.path}>Blog</Link>. This site also contains some of my work as a <Link to={DanGoyetteConsultingRoute.path}>Software Engineer</Link> and my <Link to={DanGoyetteMusicRoute.path}>Music</Link>.
                    </p>
                </PageLayout>
            </div>
        );
    }
}

export default Home;