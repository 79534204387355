// @flow
import React, {Component} from 'react';
import './BlogPostPreview.css';
import ReactRouter, {Link} from 'react-router-dom';
import PortfolioLib from 'goyette-portfolio-lib';
import AuthStatusProviderService from '../../Services/AuthStatusProviderService';
import BlogTagLink from '../../Components/BlogTagLink/BlogTagLink';
import {GoPencil} from 'react-icons/go'
import {GoX} from 'react-icons/go'
import BlogService from '../../Services/BlogService';
import Utils from '../../utils';
/*
import { Share as TwitterShare } from 'react-twitter-widgets'
import AppConstants from "../../appConstants";
*/

let blogService = new BlogService();

class BlogPostPreview extends Component {
    props: {
        postPreviewModel: PortfolioLib.BlogPostPreviewModel,
        authStatus: PortfolioLib.AuthStatusModel,
        onDelete: () => void,
        history: ReactRouter.history
    };


    static defaultProps = {
        onDelete: () => {},
        history: {}
    };

    state: {
        authStatus: PortfolioLib.AuthStatusModel,
        authStatusProviderSubscriptionKey: string
    };


    constructor(props: $PropertyType<BlogPostPreview, 'props'>) {
        super(props);

        this.state = {
            authStatus: AuthStatusProviderService.instance.authStatusModel,
            authStatusProviderSubscriptionKey: ""
        };
    }

    componentDidMount() {
        this.setState({authStatusProviderSubscriptionKey: AuthStatusProviderService.instance.subscribe((newAuthStatus) => this.updateAuthStatus(newAuthStatus))});
    }

    updateAuthStatus(newAuthStatus: PortfolioLib.AuthStatusModel) {
        this.setState({authStatus: newAuthStatus});
    }

    componentWillUnmount() {
        AuthStatusProviderService.instance.unsubscribe(this.state.authStatusProviderSubscriptionKey);
    }


    deletePost() {
        if (window.confirm("Are you sure you want to delete this post complete?")) {
            blogService.deletePost(parseInt(this.props.postPreviewModel.postId, 10))
                .then((result) => {
                    Utils.notifyMessage(result.message);
                    this.props.onDelete();
                })
                .catch((err) => {
                    Utils.notifyException(err, "Error saving Edit Model");
                });
        }
    }

    render() {
        let viewPostUrl = PortfolioLib.SharedUtils.replaceRouteParam(PortfolioLib.SharedRoutes.UI.Blog.ViewPost.Path, PortfolioLib.SharedRoutes.UI.Blog.ViewPost.PostIdParam, this.props.postPreviewModel.postId || "");
        viewPostUrl = PortfolioLib.SharedUtils.replaceRouteParam(viewPostUrl, PortfolioLib.SharedRoutes.UI.Blog.ViewPost.LongIdParam, this.props.postPreviewModel.longId || "");

        /*let twitterPostTitle = (Utils.getApplication() === AppConstants.Applications.DanGoyette ?  "Dan Goyette Blog - " : "Gravia Blog - ") + (this.props.postPreviewModel.title || "");*/

        return (
            <div key={this.props.postPreviewModel.postId} className="BlogPostPreview-main-container">

                {this.state.authStatus && this.state.authStatus.isAdmin &&
                <div>
                    <Link
                        to={PortfolioLib.SharedUtils.replaceRouteParam(PortfolioLib.SharedRoutes.UI.Blog.EditPost.Path, PortfolioLib.SharedRoutes.UI.Blog.EditPost.PostIdParam, this.props.postPreviewModel.postId || "")}>
                        <GoPencil className="BlogPostPreview-edit-button icon-button-icon "/>
                    </Link>
                    <button className="BlogPostPreview-delete-button icon-button delete" onClick={(e) => this.deletePost()}>
                        <GoX className="BlogPostPreview-edit-button icon-button-icon delete "/>
                    </button>
                </div>
                }

                <div className="BlogPostPreview-image-wrap">
                    <Link rel="canonical" to={viewPostUrl}>
                        <img className="BlogPostPreview-image" src={this.props.postPreviewModel.bannerImageThumbnailUrl}
                             alt={this.props.postPreviewModel.title}/>
                    </Link>
                </div>



                <div className="BlogPostPreview-title-and-tag-wrap">
                    <h2 className="BlogPostPreview-title">
                        <Link rel="canonical" to={viewPostUrl}>
                            <span>{this.props.postPreviewModel.title}</span>
                        </Link>
                    </h2>

                    <div
                        className="BlogPostPreview-date-and-tags">
                        <span>{PortfolioLib.SharedUtils.dateToShortDateString(this.props.postPreviewModel.date)}</span>
                        &nbsp;-&nbsp;
                        <BlogTagLink tags={this.props.postPreviewModel.tags}/>


                        {/* Note: Sharing has been removed here, because much of the sharing depends on
                        metadata, which is post-specific.*/}

                        {/*<div
                            style={{marginTop: "4px", display: "flex"}}>
                            <div style={{marginRight: "4px"}}>
                                <TwitterShare url={viewPostUrl}
                                          options={{
                                              size: "small",
                                              text: twitterPostTitle
                                          }}/>
                            </div>
                            <FacebookProvider appId={Utils.getFacebookAppId()}>
                                <Like href={viewPostUrl} colorScheme="dark" showFaces share size="small" layout="button_count"/>
                            </FacebookProvider>
                        </div>*/}
                    </div>
                </div>


                <p>
                    {this.props.postPreviewModel.excerpt}&nbsp;
                    <Link rel="canonical" to={viewPostUrl}>
                        <span>More...</span>
                    </Link>
                </p>

                <div className="clear-both"/>


            </div>
        );
    }
}

export default BlogPostPreview;