// @flow
import React, {Component} from 'react';
import './CubeDropPressKit.css';
import PageLayout from '../../../Components/PageLayout/PageLayout'
import {Link} from 'react-router-dom';
import {DanGoyetteCubeDropRoute} from '../../../routeConfig';

import {default as BannerImage} from './Images/Banner.jpg';


class CubeDropPressKit extends Component {
    render() {
        return (
            <PageLayout caption="Cube-Drop Press Kit" backgroundImage={BannerImage} opacity={0.0} grayscale={0.0}>
                <p>Thanks for taking a look at Cube-Drop. All of the Press Kit material has been incorporated into the main <Link to={DanGoyetteCubeDropRoute.path}>Cube-Drop</Link> page. If you need anything else, please let me know.</p>
            </PageLayout>
        );
    }
}


export default CubeDropPressKit;
