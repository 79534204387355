// @flow
import React, {Component} from 'react';
import './LoginLanding.css';
import PageLayout from '../../../Components/PageLayout/PageLayout'



class LoginLanding extends Component {


    componentDidMount() {
        window.close();
    }


    render() {
        return (
            <PageLayout caption="Login Successful" showBannerImage={false}>

                <h2>Login Successful</h2>

                <p>You can close this window.</p>
            </PageLayout>
        );
    }

}


export default LoginLanding;