// @flow
import React, {Component} from 'react';
import './HelpAndTroubleshooting.css';
import PageLayout from '../../../../../Components/PageLayout/PageLayout'
import AccordionControl from '../../../../../Components/AccordionControl/AccordionControl';
import {Link} from 'react-router-dom';
import Utils from '../../../../../utils';
import {GraviaGameContactRoute} from "../../../../../routeConfig";
import {default as bgImage} from './Images/Decomissioned.jpg';
import ReactDocMeta from 'react-document-meta';


class HelpAndTroubleshooting extends Component {

    state: {};


    constructor(props: any) {
        super(props);

        this.state = {};
    }


    render() {
        let canonicalUrl = "https://www.graviagame.com/Support/HelpAndTroubleshooting";
        let metaDescription = "Help and Troubleshooting";
        let metaTags = [
            {name: "description", content: metaDescription},
            {property: "og:title", content: "Gravia - Help and Troubleshooting"},
            {property: "og:type", content: "website"},
            {property: "og:description", content: metaDescription},
            {property: "og:image", content: "https://www.graviagame.com" + bgImage},
            {property: "og:url", content: canonicalUrl},
            {property: "fb:app_id", content: Utils.getFacebookAppId()}
        ];
        let highlightedQuestionId = this.props.location.hash;


        return (
            <PageLayout caption="Help And Troubleshooting" title="Help And Troubleshooting" backgroundImage={bgImage}
                        opacity={0.1} grayscale={0.0}>
                <ReactDocMeta tags={metaTags}>
                    <div>
                        <p>
                            Since Gravia is still an Early Access title, there's a good chance you'll run into some bugs,
                            both known and unknown. If you're running into a problem, hopefully the information on this
                            page will help. If not, please <Link to={GraviaGameContactRoute.path}>Contact Me</Link>.
                        </p>

                        <h2>Non-crashing Problems</h2>

                        <p>For issues you run into that don't cause Gravia to crash, the best thing you can do is submit Feedback within the game. This will give me extra details I can use to help diagnose the problem. You may also want to stop by Discord as well, in case there is a workaround to the problem.</p>

                        <h2>Crashing Problems</h2>

                        <p>
                            If Gravia shuts down unexpectedly, or won't start properly, there are a few steps to try.
                        </p>

                        <ul>
                            <li>Restart your computer.</li>
                            <li>Make sure your Graphics Card drivers are up to date.</li>
                            <li>Try lowering the Graphics settings in the game. I would recommend starting with the settings on Low. If that's stable, try Medium, and so on.</li>
                            <li>If a popup appeared when the crash occurred, please submit it, as it will help me pinpoint and fix the issue.</li>
                        </ul>
                        <p>
                            If that doesn't work, <Link to={GraviaGameContactRoute.path}>Contact Me</Link> by email or Discord.
                        </p>

                        <h2>Game Files</h2>
                        <p>Gravia stores various data on your system to save your progress and other settings. These are automatically synchronized with Steam, so your saved data <em>should</em> carry over to other computers.</p>

                        <p>If you need to access these files for some reason, they should be available in these locations:</p>
                        <ul>
                            <li>Windows:</li> Starting from your user directory (For example, "c:\Users\Dan"), Gravia's files are found in "AppData\LocalLow\Gravia Software, LLC\Gravia"
                            <li>Mac:</li> Files should be found in "~/Library/Application Support/Gravia Software, LLC/Gravia"
                        </ul>

                        <p>If you run into serious problems running Gravia, some times the best thing to do is to delete all of the save game files and settings files.</p>

                        <h2>Common Problems</h2>


                        <p>This section lists some common problems/errors that people have run into, along with specific troubleshooting steps, when available.</p>

                        <AccordionControl
                            question={<div>
                                The character is walking and/or turning slightly, even when you're not pressing any buttons.
                            </div>}
                            questionId="phantom-motion"
                            highlightedQuestionId={highlightedQuestionId}>
                            <div>
                                <p>
                                    This usually means you have a controller/gamepad connected, and that you're getting a small amount of input from it.
                                </p>
                                <p>
                                    The easier thing is to make sure you don't have any gamepads connected/active while playing in Mouse and Keyboard mode. If you're playing with a gamepad, then the issue is most likely that you need to increase the "dead zone" for the controller, to ignore small amounts of noise coming from the controller. There are sliders for adjusting the dead zone settings on the Settings screen, under the Gamepad section.
                                </p>
                            </div>
                        </AccordionControl>

                        <AccordionControl
                            question={<div>
                                "Error adding Enlighten system data ... RadiosityData is missing" / "Error adding Enlighten probeset ...  Data not available" / "GI output for inputsystem ... is missing"?
                            </div>}
                            questionId="enlighten-data-error-sync-related"
                            highlightedQuestionId={highlightedQuestionId}>
                            <div>
                                <p>
                                   This error means that some of the "lighting" data the game is looking for was not found in the installation directory of the game. I've seen this happen if the Gravia is running while Steam is in the middle of downloading an update to the game. In those cases, Steam may delete some of the "old" files, which the running game is expecting to find.
                                </p>
                                <p>
                                    Most likely, the only "fix" needed for this is to make sure Gravia is up to date, and restart the game. And in general, you should try to run the game via the Steam shortcut, rather than opening the .exe/app directly.
                                </p>
                            </div>
                        </AccordionControl>

                        <AccordionControl
                            question={<div>
                                When starting Gravia, the screen is completely black, and I can't see anything.
                            </div>}
                            questionId="black-screen-at-startup"
                            highlightedQuestionId={highlightedQuestionId}>
                            <div>
                                <p>
                                    Typically this is the result of using a GPU that isn't supported. Specifically, a GPU that doesn't support at least Shader Model 5.0. If that's the case, unfortunately there isn't anything that can be done without upgrading to a new GPU.
                                </p>
                            </div>
                        </AccordionControl>

                        <AccordionControl
                            question={<div>
                                Crashes when using Intel (U)HD Graphics 6XX
                            </div>}
                            questionId="intel-600-gpu"
                            highlightedQuestionId={highlightedQuestionId}>
                            <div>
                                <p>
                                    It seems that there are some issues with Intel HD or UHD 600 series graphics cards, which seem to crash frequently with Gravia (and perhaps any Unity HDRP game). At this time, I don't know of any solution, other than to make sure your graphics drivers are up to date.
                                </p>
                            </div>
                        </AccordionControl>


                        <AccordionControl
                            question={<div>
                                Thread group count is above the maximum allowed limit. Maximum allowed thread group count is 65535.
                            </div>}
                            questionId="thread-group-count-crash"
                            highlightedQuestionId={highlightedQuestionId}>
                            <div>
                                <p>
                                    This is an error that seems to only occur on Mac hardware. This appears to be a bug in Unity/HDRP, for which there doesn't yet appear to be a proper fix.
                                </p>
                                <p>
                                    It seems that this is much more likely to occur when running Gravia at very high resoltions. For example, this was recently reported to me while running at resolution 5120x2880. For now, the best workaround is probably to adjust the resolution down.
                                </p>
                            </div>
                        </AccordionControl>

                        {/*  Template...
                        <AccordionControl
                            question={<div>
                                Q?
                            </div>}
                            questionId="QUESTION-ID-CHANGE-ME"
                            highlightedQuestionId={highlightedQuestionId}>
                            <div>
                                <p>
                                    A
                                </p>
                            </div>
                        </AccordionControl>
                        */}


                        <div className="spacer-row" />
                    </div>
                </ReactDocMeta>
            </PageLayout>
        );
    }
}


export default HelpAndTroubleshooting;