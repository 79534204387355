// @flow
import React, {Component} from 'react';
import './CaseStudy_SportingGoods.css';
import PageLayout from '../../../Components/PageLayout/PageLayout'
import {default as bgImage} from './Images/Window.jpg';
import ImagePreview from '../../../Components/ImagePreview/ImagePreview';

import {default as img1Thumb} from './Images/Thumbs/SportingGoods01_tn.jpg';
import {default as img1} from './Images/SportingGoods01.jpg';

import {default as img2Thumb} from './Images/Thumbs/SportingGoods02_tn.jpg';
import {default as img2} from './Images/SportingGoods02.jpg';

import {default as img3Thumb} from './Images/Thumbs/SportingGoods03_tn.jpg';
import {default as img3} from './Images/SportingGoods03.jpg';

import {default as img4Thumb} from './Images/Thumbs/SportingGoods04_tn.jpg';
import {default as img4} from './Images/SportingGoods04.jpg';

class CaseStudy_SportingGoods extends Component {
    render() {
        return (
            <div>
                <PageLayout caption="Case Study - Sporting Goods Company" backgroundImage={bgImage} opacity={0.5}
                            grayscale={0.1}>
                    <h2>Summary</h2>
                    <p>A global sporting goods had been maintaining a WinForms Point of Sale system for about 15 years.
                        This application was used by several hundred field sales reps throughout the United States,
                        Canada, and Europe. Due to the age of the platform and the underlying UI technology, they were
                        experiencing difficulty adding new functionality that users were requesting. The
                        Company asked us to design and build a replacement for this system.</p>

                    <h2>Key Challenges</h2>
                    <ul>
                        <li>Build a new User Interface that was capable of running in an entirely disconnected state.
                        </li>
                        <li>Review, redesign, and reimplement 15 years of WinForms application functionality.</li>
                        <li>Reuse nearly all of the existing business tier, while addressing performance and correctness
                            concerns.
                        </li>
                    </ul>

                    <h2>Technologies</h2>
                    <ul>
                        <li>C# / VB.NET / .NET Framework 4.6+</li>
                        <li>WPF</li>
                        <li>Prism</li>
                        <li>WinForms</li>
                        <li>SQL Express</li>
                        <li>Sybase SQL Anywhere</li>
                        <li>Infragistics</li>
                        <li>WPF Toolkit</li>
                    </ul>

                    <h2>Approaches and Solutions</h2>

                    <ImagePreview thumbnailPath={img1Thumb} imagePath={img1} floatRight={true}
                                  caption="The primary order entry screen, where users can search for and order products."/>
                    <p>One of the first difficulties we faced was determining an appropriate platform for the new
                        system. The biggest consideration was the requirement that the application function properly on
                        user laptops when disconnected from the internet. The primary candidates we evaluated were WPF,
                        and a .NET web application running against a locally hosted web server. I considered the web
                        application approach mainly because I felt we would have greater development efficiency building
                        a web application compared to a WPF application, and because it would be easier for the Company
                        to hire web developers than it would be to find WPF developers. Ultimately we chose a WPF
                        application mainly due to the advantage of being able to reuse certain amounts of WinForms
                        content in the new application by rehosting the controls in WPF.</p>


                    <ImagePreview thumbnailPath={img2Thumb} imagePath={img2} floatLeft={true}
                                  caption="A dashboard that provided a holistic view of a customer."/>
                    <p>Prior to primary development, I took inventory of the functionality of the existing application.
                        This was a very large and complex task. The WinForms application contained several hundred
                        distinct controls which composed the user interface, and the application supported several
                        different ordering modes depending on the type of order a user was submitting. Given the age of
                        the application, it was full of workarounds that had been implemented over the years to address
                        specific business needs. The first work product I released was a detailed audit of every UI
                        screen in the application, which involved a thorough analysis of the underlying VB.NET
                        code-behind for each control. This analysis was critical in gaining an understanding of the
                        system and allowed me to plan the overall struture of the WPF project.</p>


                    <p>I chose to use Prism/Unity for MVVM and dependency injection. This is a very powerful technology
                        that dramatically simplified the development of the application. The overall structure of the
                        application included:</p>

                    <ImagePreview thumbnailPath={img3Thumb} imagePath={img3} floatRight={true}
                                  caption="The Customer/Order search interface."/>
                    <ul>
                        <li>A single "Shell", which hosted all other content.</li>
                        <li>A main menu bar, which featured both static and context-specific menu items.</li>
                        <li>A tabbed interface, where each tab represented either a Customer or an Order.</li>
                        <li>Within each tab, another menu system allowed navigation to different areas with the
                            Customer/Order.
                        </li>
                        <li>Dozens of Views that could be loaded into the tab depending on the navigation options chosen
                            by
                            the user.
                        </li>
                    </ul>

                    <p>Prism allowed each of these components to be developed independently, and composed dynamically in
                        response to user actions. It also allowed Views to be contained in distinct modules, most of
                        which were implemented as a separate Visual Studio projects and only loaded into memory when
                        the user actually made use of functionality from that module. This helped to improve initial
                        load time and memory footprint.</p>

                    <ImagePreview thumbnailPath={img4Thumb} imagePath={img4} floatLeft={true}
                                  caption="An interface for adding customization to a product."/>
                    <p>We identified early on
                        in the project that we would not be able to complete the project within our budget if we tried
                        to
                        reimplement all of the existing VB.NET code. The application contained a significant amount of
                        business logic implemented in VB.NET services that we would need to reuse. This proved difficult
                        for
                        two main reasons. First, we frequently encountered performance problems with the code,
                        especially
                        when we tried to access the code in a more asynchronous and event-driven way via WPF views. A
                        significant amount of time had to be spent reengineering some of this code to run efficiently.
                        Another concern with the existing business logic was that it was not initially designed with
                        unit
                        testing in mind, and represented a direct dependency for the application. We ended up writing
                        wrapper services to contain these functions in order to ensure the UI didn't have any
                        dependencies
                        on the underlying business tier.</p>

                    <h2>Outcome</h2>

                    <p>We released an initial version of the new application approximately one year after we began
                        development. This featured an entirely new user interface experience for the most common type of
                        order entry being performed. It also included a significant amount of rehosted WinForms content,
                        hosted within the WPF application. This version of the application was rolled out to the entire
                        US sales team. Over the course of another year of development we continued to reimplement
                        WinForms content into pure WPF, leaving behind only a tiny amount of WinForms code in the
                        application, mainly related to little-used Admin functionality. The completed application was
                        rolled out to the remaining Canada and Europe sales team.</p>

                </PageLayout>
            </div>
        );
    }


}

export default CaseStudy_SportingGoods;