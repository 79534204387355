// @flow
import AppConstants from '../appConstants';
import Utils from '../utils';
import PortfolioLib from 'goyette-portfolio-lib';


class AuthenticationService {


    login( username : string, password : string ) : Promise<PortfolioLib.AuthStatusModel> {
        let loginModel = new PortfolioLib.LoginModel(username, password);
        //console.log(loginModel);

        let route = Utils.getApiRoute(PortfolioLib.SharedRoutes.Server.Auth.Login.Path);
        return Utils.ajaxPost(route, loginModel)
            .catch((err) => {
                Utils.logAndRethrow(err, "Error Logging in");
            });
    }

    logout() : Promise<boolean> {
        return Utils.ajaxGet(Utils.getApiRoute(AppConstants.ApiRoutes.Auth.logout))
            .catch(err => {
                Utils.logAndRethrow(err, "Error process logout");
            });
    }


    getAuthStatus() : Promise<PortfolioLib.AuthStatusModel> {
        let apiRoute = Utils.getApiRoute(AppConstants.ApiRoutes.Auth.authStatus)
        return Utils.ajaxGet(apiRoute)
            .then((res) => {
                let authStatus = PortfolioLib.AuthStatusModel.Empty;
                Object.assign(authStatus, res.data);
                return authStatus;
            })
            .catch(err => {
                Utils.logAndRethrow(err, "Error fetching auth status");
            });
    }
}

export default AuthenticationService
