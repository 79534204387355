// @flow
import React, {Component} from 'react';
import './BlogHome.css';
import ReactRouter, {Link} from 'react-router-dom';
import PortfolioLib from 'goyette-portfolio-lib';
import BlogService from '../../Services/BlogService';
import AuthStatusProviderService from '../../Services/AuthStatusProviderService';
import WaitOverlay from '../../Components/WaitOverlay/WaitOverlay';
import BlogPostPreview from '../../Components/BlogPostPreview/BlogPostPreview';
import Lodash from 'lodash';
import {GoSearch} from 'react-icons/go'
import {GoPlus} from 'react-icons/go'
import {GoGear} from 'react-icons/go'
import {GoRss} from 'react-icons/go'
import {FaHashtag} from 'react-icons/fa'
import ClassNames from 'classnames';
import Utils from '../../utils';
import {Helmet} from "react-helmet";
import utils from "../../utils";
import AuthenticationService from "../../Services/AuthenticationService";

let blogService = new BlogService();
let authenticationService = new AuthenticationService();

class BlogHome extends Component {
    props: {
        match: ReactRouter.Match,
        history: ReactRouter.history
    };

    searchInput: HTMLInputElement;


    static defaultProps = {
        match: null
    };

    state: {
        postPreviews: Array<PortfolioLib.BlogPostPreviewModel>,
        isLoadingPosts: boolean,
        isLoadingMorePosts: boolean,
        morePostsExist: boolean,
        currentPageIndex: number,
        searchInputIsOpen: boolean,
        tagSelectIsOpen: boolean,
        searchValue: string,
        searchPending: boolean,
        tagValue: string,
        tagOptions: Array<string>,
        authStatus: PortfolioLib.AuthStatusModel,
        authStatusProviderSubscriptionKey: string
    };


    constructor(props: any) {
        super(props);

        let openTagSelect = this.props.match
            && this.props.match.params
            && this.props.match.params.searchTag
            && this.props.match.params.searchTag !== "";


        this.state = {
            postPreviews: [],
            isLoadingPosts: false,
            isLoadingMorePosts: false,
            morePostsExist: false,
            currentPageIndex: 0,
            searchInputIsOpen: false,
            tagSelectIsOpen: openTagSelect,
            searchValue: "",
            searchPending: false,
            tagValue: this.props.match ? this.props.match.params.searchTag : "",
            tagOptions: [],
            authStatus: AuthStatusProviderService.instance.authStatusModel,
            authStatusProviderSubscriptionKey: ""
        };
    }


   // updateAuthStatus(newAuthStatus: PortfolioLib.AuthStatusModel) {
   //     this.setState({authStatus: newAuthStatus});
   // }



    componentDidMount() {
        this.updateAuthStatus();

       // this.setState({authStatusProviderSubscriptionKey: AuthStatusProviderService.instance.subscribe((newAuthStatus) => this.updateAuthStatus(newAuthStatus))});

        window.addEventListener('scroll',  Lodash.throttle((e) => this.handleScroll(e), 200));

        blogService.fetchTags()
            .then((tags) => {
                tags.sort();
                tags.splice(0, 0, "");
                this.setState({tagOptions: tags});


                this.fetchPosts(false);
            });


    }

    updateAuthStatus() {
        authenticationService.getAuthStatus()
            .then((authStatus) => {
                this.setState({authStatus: authStatus || PortfolioLib.AuthStatusModel.Empty});
                AuthStatusProviderService.instance.notify(authStatus);
            })
            .catch((err) => {
                // TODO
                utils.notifyException(err, "Error getting authentication status");
            });
    }

    componentWillUnmount() {
        AuthStatusProviderService.instance.unsubscribe(this.state.authStatusProviderSubscriptionKey);
        window.removeEventListener('scroll', Lodash.throttle((e) => this.handleScroll(e), 200));
    }

    handleScroll(event: any) {
        let windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
        let body = document.body;
        let html = document.documentElement;
        let docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight,  html.scrollHeight, html.offsetHeight);
        let windowBottom = windowHeight + window.pageYOffset;

        // Load more posts when we're within a certain distance from the bottom of the page.
        if (windowBottom + 200 >= docHeight) {
            if (!this.state.isLoadingMorePosts && this.state.morePostsExist) {
                this.fetchMorePosts();
            }
        }
    }

    componentWillReceiveProps(nextProps: any) {
        this.setState({
            tagValue: nextProps.match ? nextProps.match.params.searchTag : "",
            currentPageIndex: 0
        }, () => {
            let openTagSelect = this.props.match
                && this.props.match.params
                && this.props.match.params.searchTag
                && this.props.match.params.searchTag !== "";
            this.setState({tagSelectIsOpen: openTagSelect});
            this.refreshSearch();
        });
    }


    fetchPosts(isAdd: boolean) {
        let recordsPerPage = 5;
        let pageIndex = this.state.currentPageIndex;
        let sortBy = "createDate";
        let sortAsc = false;
        let filter = this.state.searchValue.trim();
        let tag = this.state.tagValue;
        let requestModel = new PortfolioLib.BlogPostPreviewRequestModel(recordsPerPage,
            pageIndex,
            sortBy,
            sortAsc,
            filter,
            tag);


        if (isAdd) {
            this.setState({isLoadingMorePosts: true});
        } else {
            this.setState({isLoadingPosts: true});
        }


        let resetState = () => {
            this.setState({isLoadingPosts: false, isLoadingMorePosts: false});
        };

        let searchAgainIfNecessary = () => {
            // If another search request was queued while we were fetching the last batch,
            // perform another search.
            if (this.state.searchPending) {
                this.setState({searchPending: false}, () => this.fetchPosts(false));
            }
        };


        blogService.fetchPostPreviews(requestModel)
            .then((dataPage) => {
                resetState();
                let morePostsExist = this.state.currentPageIndex < dataPage.totalPages - 1;

                this.setState((prevState, props) => ({
                    postPreviews: isAdd ? prevState.postPreviews.concat(dataPage.records) : dataPage.records,
                    morePostsExist: morePostsExist
                }));

                searchAgainIfNecessary();

            })
            .catch((err) => {
                resetState();
                Utils.notifyException(err, "Error getting Posts content");
                searchAgainIfNecessary();
            });
    }

    fetchMorePosts() {
        this.setState((prevState, props) => ({
            currentPageIndex: prevState.currentPageIndex + 1
        }), () => this.fetchPosts(true));
    }

    toggleSearchInput() {
        if (this.state.searchInputIsOpen) {
            let action = this.state.searchValue === "" ? () => {
            } : () => this.refreshSearch();
            this.setState({searchValue: "", searchInputIsOpen: false}, action);
        } else {
            this.setState({searchInputIsOpen: true});
            this.searchInput.focus();
        }
    }


    toggleTagSelect() {
        if (this.state.tagSelectIsOpen) {
            let action = this.state.tagValue === "" ? () => {
            } : () => this.refreshSearch();
            this.setState({tagValue: "", tagSelectIsOpen: false}, action);
        } else {
            this.setState({tagSelectIsOpen: true});
        }
    }

    handleSearchValueChange(event: Event) {
        if (event.target instanceof HTMLInputElement) {
            this.setState({searchValue: event.target.value, currentPageIndex: 0}, () => this.refreshSearch());
        }
    }

    handleTagValueChange(event: Event) {
        if (event.target instanceof HTMLSelectElement) {
            this.setState({tagValue: event.target.value, currentPageIndex: 0}, () => this.refreshSearch());
        }
    }

    refreshSearch() {
        // If a search is in progress, don't search again, but queue up a request to search
        // when the current request has completed.
        if (this.state.isLoadingPosts || this.state.isLoadingMorePosts) {
            this.setState({searchPending: true});
        } else {
            this.fetchPosts(false);
        }
    }

    addNewPost() {
        blogService.getNewPostId()
            .then((newPostId) => {
                let route = PortfolioLib.SharedUtils.replaceRouteParam(PortfolioLib.SharedRoutes.UI.Blog.EditPost.Path,
                    PortfolioLib.SharedRoutes.UI.Blog.EditPost.PostIdParam, newPostId);
                this.props.history.push(route);

            })
            .catch((err) => {
                Utils.notifyException(err, "Error getting new post id");
            });
    }

    render() {

        let searchInputClassNames = ClassNames('BlogHome-search-input', this.state.searchInputIsOpen ? 'open' : '');
        let searchInputButtonClassNames = ClassNames('BlogHome-search-filter-toggle-button search icon-button-icon ',
            this.state.searchInputIsOpen ? 'open' : '', this.state.searchValue.length > 0 ? 'has-input' : '');

        let tagDropDownClassNames = ClassNames('BlogHome-tag-select', this.state.tagSelectIsOpen ? 'open' : '');
        let tagDropDownButtonClassNames = ClassNames('BlogHome-search-filter-toggle-button tag icon-button-icon ',
            this.state.tagSelectIsOpen ? 'open' : '', this.state.searchValue.length > 0 ? 'has-input' : '');

        let tagSelect = <select
            className={tagDropDownClassNames}
            value={this.state.tagValue}
            onChange={(e) => this.handleTagValueChange(e)}>
            {this.state.tagOptions.map((t, i) => {
                return <option key={i} value={t}>
                    {t === "" ? "All Tags" : t}
                </option>
            })}
        </select>;
        let tagButton = <FaHashtag className={tagDropDownButtonClassNames}
                                   onClick={(e) => this.toggleTagSelect()}/>;


        let searchInput = <input
            placeholder="Search..."
            type="text" className={searchInputClassNames}
            ref={(input) => {
                this.searchInput = input;
            }}
            value={this.state.searchValue} onChange={(e) => this.handleSearchValueChange(e)}/>;
        let searchInputButton = <GoSearch className={searchInputButtonClassNames}
                                          onClick={(e) => this.toggleSearchInput()}/>;

        let addPostButton = <button className="icon-button" onClick={(e) => this.addNewPost()}>
            <GoPlus className="BlogHome-add-post-button icon-button-icon"/>
        </button>;

        let rssFeedButton = <button className="icon-button ">
            <a rel="alternate noopener noreferrer" type="application/rss+xml" target="_blank" href={Utils.getRssFeedUrl()}><GoRss className=" icon-button-icon BlogHome-rss-button"/></a>
        </button>;


        let adminButton = <Link to={PortfolioLib.SharedRoutes.UI.Blog.Admin.Path}>
            <GoGear className="BlogHome-admin-button icon-button-icon"/>
        </Link>;

        let allPostsLink = PortfolioLib.SharedUtils.replaceRouteParam(PortfolioLib.SharedRoutes.UI.Blog.AllPosts.Path,
            PortfolioLib.SharedRoutes.UI.Blog.AllPosts.PageNumberParam, 1);

        return (

            <div style={{position: "relative"}}>
                <Helmet>
                    <link rel="alternate" type="application/rss+xml" title="RSS"
                          href={Utils.getRssFeedUrl()}/>
                </Helmet>

                <div className="BlogHome-filter-toolbar-desktop">
                    {tagSelect}
                    {tagButton}


                    {searchInput}
                    {searchInputButton}
                    {rssFeedButton}

                    {this.state.authStatus && this.state.authStatus.isAdmin && addPostButton}
                    {this.state.authStatus && this.state.authStatus.isAdmin && adminButton}
                </div>

                <div className="BlogHome-filter-toolbar-mobile">
                    <div>
                        <div style={{display: "inline-block"}}>
                            <div>
                                {tagSelect}
                                {tagButton}

                            </div>

                            <div style={{marginTop: "10px"}}>
                                {searchInput}
                                {searchInputButton}
                            </div>

                            <div style={{marginTop: "10px"}}>
                                {rssFeedButton}
                            </div>

                        </div>
                        {this.state.authStatus && this.state.authStatus.isAdmin &&
                        <div style={{display: "inline-block"}}>

                            <div>
                                {addPostButton}
                            </div>

                            <div style={{marginTop: "10px"}}>
                                {adminButton}
                            </div>


                        </div>
                        }
                    </div>


                </div>


                <h2 className="BlogHome-page-title">Blog</h2>


                <div className="clear-both">

                    {!this.state.isLoadingPosts && this.state.postPreviews.length === 0 &&
                    <p>No records matched the search criteria</p>
                    }


                    {this.state.postPreviews && this.state.postPreviews.map((postPreview, i) => (
                        <BlogPostPreview key={i} postPreviewModel={postPreview} authStatus={this.state.authStatus}
                                         onDelete={() => this.refreshSearch()}/>
                    ))}

                    {!this.state.isLoadingMorePosts && this.state.morePostsExist &&
                    <button className="BlogHome-more-button standard-button" onClick={(e) => this.fetchMorePosts()}>
                        Load More
                        Posts
                    </button>
                    }

                </div>


                {/* There are two loading widgets. One is to cover the whole screen when fully refreshing the list. The other is to show the widget at the bottom of the screen while we fetch more posts. */}
                {this.state.isLoadingPosts &&
                <WaitOverlay showOverlay={true}/>
                }


                {this.state.isLoadingMorePosts &&
                <div className="BlogHome-wait-overlap-wrap">
                    <WaitOverlay showOverlay={false}/>
                </div>
                }


                <div className="BlogHome-all-posts-link-wrap">
                    <Link
                        to={allPostsLink}>All
                        Posts</Link>
                </div>
            </div>
        );
    }
}


export default BlogHome;
