// @flow
import React, {Component} from 'react'
import './AccordionControl.css'
import classNames from 'classnames';
import {FaPlusCircle} from 'react-icons/fa'
import {FaMinusCircle} from 'react-icons/fa'
import {FaLink} from 'react-icons/fa'


class AccordionControl extends Component {

    props: {
        questionId: string,
        highlightedQuestionId: string,
        question: any,
        children: any
    };

    static defaultProps = {
        onClose: () => { }
    };

    state: {
        isExpanded: boolean
    };

    constructor(props: any) {
        super(props);


        this.state = {
            isExpanded: "#" + this.props.questionId === this.props.highlightedQuestionId
        };
    }

    render() {
        let childPanelClassnames = classNames('AccordionControl-child-panel', this.state.isExpanded ? 'expanded' : 'collapsed');
        let plusMinusIcon = this.state.isExpanded ? <FaMinusCircle /> : <FaPlusCircle/>;
        let permalink = "#" + this.props.questionId;

        return (
            <div>
                <a id={this.props.questionId}> </a>
                    <div className="AccordionControl-main-panel" onClick={() => this.handleClick()}>
                        <div style={{"marginRight": "10px"}}>{plusMinusIcon}</div> {this.props.question}
                    </div>
                    <div className={childPanelClassnames}>
                        {/*<hr className="AccordionControl-answer-line"/>*/}
                        {this.props.children}
                        <a className="AccordionControl-permalink" title="Permalink" href={permalink}><FaLink/></a>
                        <div className="AccordionControl-child-panel-buffer"/>
                    </div>
            </div>
        );
    }

    handleClick() {
        console.log("Clicked");

        this.setState(prevState => ({
            isExpanded: !prevState.isExpanded
        }));
    }
}


export default AccordionControl;