// @flow
import React, {Component} from 'react';
import './ImageViewDialog.css';
import classNames from 'classnames';
import KeyHandler, {KEYDOWN} from 'react-key-handler';
import FadeBackground from '../FadeBackground/FadeBackground';
import {TiChevronRight} from 'react-icons/ti'
import {TiChevronLeft} from 'react-icons/ti'
import {TiTimes} from 'react-icons/ti'
import ReactDocMeta from 'react-document-meta';


class ImagePreviewData {
    imagePath: string;
    caption: string;
}

class ImageViewDialog extends Component {
    props: {
        imagePath: string,
        caption: string,
        onClose: () => void
    };


    static defaultProps = {
        caption: "",
        onClose: () => {
        }
    };


    componentDidMount() {
        // Create a delay so CSS will animate
        requestAnimationFrame(() => this.setState({open: true}));
    };

    state: {
        open: boolean,
        isClosing: boolean,
        imagePath: string,
        caption: string
    };

    constructor(props: any) {
        super(props);
        this.state = {
            open: false,
            isClosing: false,
            imagePath: this.props.imagePath,
            caption: this.props.caption
        };
    }

    render() {

        let containerClasses = classNames('ImageViewDialog-container',
            this.state.open ? 'open' : '',
            this.state.isClosing ? 'closing' : ''
        );

        let imageClasses = classNames('ImageViewDialog-image',
         this.state.open ? 'open' : '',
            this.state.isClosing ? 'closing' : ''
        );

        let captionClasses = classNames('ImageViewDialog-caption',
             this.state.open ? 'open' : '',
             this.state.isClosing ? 'closing' : ''
        );

        let metaTags = [
            {name: "prerender-status-code", content: 304},
        ];

        return (
            <ReactDocMeta tags={metaTags}>
                <div className="ImageViewDialog-outer-container">
                    <KeyHandler keyEventName={KEYDOWN} keyCode={27} onKeyHandle={() => this.closeImage()} />
                    <KeyHandler keyEventName={KEYDOWN} keyCode={37} onKeyHandle={() => this.openPreviousImagePreview(null)} />
                    <KeyHandler keyEventName={KEYDOWN} keyCode={38} onKeyHandle={() => this.openPreviousImagePreview(null)} />
                    <KeyHandler keyEventName={KEYDOWN} keyCode={39} onKeyHandle={() => this.openNextImagePreview(null)} />
                    <KeyHandler keyEventName={KEYDOWN} keyCode={40} onKeyHandle={() => this.openNextImagePreview(null)} />

                    <FadeBackground isClosing={this.state.isClosing} onClick={() => this.closeImage()}/>



                    <div className={containerClasses} onClick={(e) => this.closeImage()}>
                        <button className="ImageViewDialog-full-view-nav-button close icon-button " onClick={(e) => this.closeImage()}>
                            <TiTimes />
                        </button>


                        <div className="ImageViewDialog-content-wrap" >

                            <img src={this.state.imagePath} className={imageClasses} alt={this.state.caption}
                                 onClick={(e) => this.state.open && this.closeImage()}/>

                            {/* Neutered onClick for this so allow selection/copy */}
                            <p className={captionClasses} onClick={(e) => {e.stopPropagation();}}>{this.state.caption}</p>
                            <div className="clear-both" />


                        </div>

                        <div>
                            <button className="ImageViewDialog-full-view-nav-button previous icon-button " onClick={(e) => this.openPreviousImagePreview(e)}>
                                <TiChevronLeft />
                            </button>

                            <button className="ImageViewDialog-full-view-nav-button next icon-button " onClick={(e) => this.openNextImagePreview(e)}>
                                <TiChevronRight />
                            </button>
                        </div>
                    </div>


                </div>
            </ReactDocMeta>

        );
    }


    closeImage() {
        if (!this.state.isClosing) {
            this.setState({isClosing: true});

            setTimeout(() => {
                this.props.onClose();
            }, 500);
        }
    }

    openPreviousImagePreview(e: ?Event) {
        if (e != null) {
            e.preventDefault();
            e.stopPropagation();
        }
        this.setImagePreview(true);
    }

    openNextImagePreview(e: ?Event) {
        if (e != null) {
            e.preventDefault();
            e.stopPropagation();
        }

        this.setImagePreview(false);
    }

    setImagePreview(previous: bool) {

        let allPreviews = this.getImagePreviewsData();

        if (allPreviews.length === 0 || allPreviews.length === 1) {
            // There are no other previews. Return.
            return;
        }

        let currentIndex = -1;
        for (let i = 0; i < allPreviews.length; i++) {
            if (allPreviews[i].imagePath === this.state.imagePath) {
                currentIndex = i;
                break;
            }
        }

        if (currentIndex === -1) {
            console.error("Unexpectedly could not find current image path index for path: " + this.state.imagePath);
            return;
        }

        let nextIndex = currentIndex;
        if (previous === true) {
            nextIndex--;
        } else {
            nextIndex++;
        }

        if (nextIndex === -1) {
            // Wrapped around. Go to the end of the list.
            nextIndex = allPreviews.length - 1;
        }
        if (nextIndex === allPreviews.length) {
            // Wrapped around. Go to the beginning of the list.
            nextIndex = 0;
        }

        this.setState({imagePath: "", caption:  ""}, () => {
            this.setState({imagePath: allPreviews[nextIndex].imagePath, caption:  allPreviews[nextIndex].caption});
        });
    }

    getImagePreviewsData() : ImagePreviewData[] {
        let retval = [];

        let images = document.getElementsByClassName("ImagePreview-thumbnail-image");
        for (let i = 0; i < images.length; i++) {
            let image = images[i];
            let dataObj = new ImagePreviewData();
            dataObj.imagePath = image.dataset.fullimagepath;
            dataObj.caption = image.dataset.imagecaption;
            retval.push(dataObj);
        }

        return retval;
    }
}

export default ImageViewDialog;