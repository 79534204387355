// @flow
import React, {Component} from 'react';
import './PageLayout.css';
import Utils from '../../utils';
import AppConstants from '../../appConstants';

type Props = {
    title: string,
    backgroundImage: string,
    grayscale: number,
    opacity: number,
    caption: string,
    showBannerImage: boolean,
    children: any
};
class PageLayout extends Component<Props> {

    props: {
        title: string,
        backgroundImage: string,
        grayscale: number,
        opacity: number,
        caption: string,
        showBannerImage: boolean,
        children: any
    };

    static defaultProps = {
        title: "",
        backgroundImage: "",
        showBannerImage: true,
        grayscale: 0,
        opacity: .5,
        caption: "",
    };

    render() {

        // This is to support property-based background images.
        let imgUrl = this.props.backgroundImage;

        let backgroundImageStyle = {
            backgroundImage: 'url(' + imgUrl + ')',
            filter: 'grayscale(' + this.props.grayscale + ')'
        };

        let darkenOverlayStyle = {
            opacity: this.props.opacity
        };



        

        return (
                <div className="PageLayout-main-content-panel">
                    {this.props.showBannerImage &&
                    <div>
                        <div className="PageLayout-background-wrap" style={backgroundImageStyle}>
                            <div className="PageLayout-darken-overlay" style={darkenOverlayStyle}/>

                            {this.props.caption != null && this.props.caption !== "" &&
                            <div className="PageLayout-caption-container">
                                <div className="PageLayout-caption">
                                    <span>{this.props.caption}</span>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                    }

                    <div className="PageLayout-centered-content-wrap">
                        <div className="PageLayout-centered-content">
                            {this.props.children}
                        </div>
                    </div>

                    <div className="PageLayout-fade-overlay"/>
                </div>
        );
    }


    componentDidMount() {
        // Scroll to top
        window.scrollTo(0, 0)

        document.title = this.props.title
                ? this.props.title
                : (Utils.getApplication() === AppConstants.Applications.DanGoyette ? "Dan Goyette - " : "Gravia - ") + this.props.caption;
    }

}


export default PageLayout;