// @flow
import React, {Component} from 'react';
import './EarlyAccess.css';
import PageLayout from '../../../Components/PageLayout/PageLayout'
import Utils from '../../../utils';
import {default as bgImage} from './Images/Anomaly_Close.jpg';
import ReactDocMeta from 'react-document-meta';


class EarlyAccess extends Component {

    state: {
    };


    constructor(props: any) {
        super(props);


        this.state = {
        };
    }



    render() {
        let canonicalUrl = "https://www.graviagame.com/EarlyAccess";
        let metaDescription= "Gravia is in Early Access on Steam";
        let metaTags = [
            {name: "description", content: metaDescription},
            {property: "og:title", content: "Gravia - Early Access"},
            {property: "og:type", content: "website"},
            {property: "og:description", content: metaDescription},
            {property: "og:image", content: "https://www.graviagame.com" + bgImage},
            {property: "og:url", content: canonicalUrl},
            {property: "fb:app_id", content: Utils.getFacebookAppId()}
        ];


        return (
            <PageLayout caption="Early Access" title="Early Access" backgroundImage={bgImage} opacity={0.1} grayscale={0.0}>
                <ReactDocMeta tags={metaTags}>
                    <div>
                        <p>
                            After about three years of full-time development, Gravia officially entered Early Access on March 15, 2021. Although Gravia is not yet a complete product, I believe that it has reached a point in its development where it is an enjoyable gameplay experience.
                        </p>

                        <p>
                            This page lists the current and planned features, some caveats of buying an Early Access game.
                        </p>

                        <div className="spacer-row"/>
                        <h3>Why Early Access?</h3>

                        <p>So if Gravia isn't finished, why have I decided to release it into Early Access? The main
                            reason is because community feedback over the course of the "Alpha" testing period has been
                            incredibly valuable, and I'd like to broaden that experience. Members of the community have
                            made some great recommendations, and getting direct feedback on what players do and don't
                            like has definitely made Gravia a more enjoyable experience. I'm motivated to make Gravia
                            the best game I can, and the more feedback I get the more I can ensure that the content I
                            add is what people are looking for.
                        </p>

                        <h3>Is Early Access right for you?</h3>
                        <p>
                            Gravia is pretty stable right now, and the "core" experience provides about 5 hours of
                            gameplay. The optional secrets provide around another 10-15 hours of gameplay on average,
                            and the Time Trial mode challenges you to complete individual levels as quickly as possible,
                            to see if you can finish under the goal times. So I think it's a fairly substantial
                            experience already. However, there are some caveats to playing the game in its current state
                            compared to waiting until it's fully released:
                        </p>
                        <ul>
                            <li>Some parts of the game aren't included yet. Most importantly, the story isn't
                                significantly included in the game yet. If game story is extremely important to you, you
                                might want to wait until the full release, which will include it.
                            </li>
                            <li>The game will change, perhaps significantly, as it continues to be developed. The main reason to release to Early Access
                                is to gather more feedback from players, and use that feedback to improve the game. But
                                it's entirely possible that changes will be made that you don't like. Hopefully that
                                doesn't happen much, but Gravia is still evolving.
                            </li>
                            <li>There will be some bugs. Again, I feel that Gravia runs quite well at this point, but
                                I'm sure that new bugs and problems will be uncovered over the course of the Early
                                Access, which I'll work to fix as quickly as possible.
                            </li>
                        </ul>


                        <h3>What if you don't like the game?</h3>
                        <p>
                            If you don't enjoy playing Gravia, I really do want to know why. Feedback from players is essential to me in order to improve or fix the game. The worst thing for me would be if someone didn't enjoy the game, left a bad review, and never let me know what they didn't like about the Gravia.
                        </p>
                        <p>
                            I realize that Gravia isn't a game that everyone will enjoy. But if you had the impression that it <em>would</em> be fun, but it wasn't, I hope you'll let me know why.
                        </p>
                        <p>
                            Again, in short: Feedback matters, and negative feedback is usually the most helpful. So, if there's something you don't like, or something you think is missing, please considering letting me know. You can email me, or join the Discord server, depending on the level of involvement you're looking for from the Gravia community.
                        </p>


                        <h3>How much of the game is done?</h3>
                        <p>
                            In terms of the number of levels, there are currently about 55 levels in the game, with the
                            goal of about 80 levels for the full release. So, the game currently contains about 2/3rds of the
                            intended levels.
                        </p>

                        <h3>What isn't finished yet?</h3>
                        <p>
                            These are the main features remaining to be added to the game:
                        </p>

                        <ul>
                            <li><b>Additional Levels - </b> Again, about 55 of the planned 80 levels are included. The
                                other 25 or so levels remain to be added.
                            </li>
                            <li><b>Additional Secrets - </b> Along with new levels will come new extra-hard "Secrets". I
                                don't have a final goal number in mind for the total number of secrets, but I expect
                                that most levels will continue to have secrets.
                            </li>
                            <li><b>Story - </b> Gravia has a story planned, which is mostly not included in the game
                                right now. There are some hints of what's going on, but the full story will be added
                                closer to the full release.
                            </li>
                            <li><b>Other 'Game Modes' - </b> In addition to the existing Time Trial mode, I'd like to
                                explore other game modes to add replay value to existing levels.
                            </li>
                            <li><b>Voice Acting - </b> Currently, the small amount of dialog in the game is generated
                                via computer text-to-speech, and is temporary. Character dialogue will be recorded by
                                actual voice actors for the full release.
                            </li>
                            <li><b>"Secret" rewards - </b> All of the secrets in the game currently only have
                                "placeholder" rewards. I want to add some additional incentives for obtaining secrets,
                                and a holistic reward for getting all of the game's secrets.
                            </li>
                            <li><b>Steam Achievements - </b> I'll include a bunch of achievements on Steam.</li>
                            <li><b>Completed Soundtrack - </b> Gravia has an original soundtrack, which is currently
                                only partially complete. The soundtrack will be completed by the final release.
                            </li>
                            <li><b>General Polish and Cleanup - </b> Levels are always getting
                                fine-tuned/modified/improved, and I don't expect this will change during Early Access.
                                If anything, I expect a surge of initial changes related to new player feedback.
                            </li>
                        </ul>

                        <h3>How long will Early Access last?</h3>
                        <p>
                            My estimate is that about a year of work remains before I will feel Gravia will be
                            "complete". But that's a pretty flexible target right now.
                        </p>

                        <h3>What are the chances Gravia will actually be completed?</h3>
                        <p>
                            I know that "Early Access" games can give people concern. Some "Early Access" turn into
                            "Only Access", as the developers either run out of funding or lose interest. Although I
                            obviously can't be certain that some disaster won't occur that prevents me from finishing
                            the game, I believe that only a disaster actually would keep Gravia from being completed.
                        </p>
                        <p>
                            I've been working on Gravia, in some capacity, for about five years now. My excitement and
                            motivation has only increased over time, as I've seen Gravia go from a small technical demo
                            to an experience that people really seem to enjoy. So my interest in the project is stronger
                            than ever. I've also been fortunate that funding hasn't been a problem, and I don't foresee
                            any funding issues in the future, even if Gravia doesn't sell a single copy.
                        </p>
                        <p>
                            In short, Gravia is my "dream project". I'm not making Gravia because I think it will be
                            financially successful, and whether Gravia makes any money isn't part of the equation for
                            completing the game. I'll stop working on Gravia when I think it's about as good as I can
                            make it.
                        </p>

                        <h3>Are there any other feature planned?</h3>
                        <p>
                            I have a list of things I think would be cool to add, but I'm going to hold off and see
                            what the community wants before committing to anything else. However, one item near the top
                            of my list is to add a global Leaderboard for the Time Trial game mode, so players can
                            compete against other players to see who's fastest. But that will depend on Time Trial being
                            a mode that people enjoy.
                        </p>


                    </div>
                </ReactDocMeta>
            </PageLayout>
        );
    }
}


export default EarlyAccess;