// @flow
import React, {Component} from 'react';
import './BlogEditPost.css';
import ReactRouter, {Link} from 'react-router-dom';
import {Controlled as CodeMirror} from 'react-codemirror2'
import PortfolioLib from 'goyette-portfolio-lib';
import {FaSync} from 'react-icons/fa'
import {GoPlus} from 'react-icons/go'
import {GoX} from 'react-icons/go'
import {GoSync} from 'react-icons/go'
import Utils from '../../utils';
import BlogService from '../../Services/BlogService';
import AuthStatusProviderService from '../../Services/AuthStatusProviderService';
import WaitOverlay from '../../Components/WaitOverlay/WaitOverlay';
import BlogPostPreview from '../../Components/BlogPostPreview/BlogPostPreview';
import ImagePreview from '../../Components/ImagePreview/ImagePreview';
import ExternalLink from '../../Components/ExternalLink/ExternalLink';
import ModalDialog from '../../Components/ModalDialog/ModalDialog'
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import {FaBold} from 'react-icons/fa';
import {FaItalic} from 'react-icons/fa'
import {FaParagraph} from 'react-icons/fa'
import {FaHeading} from 'react-icons/fa'
import {FaListUl} from 'react-icons/fa'
import {FaList} from 'react-icons/fa'
import {FaRegFileImage} from 'react-icons/fa'
import {FaTh} from 'react-icons/fa'
import {GoLink} from 'react-icons/go'
import {FaExternalLinkAlt} from 'react-icons/fa'
import {FaYoutube} from 'react-icons/fa'
import Lodash from 'lodash';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/xml/xml';
import 'codemirror/mode/css/css';
import AuthenticationService from "../../Services/AuthenticationService";
import utils from "../../utils";

let blogService = new BlogService();
let authenticationService = new AuthenticationService();

class BlogEditPost extends Component {
    props: {
        match: ReactRouter.Match,
        history: ReactRouter.history
    };

    fileInput: HTMLInputElement;
    codeMirrorEditor: any;
    currentlyHandlingCodeMirrorKeyEvent: boolean;


    static defaultProps = {};

    state: {
        postId: number,

        isInitialized: boolean,
        authStatus: PortfolioLib.AuthStatusModel,
        authStatusProviderSubscriptionKey: string,

        postGuid: string,

        titleValue: string,
        excerptValue: string,
        copySourceValue: string,
        htmlValue: string,
        cssValue: string,
        longIdValue: string,
        isPublished: boolean,
        tags: Array<string>,
        tagOptions: Array<string>,
        bannerImageUrlValue: string,
        bannerImageThumbnailUrlValue: string,
        dateString: string,

        images: Array<PortfolioLib.BlogImageModel>,

        isUploadingImages: boolean,

        outputPreview: any,
        refreshPreviewTimer: any,

        postPreviewModel: PortfolioLib.BlogPostPreviewModel,
        previewPostUrl: string,

        addImageEditorOpen: boolean,
        newImageCaption: string,

        draftsDialogIsOpen: boolean,
        drafts: Array<PortfolioLib.BlogDraftPostSummaryModel>,
        draftAutoSaveTimer: any,
        lastSavedDraft: ?PortfolioLib.BlogEditPostModel,

        codeMirrorEditor: any


    };

    isDoingInit: boolean;
    shouldReinit: boolean;

    constructor(props: $PropertyType<BlogEditPost, 'props'>) {
        super(props);
        this.isDoingInit = false;
        this.shouldReinit = false;


        this.state = {
            postId: parseInt(this.props.match.params.postId, 10),

            postGuid: "",

            isInitialized: false,
            titleValue: "",
            longIdValue: "",
            excerptValue: "",
            copySourceValue: "",
            htmlValue: "",
            cssValue: "",
            isPublished: false,
            tags: [],
            tagOptions: [],
            bannerImageUrlValue: "",
            bannerImageThumbnailUrlValue: "",
            dateString: PortfolioLib.SharedUtils.dateToShortDateString(new Date()) || "",

            images: [],

            isUploadingImages: false,

            outputPreview: null,
            refreshPreviewTimer: null,

            postPreviewModel: PortfolioLib.BlogPostPreviewModel.Empty,
            authStatus: AuthStatusProviderService.instance.authStatusModel,
            authStatusProviderSubscriptionKey: "",
            previewPostUrl: "",

            addImageEditorOpen: false,
            newImageCaption: "",

            draftsDialogIsOpen: false,
            drafts: [],
            draftAutoSaveTimer: null,
            lastSavedDraft: null,
            codeMirrorEditor: null
        };
    }

    //updateAuthStatus(newAuthStatus: PortfolioLib.AuthStatusModel) {
    //    let action = this.state.authStatus.isAdmin === newAuthStatus.isAdmin
    //        ? () => {} : () => this.doInitialization();
    //    this.setState({authStatus: newAuthStatus}, action);
    //}

    updateAuthStatus() {
        authenticationService.getAuthStatus()
            .then((authStatus) => {
                this.setState({authStatus: authStatus || PortfolioLib.AuthStatusModel.Empty});
                AuthStatusProviderService.instance.notify(authStatus);
            })
            .catch((err) => {
                // TODO
                utils.notifyException(err, "Error getting authentication status");
            });
    }

    doInitialization() {
        if (this.isDoingInit) {
            console.log("Already initializing. Queued for reinit.");
            this.shouldReinit = true;
            return;
        }

        if (this.state.authStatus.isAdmin) {
            this.setState({isInitialized: false});

            // Continue loading.
            Promise.all([
                this.fetchAllTags(),
                this.fetchPost(),
                this.fetchImages()]
            ).then(() => {

                this.setState({isInitialized: true});

                // Set the current edit model as the draft, to avoid saving a draft
                // until there are changes.
                this.setState({lastSavedDraft: this.getCurrentEditModel()});

                this.isDoingInit = false;
                if (this.shouldReinit) {
                    this.shouldReinit = false;
                    this.doInitialization();
                }
            })
                .catch((err) => {
                    Utils.notifyException(err, "Error Initializing Blog Edit");
                    this.isDoingInit = false;
                    if (this.shouldReinit) {
                        this.shouldReinit = false;
                        this.doInitialization();
                    }
                });
        } else {
            // Initialization is done, but will result in no access granted.
            this.setState({isInitialized: true});
        }

    }

    componentWillUnmount() {
        if (this.state.refreshPreviewTimer) {
            clearTimeout(this.state.refreshPreviewTimer);
            this.setState({refreshPreviewTimer: null});
        }

        if (this.state.draftAutoSaveTimer) {
            clearInterval(this.state.draftAutoSaveTimer);
            this.setState({draftAutoSaveTimer: null});
        }

        AuthStatusProviderService.instance.unsubscribe(this.state.authStatusProviderSubscriptionKey);
    }

    componentDidMount() {
        this.updateAuthStatus();


       // this.setState({authStatusProviderSubscriptionKey: AuthStatusProviderService.instance.subscribe((newAuthStatus) => this.updateAuthStatus(newAuthStatus))});
        this.setState({draftAutoSaveTimer: setInterval(() => this.saveDraft(), 1000 * 60 * 5)});

        document.title = `Edit Post #${this.state.postId.toString()}`;

        this.doInitialization();
        this.updatePreview();
    }

    saveDraft() {
        let model = this.getCurrentEditModel();

        // Drafts have their own Guid
        model.guid = uuidv4();
        model.isDraft = true;
        model.lastUpdatedDate = new Date();

        let shouldSave = false;
        if (this.state.lastSavedDraft) {
            // Compare current to last, don't save if they're the same. The annoying part is ignoring
            // the lastUpdatedDate

            if (!this.draftIsEquivalent(model, this.state.lastSavedDraft)) {
                shouldSave = true;
            }

        } else {
            shouldSave = true;
        }

        if (shouldSave) {
            blogService.saveDraftPost(model)
                .then((success) => {
                    Utils.notifyMessage('Draft Auto-saved');
                    this.setState({lastSavedDraft: model});
                })
                .catch((err) => {
                    Utils.notifyException(err, "Error saving draft");
                });
        }

    }

    draftIsEquivalent(a: PortfolioLib.BlogEditPostModel, b: PortfolioLib.BlogEditPostModel): boolean {
        // Cherry pick the fields which result in inequivalence.

        if (+a.date !== +b.date
            || a.longId !== b.longId
            || a.title !== b.title
            || a.html !== b.html
            || a.css !== b.css
            || a.summary !== b.summary
            || a.bannerImageUrl !== b.bannerImageUrl
            || a.bannerImageThumbnailUrl !== b.bannerImageThumbnailUrl
            || a.isPublished !== b.isPublished) {
            return false;
        }

        if (a.tags.length !== b.tags.length) {
            return false;
        }

        for (let i = 0; i < a.tags.length; i++) {
            if (a.tags[i] !== b.tags[i]) {
                return false;
            }
        }


        return true;
    }

    getCurrentEditModel(): PortfolioLib.BlogEditPostModel {
        return new PortfolioLib.BlogEditPostModel(this.state.postGuid, this.state.postId,
            this.state.longIdValue, this.state.titleValue, this.state.htmlValue, this.state.cssValue,
            this.state.excerptValue, this.getDate(), this.state.bannerImageUrlValue, this.state.bannerImageThumbnailUrlValue,
            this.state.isPublished, this.state.tags, false, new Date());
    }

    fetchAllTags() {

        return new Promise((res, rej) => {
            blogService.fetchAllTags()
                .then((tags) => {
                    this.setState({tagOptions: tags});
                    res();
                })
                .catch((err) => {
                    Utils.notifyException(err, "Error fetching tags");
                    rej(`Error fetching tags: ${err}`);
                });
        });

    }

    fetchImages() {

        return new Promise((res, rej) => {
            blogService.fetchImages(this.state.postId)
                .then((images) => {
                    this.setState({images: images});
                    res();
                })
                .catch((err) => {
                    Utils.notifyException(err, "Error fetching images");
                    rej(`Error fetching images: ${err}`);
                });
        });

    }


    fetchPost() {
        return new Promise((res, rej) => {
            blogService.fetchEditPostModel(this.state.postId)
                .then((postModel) => {
                    this.updateStateWithEditModel(postModel);
                    res();
                })
                .catch((err) => {
                    Utils.notifyException(err, "Error Initializing posts");
                    rej(`Error fetching post: ${err}`);
                });
        });
    }

    updateStateWithEditModel(postModel: PortfolioLib.BlogEditPostModel) {
        this.setState(
            {
                postGuid: postModel.guid || uuidv4(),
                titleValue: postModel.title || "",
                longIdValue: postModel.longId || "",
                excerptValue: postModel.summary || "",
                htmlValue: postModel.html || "",
                cssValue: postModel.css || "",
                isPublished: postModel.isPublished,
                bannerImageUrlValue: postModel.bannerImageUrl || "",
                bannerImageThumbnailUrlValue: postModel.bannerImageThumbnailUrl || "",
                dateString: PortfolioLib.SharedUtils.dateToShortDateString(postModel.date) || "",
                tags: postModel.tags,

                outputPreview: null,
                refreshPreviewTimer: null,
            },
            () => this.updatePreview()
        );
    }

    updateLongId() {
        this.setState({longIdValue: PortfolioLib.SharedUtils.getPostIdFromTitle(this.state.titleValue)});
    }

    activateFileUpload() {
        this.fileInput.click();
    }

    uploadImages(batch: PortfolioLib.BlogImageBatchUploadModel) {

        // Upload the image to the server.

        this.setState({isUploadingImages: true});

        blogService.uploadImageBatch(batch)
            .then((blogImageModels) => {
                let newImages = this.state.images.slice();
                Lodash.forEach(blogImageModels, (im) => {
                    newImages.push(im);
                });

                this.setState({images: newImages});
                this.setState({isUploadingImages: false});
            })
            .catch((err) => {
                Utils.notifyException(err, "Error uploading image");
                this.setState({isUploadingImages: false});
            });

    }

    deleteImage(imageName: string) {
        if (window.confirm("Really delete this image?")) {
            blogService.deleteImage(this.state.postId, imageName)
                .then((result) => {
                    Utils.notifyMessage(result.message);
                    this.fetchImages();
                })
                .catch((err) => {
                    Utils.notifyException(err, "Error deleting image");
                });
        }
    }

    savePost() {
        let model = this.getCurrentEditModel();

        let validationErrors: Array<string> = [];

        if (this.state.isPublished) {
            // Only enforce validation when publishing.
            if (this.state.titleValue.trim().length === 0) {
                validationErrors.push("Enter a Title")
            }
            if (this.state.longIdValue.trim().length === 0) {
                validationErrors.push("Enter a Long ID")
            }
            if (this.state.excerptValue.trim().length === 0) {
                validationErrors.push("Enter a Summary")
            }
            if (this.state.bannerImageUrlValue.trim().length === 0) {
                validationErrors.push("Enter a Banner Image URL")
            }
            if (this.state.bannerImageThumbnailUrlValue.trim().length === 0) {
                validationErrors.push("Enter a Banner Image Thumbnail URL")
            }
            if (this.state.tags.length === 0) {
                validationErrors.push("Enter at least one Tag")
            }
        }

        if (validationErrors.length > 0) {
            Utils.notifyError(`Fix the following validation errors: ${validationErrors.join('; ')}`);
            return;
        }


        blogService.saveBlogEditModel(model)
            .then((result) => {
                Utils.notifyMessage(result.message);

                this.refreshPrerenderIoAndFacebook()
            })
            .catch((err) => {
                Utils.notifyException(err, "Error saving Edit Model");
            });

    }

    refreshPrerenderIoAndFacebook() {
        if (this.state.isPublished === true) {
            let viewPostUrl = Utils.getCanonicalBlogUrl(this.state.postId, this.state.longIdValue);

            // Only make this work in Prod
            if (!viewPostUrl.includes("localhost:")) {
                axios.post('https://api.prerender.io/recache', {
                    prerenderToken: Utils.getPrerenderIOKey(),
                    url: viewPostUrl
                })
                .then((response) => {

                    // Wait 20 seconds, to give prerender.io a chance to refresh, then trigger a refresh
                    // of the facebook sharing debugger. We won't bother capturing the token and cancelling it
                    // if we navigate away.

                    Utils.notifyMessage("Prerender.io update triggered");

                    setTimeout(() => {
                        this.triggerFacebookSharingDebuggerRefresh(viewPostUrl);
                    }, 1000 * 20);


                })
                .catch(function (error) {
                    Utils.notifyException(error, "Error submitting Recache request to Prerender.io");
                });
            }
        }
    }

    triggerFacebookSharingDebuggerRefresh(pageUrl: string) {
        // Start by getting an access tpoken

        let payload = {
            params: {
                client_id: Utils.getFacebookAppId(),
                client_secret: Utils.getFacebookAppSecret(),
                grant_type: "client_credentials"
            }
        };

        axios.get("https://graph.facebook.com/oauth/access_token", payload )
        .then((response) => {
            let token = response.data.access_token;
            // Now post the refresh request to facebook.
            var postPayload = {
                id: pageUrl,
                scrape: "true",
                access_token: token
            };

            axios.post( "https://graph.facebook.com", postPayload)
            .then((response) => {
                Utils.notifyMessage("Facebook Sharing Debugger update triggered");
            })
            .catch((exception) => {
                Utils.notifyException(exception, "Error submitting rescrape request to Facebook");
            });
        })
        .catch(function (error) {
            Utils.notifyException(error, "Error getting Facebook Access Token");
        });


    }

    showDraftsDialog() {
        this.setState({draftsDialogIsOpen: true, drafts: []}, () => {

            blogService.fetchDraftPosts(this.state.postId)
                .then((drafts) => {
                    this.setState({drafts: drafts});
                })
                .catch((err) => {
                    Utils.notifyException(err, "Error fetching drafts.");
                });
        });
    }

    loadDraft(guid: string) {
        if (window.confirm("Are you sure you want to load this draft?")) {
            this.setState({draftsDialogIsOpen: false, isInitialized: false, drafts: []}, () => {
                blogService.fetchDraftPost(guid)
                    .then((model) => {
                        this.updateStateWithEditModel(model);
                        this.setState({isInitialized: true});
                        this.setState({lastSavedDraft: this.getCurrentEditModel()});
                    })
                    .catch((err) => {
                        Utils.notifyException(err, `Error fetching draft guid ${guid}.`);
                    });
            });
        }
    }

    getViewPostUrl() {
        let viewPostUrl = PortfolioLib.SharedUtils.replaceRouteParam(PortfolioLib.SharedRoutes.UI.Blog.ViewPost.Path, PortfolioLib.SharedRoutes.UI.Blog.ViewPost.PostIdParam, this.state.postId || "");
        viewPostUrl = PortfolioLib.SharedUtils.replaceRouteParam(viewPostUrl, PortfolioLib.SharedRoutes.UI.Blog.ViewPost.LongIdParam, this.state.longIdValue || "");
        return viewPostUrl;
    }


    render() {

        let viewPostUrl = this.getViewPostUrl();

        return (
            <div style={{position: "relative"}}>

                {/* TODO: Improve the prompt, only fire when dirty? */}
                {/* TODO: This was removed from react-router-dom. Need a different approach. */}
                {/*<Prompt
                    when={true}
                    message={location => (
                        `Are you sure you want to go to leave this page?`
                    )}
                />*/}

                {this.state.authStatus.isAdmin && this.state.isInitialized &&
                <div>

                    <textarea
                        className="BlogEditPost-copy-source-value"
                        value={this.state.copySourceValue}
                        onChange={(e) => this.handleCopySourceValueChange(e)}>Did this get copied?</textarea>

                    <ModalDialog isOpen={this.state.draftsDialogIsOpen} onClose={(e) => this.setState({draftsDialogIsOpen: false})}>
                        <h2>Drafts</h2>

                        <div style={{overflowY: "auto", maxHeight: "500px"}}>
                            <table>
                                <thead>
                                <tr>
                                    <th>&nbsp;</th>
                                    <th>Date</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.drafts.map((draft, i) => (
                                        <tr key={i}>
                                            <td style={{padding: "5px"}}>
                                                <button className="standard-button BlogEditPost-load-draft-button" onClick={(e) => this.loadDraft(draft.guid || "")}>Load
                                                </button>
                                            </td>
                                            <td style={{padding: "5px"}}>
                                                <span>{PortfolioLib.SharedUtils.dateToDateTimeString(draft.lastUpdatedDate)}</span>
                                            </td>
                                        </tr>
                                    )
                                )}
                                </tbody>
                            </table>
                        </div>
                    </ModalDialog>


                    <div className="BlogEditPost-code-mirror-wrap">

                        <div>
                            <div className="BlogEditPost-main-buttons-panel">
                                <button className="standard-button BlogEditPost-save-button" onClick={(e) => this.savePost()}>Save
                                </button>

                                <button className="standard-button BlogEditPost-drafts-button" onClick={(e) => this.showDraftsDialog()}>Drafts
                                </button>

                                <button className="standard-button BlogEditPost-view-post-button">
                                    <Link className="BlogEditPost-view-post-link" rel="canonical" to={viewPostUrl}>
                                        View
                                    </Link>
                                </button>
                            </div>

                            <h2>Basics</h2>
                            <div>
                                <div>Title</div>
                                <div style={{display: "flex"}}>
                                    <input
                                        type="text"
                                        style={{flex: "1"}}
                                        value={this.state.titleValue}
                                        onChange={(e) => this.handleTitleValueChange(e)}/>
                                </div>
                            </div>
                            <div className="spacer-row"/>
                            <div className="spacer-row"/>

                            <div>
                                <div>Long ID</div>

                                <div style={{display: "flex"}}>

                                    <input
                                        type="text"
                                        style={{flex: "1", marginRight: "10px"}}
                                        value={this.state.longIdValue}
                                        onChange={(e) => this.handleLongIdValueChange(e)}/>
                                    <button className="icon-button"
                                            onClick={(e) => this.updateLongId()}>
                                        <FaSync className="icon-button-icon"/>
                                    </button>
                                </div>
                            </div>
                            <div className="spacer-row"/>
                            <div className="spacer-row"/>

                            <div>
                                <div>Summary</div>
                                <div style={{display: "flex"}}>
                                <textarea
                                    style={{flex: "1"}}
                                    className="BlogEditPost-excerpt-input"
                                    value={this.state.excerptValue}
                                    onChange={(e) => this.handleExcerptChange(e)}/>
                                </div>
                            </div>
                            <div className="spacer-row"/>
                            <div className="spacer-row"/>

                            <div>
                                <div>Banner Image URL</div>
                                <div style={{display: "flex"}}>
                                    <input
                                        type="text"
                                        style={{flex: "1"}}
                                        value={this.state.bannerImageUrlValue}
                                        onChange={(e) => this.handleBannerImageUrlValueChange(e)}/>
                                </div>
                            </div>
                            <div>
                                <div>Banner Image Thumbnail URL</div>
                                <div style={{display: "flex"}}>
                                    <input
                                        type="text"
                                        style={{flex: "1"}}
                                        value={this.state.bannerImageThumbnailUrlValue}
                                        onChange={(e) => this.handleBannerImageThumbnailUrlValueChange(e)}/>
                                </div>
                            </div>
                            <div className="spacer-row"/>
                            <div className="spacer-row"/>

                            <div style={{display: "flex"}}>

                                <div style={{display: "inline-block", marginRight: "20px"}}>
                                    <div>Tags: ({this.state.tags.join(", ")})</div>
                                    <select className="BlogEditPost-tag-select" multiple
                                            value={this.state.tags} onChange={(e) => this.handleTagsChange(e)}>
                                        {this.state.tagOptions.map((t, i) => {
                                            return <option key={i}>
                                                {t}
                                            </option>
                                        })}
                                    </select>
                                </div>

                                <div style={{display: "inline-block", marginRight: "20px"}}>
                                    <div>Published?</div>
                                    <input
                                        type="checkbox"
                                        checked={this.state.isPublished}
                                        onChange={(e) => this.handleIsPublishedInputChange(e)}/>

                                    <button className="icon-button"
                                            style={{marginLeft: "10px"}}
                                            title="Refresh Prerender.io and FaceBook"
                                            onClick={(e) => this.refreshPrerenderIoAndFacebook()}>
                                        <FaSync className="icon-button-icon"/>
                                    </button>
                                </div>


                                <div style={{display: "inline-block", marginRight: "20px"}}>
                                    <div>Date</div>
                                    <input
                                        type="date"
                                        value={this.state.dateString}
                                        onChange={(e) => this.handleDateInputChange(e)}/>
                                </div>


                            </div>
                            <div className="spacer-row"/>
                            <div className="spacer-row"/>

                            <div>
                                <h2>
                                    Images
                                    <button className="icon-button" style={{marginLeft: "20px"}}
                                            onClick={(e) => this.activateFileUpload()}>
                                        <GoPlus className="icon-button-icon"/>
                                    </button>
                                </h2>
                                <div className="BlogEditPost-images-container">
                                    {this.state.images.map((imageModel, i) => {
                                        return <div key={i} className="BlogEditPost-image-tile">
                                            <button className="icon-button" style={{float: "right"}}
                                                    onClick={(e) => this.deleteImage(imageModel.name || "")}>
                                                <GoX className="icon-button-icon delete"/>
                                            </button>
                                            <button className="icon-button" style={{float: "right", marginRight: "4px"}} title="Copy Image Preview"
                                                    onClick={(e) => this.copyImagePreviewTags(imageModel.url || "", imageModel.thumbnailUrl || "" )}>
                                                <FaRegFileImage className="icon-button-icon"/>
                                            </button>
                                            <div>
                                                <ExternalLink url={imageModel.url || ""} showIcon={true}>
                                                    {imageModel.name}
                                                </ExternalLink>
                                            </div>
                                            <div>
                                                <ExternalLink url={imageModel.thumbnailUrl || ""} showIcon={true}>
                                                    Thumb
                                                </ExternalLink>
                                            </div>

                                            <div>
                                                <div style={{display: "inline-block"}}>
                                                    <ImagePreview caption={(imageModel.url || "") + "\r\n\r\n" + (imageModel.thumbnailUrl || "")} imagePath={imageModel.url || ""} thumbnailPath={imageModel.url || ""}/>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                    }
                                </div>

                                <input
                                    style={{display: "none"}}
                                    ref={(input) => {
                                        this.fileInput = input;
                                    }}
                                    multiple
                                    type="file"
                                    accept=".jpg,.jpeg,.png,.gif"
                                    onChange={(e) => this.handleFileInputChange(e)}/>
                            </div>
                            <div className="spacer-row"/>
                            <div className="spacer-row"/>

                            <h2>Content</h2>

                            <div style={{width: "100%", display: "inline-block"}} className="BlogEditPost-html-editor">
                                HTML

                                <div className="BlogEditPost-quick-tags-panel">
                                    <button className="icon-button BlogEditPost-quick-tag-button" title="<p>"
                                            onClick={(e) => this.insertPTags()}>
                                        <FaParagraph className="icon-button-icon"/>
                                    </button>
                                    <button className="icon-button BlogEditPost-quick-tag-button" title="Bold"
                                            onClick={(e) => this.insertBoldTags()}>
                                        <FaBold className="icon-button-icon"/>
                                    </button>
                                    <button className="icon-button BlogEditPost-quick-tag-button" title="Italic"
                                            onClick={(e) => this.insertItalicsTags()}>
                                        <FaItalic className="icon-button-icon"/>
                                    </button>
                                    <button className="icon-button BlogEditPost-quick-tag-button" title="<h2>"
                                            onClick={(e) => this.insertH2Tags()}>
                                        <FaHeading className="icon-button-icon"/>
                                    </button>
                                    <button className="icon-button BlogEditPost-quick-tag-button" title="<ul>"
                                            onClick={(e) => this.insertULTags()}>
                                        <FaListUl className="icon-button-icon"/>
                                    </button>
                                    <button className="icon-button BlogEditPost-quick-tag-button" title="<li>"
                                            onClick={(e) => this.insertLITags()}>
                                        <FaList className="icon-button-icon"/>
                                    </button>
                                    <button className="icon-button BlogEditPost-quick-tag-button" title="Image Preview Container"
                                            onClick={(e) => this.insertImagePreviewContainerTags()}>
                                        <FaTh className="icon-button-icon"/>
                                    </button>
                                    <button className="icon-button BlogEditPost-quick-tag-button" title="<Image Preview>"
                                            onClick={(e) => this.insertImagePreviewTags()}>
                                        <FaRegFileImage className="icon-button-icon"/>
                                    </button>
                                    <button className="icon-button BlogEditPost-quick-tag-button" title="<a>"
                                            onClick={(e) => this.insertATags()}>
                                        <GoLink className="icon-button-icon"/>
                                    </button>
                                    <button className="icon-button BlogEditPost-quick-tag-button" title="<ExternalLink>"
                                            onClick={(e) => this.insertExternalLinkTags()}>
                                        <FaExternalLinkAlt className="icon-button-icon"/>
                                    </button>
                                    <button className="icon-button BlogEditPost-quick-tag-button" title="YouTube"
                                            onClick={(e) => this.insertYouTubeTags()}>
                                        <FaYoutube className="icon-button-icon"/>
                                    </button>

                                </div>

                                <CodeMirror
                                    ref={(input) => {
                                        this.codeMirrorEditor = input;
                                        if (input != null) {
                                            input.mirror.on('keydown', (instance, event) => {
                                                this.handleCodeMirrorKeyDown(event);
                                            });
                                            input.mirror.on('keyup', (instance, event) => {
                                                this.handleCodeMirrorKeyUp(event);
                                            });
                                        }
                                    }}
                                    value={this.state.htmlValue}
                                    onChange={((newValue) => this.handleHtmlInputChange(newValue))}
                                    options={{mode: 'xml', lineWrapping: true}}/>
                            </div>


                            <div style={{width: "100%", display: "inline-block"}} className="BlogEditPost-css-editor">
                                CSS
                                <CodeMirror
                                    value={this.state.cssValue}
                                    onChange={((newCss) => this.handleCssInputChange(newCss))}
                                    options={{mode: 'css', lineWrapping: true}}/>
                            </div>
                        </div>

                    </div>

                    <div className="spacer-row"/>

                    <h2>Previews
                        <button className="icon-button" style={{marginLeft: "20px"}}
                                onClick={(e) => this.queueUpdatePreview()}>
                            <GoSync className="icon-button-icon"/>
                        </button>
                    </h2>

                    <hr/>


                    <BlogPostPreview postPreviewModel={this.state.postPreviewModel}
                                     authStatus={this.state.authStatus}/>

                    <hr/>


                    {this.state.outputPreview}

                </div>
                }


                {!this.state.authStatus.isAuthenticated && this.state.isInitialized &&
                <div>
                    <h2>Error</h2>
                    <p>You must be logged in to view this page.</p>
                </div>
                }

                {this.state.authStatus.isAuthenticated && !this.state.authStatus.isAdmin && this.state.isInitialized &&
                <div>
                    <h2>Error</h2>
                    <p>You are not authorized to view this page.</p>
                </div>
                }

                {!this.state.isInitialized &&
                <WaitOverlay/>
                }

                {this.state.isUploadingImages &&
                <WaitOverlay />
                }

            </div>
        );
    }


    copyImagePreviewTags(imagepath: string, thumbnailPath: string) {
        let tag = `<ImagePreview imagepath='${imagepath}' thumbnailpath='${thumbnailPath}' caption='' />`;

        this.setState({
            copySourceValue: tag,
        }, () => {
            let copyTextarea = document.querySelector(".BlogEditPost-copy-source-value");
            if (copyTextarea != null) {
                copyTextarea.select();
            }
            document.execCommand('copy');
        });

        Utils.notifyMessage('Copied ImagePreview to Clipboard');
    }

    handleCodeMirrorKeyDown(event: KeyboardEvent) {
console.log("Key: " + event.code);
        if (this.currentlyHandlingCodeMirrorKeyEvent) {
            return;
        }
        if (this.shouldHandleCodeMirrorKeyEvent(event)) {
            this.currentlyHandlingCodeMirrorKeyEvent = true;


            if (event.ctrlKey && event.shiftKey && event.altKey) {
                switch (event.code) {
                    case "KeyB":
                        this.insertBoldTags();
                        break;
                    case "KeyI":
                        this.insertItalicsTags();
                        break;
                    case "KeyP":
                        this.insertPTags();
                        break;
                    case "KeyH":
                        this.insertH2Tags();
                        break;
                    case "KeyU":
                        this.insertULTags();
                        break;
                    case "KeyL":
                        this.insertLITags();
                        break;
                    case "KeyE":
                        this.insertExternalLinkTags();
                        break;
                    case "KeyA":
                        this.insertATags();
                        break;
                    default:
                        break;
                }
            }

        }


    }
    handleCodeMirrorKeyUp(event: KeyboardEvent) {
        console.log("Key: " + event.code);
        if (this.shouldHandleCodeMirrorKeyEvent(event)) {
            this.currentlyHandlingCodeMirrorKeyEvent = false;
        }
    }

    shouldHandleCodeMirrorKeyEvent(event: KeyboardEvent) {
        if (event == null) {
            return false;
        }

        return event.keyCode >= 65 && event.keyCode <= 90;
    }

    insertBoldTags() {
        this.insertCodeMirrorTags("strong");
    }

    insertPTags() {
        this.insertCodeMirrorTags("p");
    }

    insertItalicsTags() {
        this.insertCodeMirrorTags("em");
    }

    insertH2Tags() {
        this.insertCodeMirrorTags("h2");
    }

    insertULTags() {
        this.insertCodeMirrorTags("ul");
    }
    insertLITags() {
        this.insertCodeMirrorTags("li");
    }

    insertImagePreviewContainerTags() {
        this.insertCodeMirrorTags("div", [{attr: "class", val: "common-blog-post-image-preview-container"}]);
    }

    insertImagePreviewTags() {
        this.insertCodeMirrorTags("ImagePreview", [{attr: "thumbnailpath"},
            {attr: "imagepath"},
            {attr: "caption"}]);
    }

    insertATags() {
        this.insertCodeMirrorTags("a", [{attr: "href"}]);
    }

    insertExternalLinkTags() {
        this.insertCodeMirrorTags("ExternalLink", [{attr: "url"}]);
    }

    insertYouTubeTags() {
        this.insertCodeMirrorTags("iframe", [{attr: "class", val: "common-blog-post-youtube-video"},
            {attr: "src", val: "https://www.youtube.com/embed/"},
            {attr: "frameborder", val: "0"},
            {attr: "allowfullscreen", val: "true"}]);
    }

    insertCodeMirrorTags(tag: string, attrs?: any[]) {
        if (this.codeMirrorEditor !== null) {
            let selection = this.codeMirrorEditor.mirror.getSelection();
            let attribs = "";
            if (attrs) {
                for (let i = 0; i < attrs.length; i++ ) {
                    attribs += " " + attrs[i].attr + "='" + (attrs[i].val ? attrs[i].val : "") + "'";
                }
            }

            let replacementStart = "<" + tag + attribs + ">" + (selection || "");
            let replacementEnd = "</" + tag + ">";
            this.codeMirrorEditor.mirror.replaceSelection(replacementStart);
            this.codeMirrorEditor.mirror.replaceSelection(replacementEnd, "start");
            //this.codeMirrorEditor.mirror.setCursor(cur);
            //this.codeMirrorEditor.focus();
        }
    }


    handleTitleValueChange(event: Event) {
        if (event.target instanceof HTMLInputElement) {
            this.setState({titleValue: event.target.value});
        }
    }


    handleDateInputChange(event: Event) {
        if (event.target instanceof HTMLInputElement) {
            this.setState({dateString: event.target.value});
        }
    }

    handleFileInputChange(event: Event) {

        if (event.target instanceof HTMLInputElement && event.target.files.length > 0) {

            let completedCount = 0;
            let targetCount = event.target.files.length;

            let imageBatchUploadModel = new PortfolioLib.BlogImageBatchUploadModel(this.state.postId,[], true);

            for (let i = 0; i < event.target.files.length; i++) {
                const reader = new FileReader();
                const file = event.target.files[i];

                reader.onload = (upload) => {

                    let imageUploadModel = new PortfolioLib.BlogImageUploadModel(
                        file.name,
                        file.type,
                        upload.target.result);

                    imageBatchUploadModel.blogImageUploadModels.push(imageUploadModel);
                    completedCount++;
                    if (completedCount >= targetCount) {
                        this.uploadImages(imageBatchUploadModel);
                    }

                };

                reader.readAsDataURL(file);
            }


        }
    }

    handleLongIdValueChange(event: Event) {
        if (event.target instanceof HTMLInputElement) {
            this.setState({longIdValue: event.target.value});
        }
    }

    handleExcerptChange(event: Event) {
        if (event.target instanceof HTMLTextAreaElement) {
            this.setState({excerptValue: event.target.value});
        }
    }


    handleCopySourceValueChange(event: Event) {
        if (event.target instanceof HTMLTextAreaElement) {
            this.setState({copySourceValue: event.target.value});
        }
    }

    handleBannerImageUrlValueChange(event: Event) {
        if (event.target instanceof HTMLInputElement) {
            this.setState({bannerImageUrlValue: event.target.value}, () => {
                if (this.state.bannerImageUrlValue.includes(" ")) {
                    Utils.notifyError("The bannerImageUrlValue should not include whitespace.");
                }
            });
        }
    }

    handleBannerImageThumbnailUrlValueChange(event: Event) {
        if (event.target instanceof HTMLInputElement) {
            this.setState({bannerImageThumbnailUrlValue: event.target.value}, () => {
                if (this.state.bannerImageThumbnailUrlValue.includes(" ")) {
                    Utils.notifyError("The bannerImageThumbnailUrlValue should not include whitespace.");
                }
            });
        }
    }

    handleIsPublishedInputChange(event: Event) {
        if (event.target instanceof HTMLInputElement) {
            this.setState({isPublished: event.target.checked});
        }
    }

    handleTagsChange(event: Event) {
        if (event.target instanceof HTMLSelectElement) {
            let xs = [];
            for (let i = 0; i < event.target.options.length; ++i) {
                let opt = event.target.options[i];
                opt.selected && xs.push(opt.value);
            }
            this.setState({tags: xs});
        }
    }

    handleHtmlInputChange(newValue: string): void {
        if (newValue) {
            this.setState({htmlValue: newValue});
        }
    }

    handleCssInputChange(newValue: string): void {
        if (newValue) {
            this.setState({cssValue: newValue});
        }
    }

    queueUpdatePreview() {
        // Cancel the pending update on change.
        if (this.state.refreshPreviewTimer) {
            clearTimeout(this.state.refreshPreviewTimer);
        }
        this.setState({refreshPreviewTimer: setTimeout((() => this.updatePreview()), 1000)});

    }

    updatePreview() {

        let previewPostUrl = PortfolioLib.SharedUtils.replaceRouteParam(PortfolioLib.SharedRoutes.UI.Blog.ViewPost.Path,
            PortfolioLib.SharedRoutes.UI.Blog.ViewPost.PostIdParam,
            this.state.postId);
        let postPreviewModel = new PortfolioLib.BlogPostPreviewModel(this.state.postId, this.state.longIdValue, this.state.titleValue, this.state.excerptValue, this.getDate(), this.state.bannerImageUrlValue, this.state.bannerImageThumbnailUrlValue, this.state.tags);
        let reactComponent = Utils.renderHtmlAsComponent(this.state.postId, this.state.longIdValue, this.state.titleValue, this.state.htmlValue, this.state.cssValue, this.getDate(), this.state.tags, this.state.bannerImageUrlValue);

        // Now that we have a react component, we set it to the state.
        // Our render() method includes a "{this.state.outputPreview}", which causes the
        // component to be rendered.
        this.setState({
            outputPreview: reactComponent,
            postPreviewModel: postPreviewModel,
            previewPostUrl: previewPostUrl,
            refreshPreviewTimer: null
        });
    }

    getDate(): ?Date {
        if (this.state.dateString) {
            let year = this.state.dateString.substr(0, 4);
            let month = this.state.dateString.substr(5, 2);
            let day = this.state.dateString.substr(8, 2);
            return new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));
        } else {
            return null;
        }
    }


}

export default BlogEditPost;