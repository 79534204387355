// @flow
import React, {Component} from 'react';
import './Support.css';
import PageLayout from '../../../Components/PageLayout/PageLayout'
import Utils from '../../../utils';
import {default as bgImage} from './Images/Maintenance.jpg';
import ReactDocMeta from 'react-document-meta';
import {GraviaGameHelpAndTroubleshootingRoute} from '../../../routeConfig'
import {GraviaGameFAQRoute} from '../../../routeConfig'
import {GraviaGameContactRoute} from '../../../routeConfig'
import {GraviaGamePresskitRoute} from '../../../routeConfig'
import LinkPanel from '../../../Components/LinkPanel/LinkPanel';
import LinkPanelItem from '../../../Models/LinkPanelItem';


class Support extends Component {

    state: {};


    constructor(props: any) {
        super(props);

        this.state = {};
    }


    render() {
        let canonicalUrl = "https://www.graviagame.com/Support";
        let metaDescription = "Frequently Asked Questions";
        let metaTags = [
            {name: "description", content: metaDescription},
            {property: "og:title", content: "Gravia - Support"},
            {property: "og:type", content: "website"},
            {property: "og:description", content: metaDescription},
            {property: "og:image", content: "https://www.graviagame.com" + bgImage},
            {property: "og:url", content: canonicalUrl},
            {property: "fb:app_id", content: Utils.getFacebookAppId()}
        ];
        //let highlightedQuestionId = this.props.location.hash;

        let linkItems = [
            new LinkPanelItem("Help / Troubleshooting", GraviaGameHelpAndTroubleshootingRoute.path),
            new LinkPanelItem("FAQ", GraviaGameFAQRoute.path),
            new LinkPanelItem("Contact", GraviaGameContactRoute.path),
            new LinkPanelItem("Presskit", GraviaGamePresskitRoute.path),
        ];

        return (
            <PageLayout caption="Support" title="Support" backgroundImage={bgImage}
                        opacity={0.1} grayscale={0.0}>
                <ReactDocMeta tags={metaTags}>
                    <div>
                        {/*<div className="spacer-row" />*/}

                        <p>Looking for some help, or have some questions about Gravia?</p>

                        <LinkPanel items={linkItems} />

                    </div>
                </ReactDocMeta>
            </PageLayout>
        );
    }
}


export default Support;