import { v4 as uuidv4 } from 'uuid';
import AppMessageModel from '../Models/AppMessageModel';

const singleton = Symbol();
const singletonEnforcer = Symbol();

/** This class is used to inform other listeners in the application about message and errors that
 * have occurred.  */
class MessageProviderService {

    constructor(enforcer) {
        if (enforcer !== singletonEnforcer) {
            throw new Error("Cannot construct singleton");
        }

        this._subscribers = [];
    }

    static get instance() {
        if (!this[singleton]) {
            this[singleton] = new MessageProviderService(singletonEnforcer);
        }
        return this[singleton];
    }


    _subscribers: Array<void>;


    get subscribers(): Array<any> {
        return this._subscribers;
    }

    set subscribers(value: Array<any>) {
        return this._subscribers = value;
    }

    subscribe(callback: () => void): string {
        let guid = uuidv4();
        this.subscribers[guid] = callback;
        //console.debug(`Added MessageProviderService subscription for ${guid}`);
        return guid;
    }

    unsubscribe(key: string) {
        delete this.subscribers[key];
        //console.debug(`Removing MessageProviderService subscription for ${key}`);
    }

    notify(appMessage: AppMessageModel) {
        for (let guid in this.subscribers) {
            if (this.subscribers.hasOwnProperty(guid)) {
                //console.debug(`Notifying ${guid} of MessageProviderService change.`);
                this.subscribers[guid](appMessage);
            }
        }
    }
}

export default MessageProviderService
