// @flow
import React, {Component} from 'react';
import './AppMessageDock.css';
import AppMessageModel from '../../Models/AppMessageModel';
import MessageProviderService from '../../Services/MessageProviderService';
import classNames from 'classnames';
import {GoX} from 'react-icons/go'
import {GoChevronRight} from 'react-icons/go'
import {GoChevronDown} from 'react-icons/go'
import { v4 as uuidv4 } from 'uuid';



class AppMessageDisplayModel {
    _message: ?string;
    _stackTrace: ?string;
    _isError: ?boolean;
    _id: string;

    static get Empty(): AppMessageDisplayModel {
        return new AppMessageDisplayModel(null, null, null);
    }

    constructor(message: ?string, stackTrace: ?string, isError: ?boolean) {
        this._id = uuidv4();
        this._message = message;
        this._stackTrace = stackTrace;
        this._isError = isError;
    }

    get message(): ?string {
        return this._message;
    }

    set message(value: ?string) {
        this._message = value;
    }


    get stackTrace(): ?string {
        return this._stackTrace;
    }

    set stackTrace(value: ?string) {
        this._stackTrace = value;
    }

    get isError(): ?boolean {
        return this._isError;
    }

    set isError(value: ?boolean) {
        this._isError = value;
    }


    get id(): string {
        return this._id;
    }
}


class AppMessageDock extends Component {

    state: {
        messageModels: Array<AppMessageDisplayModel>,
        messageProviderSubscriptionKey: string
    };

    constructor(props: $PropertyType<AppMessageDock, 'props'>) {
        super(props);

        this.state = {
            messageModels: [],
            messageProviderSubscriptionKey: ""
        };
    }

    componentDidMount() {
        this.setState({messageProviderSubscriptionKey: MessageProviderService.instance.subscribe((messageModel) => this.receiveMessage(messageModel))});
    }


    componentWillUnmount() {
        MessageProviderService.instance.unsubscribe(this.state.messageProviderSubscriptionKey);
    }

    removeMessage(messageModel: AppMessageDisplayModel) {
        let messages = this.state.messageModels;
        let index = messages.indexOf(messageModel);
        messages.splice(index, 1);
        this.setState({messageModels: messages});
    }


    receiveMessage(messageModel: AppMessageModel) {
        let displayModel = new AppMessageDisplayModel(messageModel.message, messageModel.stackTrace, messageModel.isError);

        let messages = this.state.messageModels;
        messages.push(displayModel);
        this.setState({messageModels: messages});
    }

    render() {
        return (
            <div className="AppMessageDock-main-container ">
                {/* Note: the key for these is a guid. When it was simply "i", we had issues where
                * removing an element from the array would disrupt one of its neighbors. */}
                {this.state.messageModels.map((messageModel, i) => (
                    <AppMessageDockMessage key={messageModel.id} messageModel={messageModel} onClose={() => this.removeMessage(messageModel)}/>
                ))}

            </div>
        );
    }


}


class AppMessageDockMessage extends Component {
    props: {
        messageModel: AppMessageDisplayModel,
        onClose: () => void
    };

    static defaultProps = {};

    state: {
        isShowing: boolean,
        stackTraceIsOpen: boolean,
        showTimer: any,
        beginCloseTimer: any,
        endCloseTimer: any
    };


    constructor(props: $PropertyType<AppMessageDockMessage, 'props'>) {
        super(props);

        this.state = {
            isShowing: false,
            stackTraceIsOpen: false,
            showTimer: null,
            beginCloseTimer: null,
            endCloseTimer: null
        };
    }

    componentDidMount() {
        this.setState({
            showTimer: setTimeout(() => this.initAnimations(), 10)
        });
    }

    initAnimations() {
        // Success messages disappear quickly. Errors stick around longer.
        this.setState({
            isShowing: true,
            beginCloseTimer: setTimeout(() => this.beginClose(), (this.props.messageModel.isError ? (30 * 1000) : (5 * 1000)))
        });
    }

    beginClose() {
        if (this.state.beginCloseTimer) {
            clearTimeout(this.state.beginCloseTimer);
            this.setState({beginCloseTimer: null});
        }
        if (this.state.isShowing) {
            this.setState({
                isShowing: false,
                endCloseTimer: setTimeout(() => this.props.onClose(), 1000)
            });

        }
    }

    componentWillUnmount() {
        if (this.state.showTimer) {
            clearTimeout(this.state.showTimer);
            this.setState({showTimer: null});
        }
        if (this.state.beginCloseTimer) {
            clearTimeout(this.state.beginCloseTimer);
            this.setState({beginCloseTimer: null});
        }

        if (this.state.endCloseTimer) {
            clearTimeout(this.state.endCloseTimer);
            this.setState({endCloseTimer: null});
        }
    }

    clearDecay() {
        if (this.state.beginCloseTimer) {
            clearTimeout(this.state.beginCloseTimer);
            this.setState({beginCloseTimer: null});
        }
    }

    toggleStackTrace() {

        // If the user toggles the stacktrace, cancel the auto-close timer.
      //  this.clearDecay();

        this.setState((prevState, props) => ({
            stackTraceIsOpen: !prevState.stackTraceIsOpen
        }));
    }

    render() {
        let classes = classNames("AppMessageDock-item-container", this.state.isShowing ? "showing" : "", this.props.messageModel.isError ? "error" : "");
        let progressBarClasses = classNames("AppMessageDock-progress-bar", this.state.beginCloseTimer ? "decaying" : "", this.props.messageModel.isError ? "error" : "");
        return (
            <div className={classes} onClick={(e) => this.clearDecay()}>
                <GoX className="AppMessageDock-close-button icon-button-icon"
                     onClick={(e) => this.beginClose()}/>
                <div className="AppMessageDock-inner-item-container">

                    {!this.props.messageModel.isError &&
                    <div>
                        <p>{this.props.messageModel.message}</p>
                    </div>
                    }

                    {this.props.messageModel.isError &&
                    <div>
                        <p>Error: {this.props.messageModel.message}</p>
                        {this.props.messageModel.stackTrace && this.props.messageModel.stackTrace.length > 0 &&
                        <div className="AppMessageDock-error-stack-trace">
                            <div onClick={(e) => this.toggleStackTrace()}>
                                {this.state.stackTraceIsOpen &&
                                <GoChevronDown/>
                                }
                                {!this.state.stackTraceIsOpen &&
                                <GoChevronRight/>
                                }
                                StackTrace:
                            </div>
                            {this.state.stackTraceIsOpen &&
                            <pre style={{whiteSpace: "pre-wrap", wordWrap: "break-word"}}>{this.props.messageModel.stackTrace}</pre>
                            }
                        </div>
                        }

                    </div>

                    }

                </div>
                <div className={progressBarClasses}/>
            </div>
        );
    }


}


export default AppMessageDock;