// @flow
import React, {Component} from 'react';
import './Error.css';
import PageLayout from '../../../Components/PageLayout/PageLayout'
import {Link} from 'react-router-dom'
import {DanGoyetteContactRoute} from '../../../routeConfig.js';
import {GraviaGameContactRoute} from '../../../routeConfig.js';
import {Helmet} from "react-helmet";
import Utils from "../../../utils";
import AppConstants from "../../../appConstants";


class Error extends Component {
    render() {
        let contactUrl = Utils.getApplication() === AppConstants.Applications.DanGoyette ? DanGoyetteContactRoute.path : GraviaGameContactRoute.path;
        return (
            <PageLayout caption="Error" showBannerImage={false}>
                <Helmet>
                    <meta name="prerender-status-code" content="404" />
                </Helmet>

                <h2>Error</h2>

                <p>You're seeing this page because something went wrong. You can <Link to={contactUrl}>Contact Me</Link> to let me know about the problem.</p>

                {this.props.match.params.message &&
                <p>Additional Detail: {this.props.match.params.message}</p>
                }
            </PageLayout>
        );
    }

}


export default Error;