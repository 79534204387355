// @flow

class LinkPanelItem {
    _title: ?string;
    _linkTo: ?string;

    static get Empty() : LinkPanelItem{
        return new LinkPanelItem(null, null);
    }

    constructor(title: ?string, linkTo: ?string) {
        this._title = title;
        this._linkTo = linkTo;
    }

    get title(): ?string {
        return this._title;
    }

    set title(value: ?string) {
        this._title = value;
    }


    get linkTo(): ?string {
        return this._linkTo;
    }

    set linkTo(value: ?string) {
        this._linkTo = value;
    }
}


export default LinkPanelItem;
