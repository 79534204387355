// @flow
import React from 'react';
import ReactDOM from 'react-dom';
import App from './Components/App/App';
//import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';
import './index.css';
import './Theme/Main.css';

ReactDOM.render(<App />, document.getElementById('root'));
//registerServiceWorker();
unregister();
