// @flow

class AppMessageModel {
    _message: ?string;
    _stackTrace: ?string;
    _isError: ?boolean;

    static get Empty() : AppMessageModel{
        return new AppMessageModel(null, null, null);
    }

    constructor(message: ?string, stackTrace: ?string, isError: ?boolean) {
        this._message = message;
        this._stackTrace = stackTrace;
        this._isError = isError;
    }

    get message(): ?string {
        return this._message;
    }

    set message(value: ?string) {
        this._message = value;
    }


    get stackTrace(): ?string {
        return this._stackTrace;
    }

    set stackTrace(value: ?string) {
        this._stackTrace = value;
    }


    get isError(): ?boolean {
        return this._isError;
    }

    set isError(value: ?boolean) {
        this._isError = value;
    }

}


export default AppMessageModel;
