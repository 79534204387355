// @flow

class AppConstants {

    static get ApiRoutes() {
        class ApiRoutes {

            static Auth = {
                'facebook': 'auth/facebook',
                'authStatus': 'auth/authStatus',
                'logout': 'auth/logout'
            };

        }

        return ApiRoutes;
    }

    static get ExternalURLs() {
        class ExternalURLs {

            static get Discord() {
                return "https://discord.gg/vwEg5ms";
            };
            static get Reddit() {
                return "https://www.reddit.com/r/gravia"
            };
            static get Steam() {
                return "https://store.steampowered.com/app/906470/Gravia/"
            };
            static get Twitter() {
                return "https://twitter.com/GraviaGame"
            };
            static get Facebook() {
                return "https://www.facebook.com/GraviaGame/"
            };
            static get Instagram() {
                return "https://www.instagram.com/graviagame/"
            };
        }

        return ExternalURLs;
    }

    static get Applications() {
        class Applications {

            static get DanGoyette() {
                return "DanGoyette"
            };

            static get GraviaGame() {
                return "GraviaGame"
            };
        }

        return Applications;
    }


    static get Env() {
        class Env {
            static get REACT_APP_API_HOST() {
                return "REACT_APP_API_HOST"
            };

            static get REACT_APP_RAW_API_HOST() {
                return "REACT_APP_RAW_API_HOST"
            };

            static get REACT_APP_FACEBOOK_APP_ID() {
                return "REACT_APP_FACEBOOK_APP_ID"
            };

            static get REACT_APP_FACEBOOK_APP_SECRET() {
                return "REACT_APP_FACEBOOK_APP_SECRET"
            };

            static get REACT_APP_RSS_FEED_URL() {
                return "REACT_APP_RSS_FEED_URL"
            };

            static get REACT_APP_APPLICATION() {
                return "REACT_APP_APPLICATION"
            };

            static get REACT_APP_PRERENDER_IO_KEY() {
                return "REACT_APP_PRERENDER_IO_KEY"
            };

        }
        return Env;
    }

}

export default AppConstants;