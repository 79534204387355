// @flow
import React, {Component} from 'react';
import './ImagePreview.css';
import classNames from 'classnames';
import ImageViewDialog from '../ImageViewDialog/ImageViewDialog'


class ImagePreview extends Component {
    props: {
        imagePath: string,
        thumbnailPath: string,
        floatRight: boolean,
        floatLeft: boolean,
        caption: string
    };

    static defaultProps = {
        floatRight: false,
        floatLeft: false,
        caption: ""
    };


    state: {
        fullImageOpen: boolean,
    };

    constructor(props: any) {
        super(props);
        this.state = {
            fullImageOpen: false
        };
    }

    render() {

        let thumbnailContainerClasses = classNames('ImagePreview-thumbnail-container',
            this.props.floatRight ? 'right' : '',
            this.props.floatLeft ? 'left' : '',
        );

        return (
            <div className={thumbnailContainerClasses} onClick={(e) => !this.state.fullImageOpen && this.openImage()}>

                <img className="ImagePreview-thumbnail-image" alt={this.props.caption} title={this.props.caption} src={this.props.thumbnailPath}
                     data-fullImagePath={this.props.imagePath} data-imageCaption={this.props.caption}
                />

                {this.state.fullImageOpen &&
                    <div>
                        <ImageViewDialog imagePath={this.props.imagePath} caption={this.props.caption} onClose={() => this.closeImage()} />
                    </div>
                }

            </div>
        );
    }

    openImage() {
        this.setState({fullImageOpen: true});
    }

    closeImage() {
        this.setState({fullImageOpen: false});
    }
}

export default ImagePreview;