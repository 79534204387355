// @flow
import React, {Component} from 'react'
import './WaitOverlay.css'
import {GoSync} from 'react-icons/go'
import className from 'classnames';


class WaitOverlay extends Component {

    props: {
        showOverlay: boolean
    };

    static defaultProps = {
        showOverlay: true
    };


    state: {
    };

    constructor(props: any) {
        super(props);
        this.state = {
        };
    }


    render() {
        let mainPanelclasses = className('WaitOverlay-main-panel', this.props.showOverlay ? 'overlay' : '');
        return (
            <div className={mainPanelclasses}>
                <div className="WaitOverlay-spinner-panel">
                    <GoSync size="50" />
                </div>
            </div>
        );
    }


}


export default WaitOverlay;