// @flow
import React, {Component} from 'react';
import './Contact.css';
import AppConstants from '../../../../../appConstants';
import PageLayout from '../../../../../Components/PageLayout/PageLayout'
import {default as bgImage} from './Images/BehindTheScenes.jpg';
import {FaTwitter} from 'react-icons/fa'
import {FaSteam} from 'react-icons/fa'
import {FaInstagram} from 'react-icons/fa'
import {FaRedditAlien} from 'react-icons/fa'
import {FaDiscord} from 'react-icons/fa'
import {GoMail} from 'react-icons/go'
import ExternalLink from '../../../../../Components/ExternalLink/ExternalLink';


class Contact extends Component {
    render() {
        return (
            <PageLayout caption="Contact" backgroundImage={bgImage} opacity={0.15} grayscale={0.1}>
                <div className="Contact-item-container">
                    <ContactLink url="mailto:dan@graviagame.com" label="dan@graviagame.com" icon={<GoMail />}/>
                    <ContactLink url={AppConstants.ExternalURLs.Discord} label="Discord"
                                 icon={<FaDiscord />}/>
                    <ContactLink url={AppConstants.ExternalURLs.Steam} label="Steam"
                                 icon={<FaSteam />}/>
                    <ContactLink url={AppConstants.ExternalURLs.Twitter} label="Twitter"
                                 icon={<FaTwitter />}/>
                    {/*<ContactLink url={AppConstants.ExternalURLs.Facebook} label="Facebook"*/}
                                 {/*icon={<FaFacebook />}/>*/}
                    <ContactLink url={AppConstants.ExternalURLs.Instagram} label="Instagram"
                                 icon={<FaInstagram />}/>
                    <ContactLink url={AppConstants.ExternalURLs.Reddit} label="Reddit"
                                 icon={<FaRedditAlien />}/>

                </div>

               {/* <div className="spacer-row" />
                <div className="spacer-row" />
                <div className="Contact-privacy-policy">
                    <p><strong>Privacy Policy:</strong> Any data collected on this site is used only to support Facebook's Likes and Comments systems. We don't keep any of this data; it's just used to interact with Facebook's public API.</p>
                </div>*/}
            </PageLayout>
        );
    }
}

class ContactLink extends Component {
    props: {
        url: string,
        label: string,
        icon: any
    };

    render() {
        return (
            <div className="Contact-item-container">
                <div className="Contact-item">
                    <ExternalLink url={this.props.url} showIcon={false}>
                        <div className="Contact-item-icon">{this.props.icon}</div>
                    </ExternalLink>
                    <p className="Contact-item-label">{this.props.label}</p>
                </div>
            </div>
        );
    }
}

export default Contact;