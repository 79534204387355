// @flow
import PortfolioLib from 'goyette-portfolio-lib';

import DanGoyetteHome from './Pages/DanGoyette/Home/Home';
import DanGoyetteContact from './Pages/DanGoyette/Contact/Contact';
import DanGoyetteMusic from './Pages/DanGoyette/Music/Music';

import DanGoyetteConsulting from './Pages/DanGoyette/Consulting/Consulting';
import DanGoyetteCaseStudy_SportingGoods from './Pages/DanGoyette/CaseStudy_SportingGoods/CaseStudy_SportingGoods';
import DanGoyetteCaseStudy_InvestmentCompany from './Pages/DanGoyette/CaseStudy_InvestmentCompany/CaseStudy_InvestmentCompany';
import DanGoyetteGameDev from './Pages/DanGoyette/GameDev/GameDev';
import DanGoyetteCubeDrop from './Pages/DanGoyette/CubeDrop/CubeDrop';
import DanGoyetteCubeDropPressKit from './Pages/DanGoyette/CubeDropPressKit/CubeDropPressKit';

import GraviaGameGravia from './Pages/GraviaGame/Gravia/Gravia';
import GraviaGameEarlyAccess from './Pages/GraviaGame/EarlyAccess/EarlyAccess';
import GraviaGamePlaytesting from './Pages/GraviaGame/Playtesting/Playtesting';
import GraviaGameMedia from './Pages/GraviaGame/Media/Media';
import GraviaGameSupport from './Pages/GraviaGame/Support/Support';
import GraviaGameHelpAndTroubleshooting from './Pages/GraviaGame/Support/Sub/HelpAndTroubleshooting/HelpAndTroubleshooting';
import GraviaGameFAQ from './Pages/GraviaGame/Support/Sub/FAQ/FAQ';
import GraviaGameWork from './Pages/GraviaGame/Work/Work';
import GraviaGameContact from './Pages/GraviaGame/Support/Sub/Contact/Contact';
import GraviaGamePresskit from './Pages/GraviaGame/Support/Sub/Presskit/Presskit';

import Blog from './Pages/Common/Blog/Blog';
import Error from './Pages/Common/Error/Error';
import Privacy from './Pages/Common/Privacy/Privacy';
import LoginLanding from './Pages/Common/LoginLanding/LoginLanding';
import Admin from "./Pages/Common/Admin/Admin/Admin";
import AdminJiraReports from "./Pages/Common/Admin/AdminJiraReports/AdminJiraReports";
import AdminTelemetry from "./Pages/Common/Admin/AdminTelemetry/AdminTelemetry";

import GraviaLogoImage from './Pages/GraviaGame/Media/Images/GraviaLogoTextOnly.png';

export class RouteEntry {
    path: string;
    exact: boolean;
    component: any;
    label: string;
    isNavigation: boolean;
    isSignature: boolean;
    showInMobile: boolean;
    imageSource: string;
    imageWidth: number;
    imageMarginLeft: string;
    imageMarginTop: string;

}

// Dan-Goyette

export const DanGoyetteHomeRoute =
    (() => {
        let r = new RouteEntry();
        r.path = '/';
        r.exact = true;
        r.component = DanGoyetteHome;
        r.label = "Dan Goyette";
        r.isNavigation = true;
        r.isSignature = true;
        r.showInMobile = true;
        return r;
    })();


export const DanGoyetteMusicRoute =
    (() => {
        let r = new RouteEntry();
        r.path = '/music';
        r.component = DanGoyetteMusic;
        r.label = "Music";
        r.isNavigation = true;
        r.showInMobile = true;
        return r;
    })();

export const DanGoyetteContactRoute =
    (() => {
        let r = new RouteEntry();
        r.path = '/contact';
        r.component = DanGoyetteContact;
        r.label = "Contact";
        r.isNavigation = true;
        r.showInMobile = true;
        return r;
    })();


export const DanGoyetteConsultingRoute =
    (() => {
        let r = new RouteEntry();
        r.path = '/consulting';
        r.exact = true;
        r.component = DanGoyetteConsulting;
        r.label = "Consulting";
        r.isNavigation = true;
        r.showInMobile = true;
        return r;
    })();

export const DanGoyetteCaseStudy_SportingGoodsRoute =
    (() => {
        let r = new RouteEntry();
        r.path = '/consulting/sportingGoods';
        r.component = DanGoyetteCaseStudy_SportingGoods;
        r.label = "Sporting Goods Company";
        r.showInMobile = false;
        return r;
    })();

export const DanGoyetteCaseStudy_InvestmentCompanyRoute =
    (() => {
        let r = new RouteEntry();
        r.path = '/consulting/investmentCompany';
        r.component = DanGoyetteCaseStudy_InvestmentCompany;
        r.label = "Investment Company";
        r.showInMobile = false;
        return r;
    })();


export const DanGoyetteGameDevRoute =
    (() => {
        let r = new RouteEntry();
        r.path = '/gameDev';
        r.exact = true;
        r.component = DanGoyetteGameDev;
        r.label = "Game Dev";
        r.isNavigation = true;
        r.showInMobile = true;
        return r;
    })();

export const DanGoyetteCubeDropPressKitRoute =
    (() => {
        let r = new RouteEntry();
        r.path = '/gameDev/Cube-Drop/pressKit';
        r.component = DanGoyetteCubeDropPressKit;
        r.label = "Cube-Drop Presskit";
        r.showInMobile = false;
        r.exact = true;
        return r;
    })();

export const DanGoyetteCubeDropRoute =
    (() => {
        let r = new RouteEntry();
        r.path = '/gameDev/Cube-Drop';
        r.component = DanGoyetteCubeDrop;
        r.label = "Cube-Drop";
        r.showInMobile = false;
        r.exact = true;
        return r;
    })();

// Gravia

export const GraviaGameGraviaRoute =
    (() => {
        let r = new RouteEntry();
        r.path = '/'; // Careful: Social media canonical links depend on this. So, it can't really be changed.
        r.exact = true;
        r.component = GraviaGameGravia;
        r.label = "Home";
        r.isNavigation = true;
        r.isSignature = true;
        r.showInMobile = true;
        r.imageSource = GraviaLogoImage;
        r.imageWidth = 250;
        r.imageMarginLeft = "0px";
        r.imageMarginTop = "25px";
        return r;
    })();



export const GraviaGameEarlyAccessRoute =
    (() => {
        let r = new RouteEntry();
        r.path = '/earlyAccess';
        r.exact = true;
        r.component = GraviaGameEarlyAccess;
        r.label = "Early Access";
        r.isNavigation = true;
        r.showInMobile = true;
        return r;
    })();

export const GraviaGameMediaRoute =
    (() => {
        let r = new RouteEntry();
        r.path = '/media';
        r.exact = true;
        r.component = GraviaGameMedia;
        r.label = "Media";
        r.isNavigation = true;
        r.showInMobile = true;
        return r;
    })();

export const GraviaGamePlaytestingRoute =
    (() => {
        let r = new RouteEntry();
        r.path = '/playtesting';
        r.exact = true;
        r.component = GraviaGamePlaytesting;
        r.label = "Playtesting";
        r.isNavigation = false;
        r.showInMobile = false;
        return r;
    })();

export const GraviaGameWorkRoute =
    (() => {
        let r = new RouteEntry();
        r.path = '/work';
        r.exact = true;
        r.component = GraviaGameWork;
        r.label = "Work";
        r.isNavigation = true;
        r.showInMobile = true;
        return r;
    })();

export const GraviaGameContactRoute =
    (() => {
        let r = new RouteEntry();
        r.path = '/support/contact';
        r.component = GraviaGameContact;
        r.label = "Contact";
        r.isNavigation = false;
        r.showInMobile = false;
        return r;
    })();


export const GraviaGameSupportRoute =
    (() => {
        let r = new RouteEntry();
        r.path = '/support';
        r.exact = true;
        r.component = GraviaGameSupport;
        r.label = "Support";
        r.isNavigation = true;
        r.showInMobile = true;
        return r;
    })();




export const GraviaGameHelpAndTroubleshootingRoute =
    (() => {
        let r = new RouteEntry();
        r.path = '/support/helpAndTroubleshooting';
        r.component = GraviaGameHelpAndTroubleshooting;
        r.label = "Help and Troubleshooting";
        r.isNavigation = false;
        r.showInMobile = false;
        return r;
    })();



export const GraviaGameFAQRoute =
    (() => {
        let r = new RouteEntry();
        r.path = '/support/faq';
        r.component = GraviaGameFAQ;
        r.label = "FAQ";
        r.isNavigation = false;
        r.showInMobile = false;
        return r;
    })();


export const GraviaGamePresskitRoute =
    (() => {
        let r = new RouteEntry();
        r.path = '/support/presskit';
        r.component = GraviaGamePresskit;
        r.label = "Presskit";
        r.isNavigation = false;
        r.showInMobile = false;
        return r;
    })();



// Common

export const BlogRoute =
    (() => {
        let r = new RouteEntry();
        r.path = PortfolioLib.SharedRoutes.UI.Blog.Root;
        r.component = Blog;
        r.label = "Blog";
        r.isNavigation = true;
        r.showInMobile = true;
        return r;
    })();

export const AdminRoute =
    (() => {
        let r = new RouteEntry();
        r.path = PortfolioLib.SharedRoutes.UI.Admin.Home.Path;
        r.exact = true;
        r.component = Admin;
        r.label = "Admin";
        r.isNavigation = false;
        r.showInMobile = false;
        return r;
    })();

export const AdminJiraReportsRoute =
    (() => {
        let r = new RouteEntry();
        r.path = PortfolioLib.SharedRoutes.UI.Admin.Jira.Path;
        r.exact = true;
        r.component = AdminJiraReports;
        r.label = "Admin Jira Reports";
        r.isNavigation = false;
        r.showInMobile = false;
        return r;
    })();

export const AdminTelemetryRoute =
    (() => {
        let r = new RouteEntry();
        r.path = PortfolioLib.SharedRoutes.UI.Admin.Telemetry.Path;
        r.exact = true;
        r.component = AdminTelemetry;
        r.label = "Admin Jira Reports";
        r.isNavigation = false;
        r.showInMobile = false;
        return r;
    })();



export const ErrorRoute =
    (() => {
        let r = new RouteEntry();
        r.path = PortfolioLib.SharedRoutes.UI.Error.Path;
        r.component = Error;
        r.exact = false;
        r.label = "Error";
        r.isNavigation = false;
        r.showInMobile = false;
        return r;
    })();



export const PrivacyRoute =
    (() => {
        let r = new RouteEntry();
        r.path = PortfolioLib.SharedRoutes.UI.Privacy.Path;
        r.component = Privacy;
        r.exact = false;
        r.label = "Privacy";
        r.isNavigation = false;
        r.showInMobile = false;
        return r;
    })();

export const LoginLandingRoute =
    (() => {
        let r = new RouteEntry();
        r.path = PortfolioLib.SharedRoutes.UI.LoginLanding.Path;
        r.component = LoginLanding;
        r.label = "Login Landing";
        r.isNavigation = false;
        r.showInMobile = false;
        return r;
    })();



export const DanGoyetteRoutes : Array<RouteEntry> = [
    DanGoyetteHomeRoute,
    DanGoyetteGameDevRoute,
    DanGoyetteConsultingRoute,
    DanGoyetteMusicRoute,
    DanGoyetteContactRoute,

    DanGoyetteCubeDropRoute,
    DanGoyetteCubeDropPressKitRoute,
    DanGoyetteCaseStudy_SportingGoodsRoute,
    DanGoyetteCaseStudy_InvestmentCompanyRoute,

    BlogRoute,
    AdminRoute,
    AdminJiraReportsRoute,
    AdminTelemetryRoute,
    ErrorRoute,
    PrivacyRoute,
    LoginLandingRoute
];


export const GraviaGameRoutes : Array<RouteEntry> = [
    GraviaGameGraviaRoute,
    GraviaGameEarlyAccessRoute,
    GraviaGameMediaRoute,
    GraviaGameSupportRoute,
    GraviaGameHelpAndTroubleshootingRoute,
    GraviaGameFAQRoute,
    GraviaGameContactRoute,
    GraviaGamePresskitRoute,
    GraviaGamePlaytestingRoute,
//    GraviaGameWorkRoute,

    BlogRoute,
    AdminRoute,
    AdminJiraReportsRoute,
    AdminTelemetryRoute,
    ErrorRoute,
    PrivacyRoute,
    LoginLandingRoute
];



