// @flow
import React, {Component} from 'react';
import './Work.css';
import PageLayout from '../../../Components/PageLayout/PageLayout'
import {Link} from 'react-router-dom';
import Utils from '../../../utils';
import {default as bgImage} from './Images/GraviaWork.jpg';
import ReactDocMeta from 'react-document-meta';


class Work extends Component {

    state: {};


    constructor(props: any) {
        super(props);


        this.state = {};
    }

    render() {
        let canonicalUrl = "https://www.graviagame.com/Work";
        let metaDescription = "Contract and other work I'm looking to have done";
        let metaTags = [
            {name: "description", content: metaDescription},
            {property: "og:title", content: "Gravia - Work"},
            {property: "og:type", content: "website"},
            {property: "og:description", content: metaDescription},
            {property: "og:image", content: "https://www.graviagame.com" + bgImage},
            {property: "og:url", content: canonicalUrl},
            {property: "fb:app_id", content: Utils.getFacebookAppId()}
        ];

        return (
            <PageLayout caption="Work" title="Work" backgroundImage={bgImage}
                        opacity={0.1} grayscale={0.0}>
                <ReactDocMeta tags={metaTags}>
                    <div>
                        <p>While I'm building Gravia mainly by myself, there are a few things I'm looking to outsource. These will all be contract-based work, not paid positions. If
                            you're interested, please get in touch with me on the <Link to="/contact">Contact</Link> page, and we can discuss it.
                        </p>

                        <div className="spacer-row"/>
                        <div className="spacer-row"/>

                        <h2>3D Character Models</h2>

                        <p>Budget: Negotiable</p>

                        <p>I'm looking for a 3D artist to create rigged and textured models for the main characters in my game. There are a total of three major characters, described below, who need detailed models capable of humanoid animation. Although I have some inspiration and concept art for the characters, it's not that important that the characters be absolutely unique. A best-case scenario would probably be to find an artist who has a library of previously created characters that could be reskinned for my needs, rather than a completely custom model built from scratch.</p>

                        <p>I'll need to see a portfolio of your work to make sure it generally fits with the aesthetic and tone I'm looking for. Ideally you could provide an unskinned, rigged model (low-poly is fine) so I can ensure it imports and animates properly in Unity.</p>

                        <p>General Requirements:</p>
                        <ul>
                            <li>The Look &amp; Feel should be realistic, but not hyper-realistic. I'm not trying to push the limits of character art in this game.</li>
                            <li>I'm using Unity, so all models should be rigged to work properly in Unity using standard Humanoid animations.</li>
                            <li>Models should contain proper blend shapes to support lip sync and eye/blink control.</li>
                            <li>Each model should be somewhere around 50k tris per model, more or less, with lower resolution LODs.</li>
                            <li>Materials/Textures should support PBR workflow (not Specular workflow). That is, the materials should minimally contain the following textures types: Base Color, Normal, Metallic, Roughness. I'm using Unity's HDRP.</li>
                        </ul>

                        <p>Nice to Haves:</p>
                        <ul>
                            <li>One of the characters wears glasses. It would be nice if these were removable, so that he could take them on and off occasionally, or have them knocked off.</li>
                        </ul>

                        <p>Below is a list of the characters, with a brief description and some pictures of how I imagine them.</p>

                        <h3>Man 1</h3>
                        <ul>
                            <li>Age: 55</li>
                            <li>Ethnicity: African American</li>
                            <li>Height: 5'11"</li>
                        </ul>

                        <p>Man 1 is a scientist, around age 50, Africa American, with some greying hair. He'll generally look like your standard scientist character, wearing glasses and a white lab coat.</p>

                        <p>My real-world inspiration for Man 1's look is a doctor named Vivien Theodore Thomas. I'm not looking for a direct copy/paste of his features, but these images cpature the general look I'd like:</p>

                        <div>
                            <img style={{width: "300px", padding: "5px"}} alt="Man 1-a" src={"https://www.baltimoresun.com/resizer/LP4fawIe-B4h_MzSCQo0k-IoK90=/800x1156/top/arc-anglerfish-arc2-prod-tronc.s3.amazonaws.com/public/N5EZR4JGCBG4JMAA4TE2OZQI2A.jpg"}/>
                            <img style={{width: "200px", padding: "5px"}} alt="Man 1-b" src={"https://cdn.vanderbilt.edu/vu-web/medschool-wpcontent/sites/45/2018/08/25035242/vivien.jpg"}/>
                        </div>

                        <div className="spacer-row"/>
                        <div className="spacer-row"/>
                        <h3>Man 2</h3>
                        <ul>
                            <li>Age: 40</li>
                            <li>Ethnicity: Caucasian, British</li>
                            <li>Height: 6'1"</li>
                        </ul>

                        <p>Man 2 is roughly 40-years-old, with a refined British accent, and a charismatic personality. He dresses in finely tailored, but styling suits. He is tall and slender, but with an athletic build.</p>

                        <p>The inspiration for his appearance is actor James D'Arcy:</p>
                        <div>
                            <img style={{width: "500px", padding: "5px"}} alt="Man 2-a" src={"https://heightline.com/wp-content/uploads/James-DArcy-640x353.jpg"}/>
                            <img style={{width: "300px", padding: "5px"}} alt="Man 2-b" src={"https://anothertongue.com/img/Artist/james-darcy.png"}/>
                        </div>

                        <div className="spacer-row"/>
                        <div className="spacer-row"/>
                        <h3>Woman 1</h3>
                        <ul>
                            <li>Age: 34</li>
                            <li>Ethnicity: Iranian</li>
                            <li>Height: 5'8"</li>
                        </ul>

                        <p>Woman 1 has a military background. She is very serious in her tone. She has two outfits during the game. One is a more formal military suit with a beret, while the other is a more combat-ready outfit with a hair tie or headband. </p>

                        <p>The inspiration for her appearance is actress Ahd Kamel:</p>
                        <div>
                            <img style={{width: "400px", padding: "5px"}} alt="Woman 1-a" src={"https://www.arabnews.com/sites/default/files/styles/n_670_395/public/media/19/06/2013/1371469134822491600.jpg?itok=-wTcQLYZ"}/>
                            <img style={{width: "400px", padding: "5px"}} alt="Woman 1-b" src={"http://cinemaadhoc.info/wp-content/uploads/2013/06/La-bicicleta-verde-2.jpg"}/>
                        </div>

                    </div>
                </ReactDocMeta>
            </PageLayout>
        );
    }
}


export default Work;