// @flow
import React, {Component} from 'react'
import {Router, Route, Redirect, Switch} from 'react-router-dom'
import './App.css'
import AppHeaderBar from '../AppHeaderBar/AppHeaderBar'
import AppMessageDock  from '../AppMessageDock/AppMessageDock';
import {DanGoyetteRoutes, GraviaGameRoutes, DanGoyetteHomeRoute, GraviaGameGraviaRoute} from '../../routeConfig'
import { createBrowserHistory as createHistory } from 'history';
import AppInfo from '../../appInfo'
import MessageProviderService from '../../Services/MessageProviderService';
import ExternalLink from '../../Components/ExternalLink/ExternalLink';
import AppMessageModel from '../../Models/AppMessageModel';
import Utils from '../../utils';
import AppConstants from "../../appConstants";
import Custom404 from '../../Pages/Common/Custom404/Custom404';

// This component is just the basic navigational structure of the site

class App extends Component {

    componentDidMount() {
        // This is global promise rejection handling, which only works on Chrome.
        window.addEventListener('unhandledrejection', function(event) {
            console.error('Unhandled rejection (promise: ', event.promise, ', reason: ', event.reason, ').');
            MessageProviderService.instance.notify(new AppMessageModel(event.reason.message, event.reason.stack, true));
        });

        window.onerror = function(messageOrEvent, source, lineno, colno, error) {
            console.error('Unhandled error: ', error.message, ', stack: ', error.stack, ').');
            MessageProviderService.instance.notify(new AppMessageModel(error.message, error.stack, true));
        };
    }

    render() {

        let routes = Utils.getApplication() === AppConstants.Applications.DanGoyette ? DanGoyetteRoutes : GraviaGameRoutes;
        let homeRoute = Utils.getApplication() === AppConstants.Applications.DanGoyette ? DanGoyetteHomeRoute : GraviaGameGraviaRoute;
        const browserHistory = createHistory();

        return (
            <div className="App-container">
                <Router history={browserHistory}>
                    <div>
                        <div className="App-content-body">
                            <Switch>
                               {/* <Route path='/' exact render={() => (
                                    <Redirect to={{pathname: homeRoute.path}}/>
                                )}/>*/}

                                 <Route path='/index.html' exact render={() => (
                                    <Redirect to={{pathname: homeRoute.path}}/>
                                )}/>

                                <Route exact path="/" component={homeRoute.component}/>

                                {routes.map((route, i) => (
                                    <Route key={i} path={route.path}
                                           exact={route.exact} component={route.component}/>
                                ))}


                                <Route path="*" component={Custom404} status={404} />
                            </Switch>
                        </div>

                        <AppHeaderBar />

                        <div className="App-footer">
                            <div className="App-footer-content">
                                © 2017-{new Date().getFullYear()} by <ExternalLink url="https://www.dan-goyette.com" showIcon={false}>Dan Goyette</ExternalLink> - Version {AppInfo.AppVersion}
                            </div>
                        </div>

                        <AppMessageDock />
                    </div>
                </Router>
            </div>
        );
    }
}

export default App