// @flow
import React, {Component} from 'react';
import './Consulting.css';
import PageLayout from '../../../Components/PageLayout/PageLayout'
import ExternalLink from '../../../Components/ExternalLink/ExternalLink'
import {default as bgImage} from './Images/Yosemite.jpg';
import {default as danGoyetteImage} from './Images/DanGoyette.jpg';
import {Link} from 'react-router-dom'
import {DanGoyetteCaseStudy_InvestmentCompanyRoute, DanGoyetteCaseStudy_SportingGoodsRoute} from '../../../routeConfig.js';

class Work extends Component {
    render() {
        return (
            <div>
                <PageLayout caption="Consulting" backgroundImage={bgImage} opacity={0.5} grayscale={0.5}>

                    <p>
                        I am the founder of Gravia Software, a software consulting and game development company. I'm currently doing work for <ExternalLink url="http://nevo.com/">Nevo Technologies</ExternalLink>, and working on my game <ExternalLink url="https://www.graviagame.com">Gravia</ExternalLink>.
                    </p>


                    <p>
                        Gravia Software provides the following Software Engineering and Project Management consulting services:
                    </p>

                    <ul>
                        <li>
                            Full-stack software engineering in a range of technologies and platforms:
                            <ul>
                                <li>Languages: C#, Javascript, Java, Perl, Swift</li>
                                <li>UI: KnockoutJS, AngularJS, React, jQuery, ASP.NET, WPF, Typescript, HTML5, CSS3, Sass, Less, Bootstrap</li>
                                <li>Back-end: Microsoft MVC, Microsoft Web API, WCF</li>
                                <li>Database: Microsoft SQL Server (T-SQL), Postgres, Oracle, Sybase, SQLite, Entity Framework, NHibernate</li>
                                <li>IDEs: Visual Studio, XCode, WebStorm</li>
                                <li>General/Other: .NET Framework, Unity3D</li>
                            </ul>
                        </li>
                        <li>
                            Agile Project Management
                            <ul>
                                <li>Initial project requirements exploration</li>
                                <li>Thorough requirements documentation in an issue tracking and wiki system</li>
                                <li>Project management using Agile project methodologies (scrum)</li>
                            </ul>
                        </li>
                    </ul>

                    <p>
                        Gravia Software works primarily remotely, but on-site meetings can be arranged, especially for initial work on a project. Please contact Dan for more information.
                    </p>

                    <h2>People</h2>

                    <img src={danGoyetteImage} alt="Dan Goyette" className="Consulting-person-image" />

                    <h3>Dan Goyette</h3>

                    <p>I have been a professional software engineer for 15 years. I started my career as a developer at
                        an internet service provider in Maine, working primarily on internal tools used within the
                        company. In 2009 I moved to the Boston area, and took a position at <ExternalLink url="http://nevo.com/">Nevo Technologies</ExternalLink>, a custom
                        software shop. At Nevo I had exposure to a wider range of technologies and business domains, and
                        began taking on project lead responsibilities.</p>

                    <p>My full resume is available here: <a href="./Files/Resume.pdf"
                                                            download="Dan Goyette - Resume.pdf">Dan Goyette -
                        Resume.pdf</a>
                    </p>

                    <p>Below are some case studies I have prepared for projects that I have led both as a technical lead
                        and as a project lead.</p>

                    <ul>
                        <li><Link to={DanGoyetteCaseStudy_SportingGoodsRoute.path}>Case Study - A Point of Sale system for a Sporting Goods Company</Link></li>
                        <li><Link to={DanGoyetteCaseStudy_InvestmentCompanyRoute.path}>Case Study - A Workflow Engine and data capture system for an Investment Management Company</Link></li>
                    </ul>

                    <p>
                        I built this site using React, Node/Express, and MongoDB.
                    </p>


                </PageLayout>
            </div>
        );
    }


}

export default Work;
