// @flow
import React, {Component} from 'react';
import './ExternalLink.css';
import {GoLinkExternal} from 'react-icons/go'

class ExternalLink extends Component {
    props: {
        url: string,
        showIcon: boolean,
        children: any,
        title: string
    };


    static defaultProps = {
        showIcon: true
    };


    render() {
        return (
            <a target="_blank" href={this.props.url} rel="noopener noreferrer" title={this.props.title}>
                {this.props.children}
                {this.props.showIcon &&
                <GoLinkExternal style={{marginLeft: "4px", marginBottom: "4px"}}/>
                }

            </a>
        );
    }
}

export default ExternalLink;