// @flow
import React, {Component} from 'react'
import './ModalDialog.css'
import classNames from 'classnames';
import FadeBackground from '../FadeBackground/FadeBackground';


class ModalDialog extends Component {

    props: {
        isOpen: boolean,
        onClose: () => void,
        children: any
    };

    static defaultProps = {
        isOpen: false,
        onClose: () => { }
    };


    state: {
        isClosing: boolean
    };

    constructor(props: any) {
        super(props);
        this.state = {
            isClosing: false
        };
    }


    render() {
        let mainPanelClassnames = classNames('ModalDialog-main-panel', this.props.isOpen ? 'open' : 'closed');

        return (
            <div>
                {this.props.isOpen &&
                    <FadeBackground isClosing={this.state.isClosing} onClick={() => this.handleClick()}/>
                }
                <div className={mainPanelClassnames}>
                    <div>
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }

    handleClick() {
        if (!this.state.isClosing) {
            this.setState({isClosing: true});
            this.props.onClose();

            setTimeout(() => {
                this.setState({isClosing: false});
            }, 500);
        }
    }
}


export default ModalDialog;