// @flow
import React, {Component} from 'react';
import './BlogTagLink.css';
import PortfolioLib from 'goyette-portfolio-lib';
import {Link} from 'react-router-dom'

class BlogTagLink extends Component {
    props: {
        tags: Array<string>,
        tagsCsv: ?string
    };


    static defaultProps = {
        tags: [],
        tagsCsv: null
    };

    state: {
        tags: Array<string>
    };


    constructor(props: $PropertyType<BlogTagLink, 'props'>) {
        super(props);

        this.state = {
            tags: []
        };
    }

    processTags(theProps : $PropertyType<BlogTagLink, 'props'>) {
        let tags = theProps.tags;



        if (theProps.tagsCsv) {
            tags = theProps.tagsCsv.split(",").map((s) => s.trim());
        }

        this.setState({tags: tags});
    }

    componentDidMount() {
        this.processTags(this.props);
    }

    componentWillReceiveProps(newProps : $PropertyType<BlogTagLink, 'props'>) {
        this.processTags(newProps);
    }



    render() {
        return <div style={{display: "inline"}}>

            {this.state.tags && this.state.tags.length > 0 && this.state.tags.map((tag, i) => {
                return <Link key={i}
                             to={PortfolioLib.SharedUtils.replaceRouteParam(PortfolioLib.SharedRoutes.UI.Blog.Home.Path, PortfolioLib.SharedRoutes.UI.Blog.Home.SearchTagParam, tag || "")}>{tag}</Link>
            }).reduce((prev, curr) => [prev, ', ', curr])}
        </div>
    }

}

export default BlogTagLink;