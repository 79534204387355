// @flow
import React, {Component} from 'react';
import './Blog.css';
import PageLayout from '../../../Components/PageLayout/PageLayout'
import {default as BlogComponent} from '../../../Components/Blog/Blog';

class Blog extends Component {
    render() {
        return (
            <PageLayout caption="Blog" showBannerImage={false}>
                <BlogComponent/>
            </PageLayout>
        );
    }
}


export default Blog;