// @flow
import React, {Component} from 'react';
import './Contact.css';
import PageLayout from '../../../Components/PageLayout/PageLayout'
import {default as bgImage} from './Images/Typesetting.jpg';
import {FaGithub} from 'react-icons/fa'
import {FaLinkedin} from 'react-icons/fa'
import {GoMail} from 'react-icons/go'
import ExternalLink from '../../../Components/ExternalLink/ExternalLink';


class Contact extends Component {
    render() {
        return (
            <PageLayout caption="Contact" backgroundImage={bgImage} opacity={0.5} grayscale={0.5}>
                <div className="Contact-item-container">
                    <ContactLink url="mailto:dan.goyette@gmail.com" label="dan.goyette@gmail.com" icon={<GoMail />}/>
                    <ContactLink url="https://github.com/dan-goyette" label="GitHub" icon={<FaGithub />}/>
                    <ContactLink url="https://www.linkedin.com/in/dan-goyette-a9816196" label="LinkedIn"
                                 icon={<FaLinkedin />}/>
                </div>

            </PageLayout>
        );
    }
}

class ContactLink extends Component {
    props: {
        url: string,
        label: string,
        icon: any
    };

    render() {
        return (
            <div className="Contact-item-container">
                <div className="Contact-item">
                    <ExternalLink url={this.props.url} showIcon={false}>
                        <div className="Contact-item-icon">{this.props.icon}</div>
                    </ExternalLink>
                    <p className="Contact-item-label">{this.props.label}</p>
                </div>
            </div>
        );
    }
}

export default Contact;