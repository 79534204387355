import { v4 as uuidv4 } from 'uuid';
import PortfolioLib from 'goyette-portfolio-lib';

const singleton = Symbol();
const singletonEnforcer = Symbol();

/** This class is used to inform other listeners in the application about changes ot the
 * overall authentication status of the current user. Any component can subscribe to this
 * service by passing in a callback. (Components should unsubscribe when unmounting. */
class AuthStatusProviderService {

    constructor(enforcer) {
        if (enforcer !== singletonEnforcer) {
            throw new Error("Cannot construct singleton");
        }

        this._subscribers = [];
        this._authStatusModel = PortfolioLib.AuthStatusModel.Empty;
    }

    static get instance() {
        if (!this[singleton]) {
            this[singleton] = new AuthStatusProviderService(singletonEnforcer);
        }
        return this[singleton];
    }


    _subscribers: Array<void>;
    _authStatusModel: PortfolioLib.AuthStatusModel;


    get subscribers(): Array<any> {
        return this._subscribers;
    }

    set subscribers(value: Array<any>) {
        return this._subscribers = value;
    }

    // No setter...
    get authStatusModel(): PortfolioLib.AuthStatusModel {
        return this._authStatusModel;
    }


    subscribe(callback: () => void): string {
        let guid = uuidv4();
        this.subscribers[guid] = callback;
        //console.debug(`Added AuthStatusProviderService subscription for ${guid}`);
        return guid;
    }

    unsubscribe(key: string) {
        if (key.length > 0) {
            delete this.subscribers[key];
            //console.debug(`Removing AuthStatusProviderService subscription for ${key}`);
        }
    }

    notify(newAuthStatus: PortfolioLib.AuthStatusModel) {
        console.log("Assigning new authStatusModel");
        this._authStatusModel = newAuthStatus;
        for (let guid in this.subscribers) {
            if (this.subscribers.hasOwnProperty(guid)) {
                //console.debug(`Notifying ${guid} of AuthStatusProviderService change.`);
                this.subscribers[guid](newAuthStatus);
            }
        }
    }
}

export default AuthStatusProviderService
