// @flow
import S from 'string';
import AppConstants from './appConstants';
import PortfolioLib from 'goyette-portfolio-lib';
import React from 'react';
import HtmlToReact from 'html-to-react';
import {Link} from 'react-router-dom'
import {TwitterShareButton, FacebookShareButton, FacebookShareCount} from 'react-share';
import {FacebookProvider, Like} from 'react-facebook';
import ExternalLink from './Components/ExternalLink/ExternalLink';
import BlogTagLink from './Components/BlogTagLink/BlogTagLink';
import MessageProviderService from './Services/MessageProviderService';
import AppMessageModel from './Models/AppMessageModel';
import ImagePreview from './Components/ImagePreview/ImagePreview';
import axios from 'axios';

class Utils {
    static disableBodyScroll(): void {
        if (document.body) {
            document.body.classList.add('prevent-scroll');
        }
    }

    static enableBodyScroll(): void {
        if (document.body) {
            document.body.classList.remove('prevent-scroll');
        }
    }

    static getApiRoute(path: string): string {
        let route = S(this.getApiHost()).stripRight('/').s;
        path = S(path).stripLeft('/').s;
        return route + '/' + path;
    }

    static getRawApiRoute(path: string): string {
        let route = S(this.getRawApiHost()).stripRight('/').s;
        path = S(path).stripLeft('/').s;
        return route + '/' + path;
    }


    static getRawApiHost(): string {
        return PortfolioLib.SharedUtils.getSafeEnvValue(AppConstants.Env.REACT_APP_RAW_API_HOST);
    }

    static getApiHost(): string {
        return PortfolioLib.SharedUtils.getSafeEnvValue(AppConstants.Env.REACT_APP_API_HOST);
    }

    static getFacebookAppId(): string {
        return PortfolioLib.SharedUtils.getSafeEnvValue(AppConstants.Env.REACT_APP_FACEBOOK_APP_ID);
    }

    static getFacebookAppSecret(): string {
        return PortfolioLib.SharedUtils.getSafeEnvValue(AppConstants.Env.REACT_APP_FACEBOOK_APP_SECRET);
    }

    static getRssFeedUrl(): string {
        return PortfolioLib.SharedUtils.getSafeEnvValue(AppConstants.Env.REACT_APP_RSS_FEED_URL);
    }

    static getApplication(): string {
        return PortfolioLib.SharedUtils.getSafeEnvValue(AppConstants.Env.REACT_APP_APPLICATION);
    }

    static getPrerenderIOKey(): string {
        return PortfolioLib.SharedUtils.getSafeEnvValue(AppConstants.Env.REACT_APP_PRERENDER_IO_KEY);
    }



    static getCanonicalBlogUrl(postId: ?number, longId: ?string): string {
        let url = PortfolioLib.SharedUtils.replaceRouteParam(PortfolioLib.SharedRoutes.UI.Blog.ViewPost.Path,
            PortfolioLib.SharedRoutes.UI.Blog.ViewPost.PostIdParam,
            postId || 0);
        url = PortfolioLib.SharedUtils.replaceRouteParam(url,
            PortfolioLib.SharedRoutes.UI.Blog.ViewPost.LongIdParam,
            longId || "");

        let urlBase = Utils.getApplication() === AppConstants.Applications.DanGoyette ? "https://www.dan-goyette.com" : "https://www.graviagame.com";
        url = `${urlBase}${url}`;

        return url;
    }


    static renderHtmlAsComponent(postId: ?number, longId: ?string, title: ?string, html: ?string, css: ?string, date: ?Date, tags: Array<string>, bannerImageUrl: ?string): React.Component<*, *, *> {
        // Combine the user-entered CSS and the user-entered HTML into a single string.


        let imageWrapStyle = bannerImageUrl ? `style="background-image: url('${bannerImageUrl || ""}')"` : "";
        let canonicalUrl = Utils.getCanonicalBlogUrl(postId, longId);

        let processedHtml = html || "";

        //let twitterPostTitle = (Utils.getApplication() === AppConstants.Applications.DanGoyette ?  "Dan Goyette - " : "Gravia - ") + (title || "");

        // Perform some processing on the HTML.
        // Replace content between pairs of ` with a 'code-snippet' block. We assume that this won't break
        // up any tags. We also ignore any escaped backticks by first transforming escaped backticks into
        // &#96;
        processedHtml = processedHtml.replace(/\\`/g, "&#96;");

        let tripleBacktickEscapeIndex = 0;
        while (processedHtml.indexOf('```') > 0) {
            processedHtml = processedHtml.replace(/[\r\n]*```[\r\n]*/, tripleBacktickEscapeIndex % 2 === 0
                ? "<div className='code-block'>"
                : "</div>");
            tripleBacktickEscapeIndex++;
        }

        let backtickEscapeIndex = 0;
        while (processedHtml.indexOf('`') > 0) {
            processedHtml = processedHtml.replace('`', backtickEscapeIndex % 2 === 0
                ? "<div className='code-snippet'>"
                : "</div>");
            backtickEscapeIndex++;
        }


        let outputPreview = `
<div>
    <style>${css || ""}</style>
  
    <div class="common-blog-post-banner-image-wrap" ${imageWrapStyle}>
        <div class="common-blog-post-title-wrap desktop"><p class="common-blog-post-title">${title || ""}</p></div>
    </div>  
    
     <div class="common-blog-post-title-wrap mobile"><p class="common-blog-post-title">${title || ""}</p></div>

    <div class="common-blog-post-tags-and-like-wrap">
        <div style="font-style: italic; margin: 0 20px 10px 0; min-height: 30px;">${PortfolioLib.SharedUtils.dateToShortDateString(date) || "?"} - <BlogTagLink tagsCsv="${tags.join(",")}"/></div>
        <div><Like /></div>
        <div><FacebookShareButton >Share</FacebookShareButton></div>
        <div><TwitterShareButton >Tweet</TwitterShareButton></div>
        </div>
        
        
    </div>
    <div style="margin-top: 30px;">${processedHtml}</div>
</div>`;


        let htmlToReactParser = new HtmlToReact.Parser();
        let processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);
        let processingInstructions = [
            {
                // Custom <ExternalLink> processing
                shouldProcessNode: function (node) {
                    return node.name === 'externallink';
                },
                processNode: function (node, children) {
                    let attribs = node.attribs;
                    return <ExternalLink {...attribs}>{children}</ExternalLink>
                }
            },
            {
                // Custom <BlogTagLink> processing
                shouldProcessNode: function (node) {
                    return node.name === 'blogtaglink';
                },
                processNode: function (node, children) {
                    let attribs = node.attribs;
                    return <BlogTagLink tagsCsv={attribs.tagscsv}>{children}</BlogTagLink>
                }
            },
            {
                // Custom <TwitterShareButton> processing
                shouldProcessNode: function (node) {
                    return node.name === 'twittersharebutton';
                },
                processNode: function (node, children) {
                    return <div>
                        <TwitterShareButton url={canonicalUrl} className="twitter-share-button">{children}</TwitterShareButton>
                    </div>
                }
            },
            {
                // Custom <FacebookShareButton> processing
                shouldProcessNode: function (node) {
                    return node.name === 'facebooksharebutton';
                },
                processNode: function (node, children) {
                    return <div>
                        <FacebookShareButton url={canonicalUrl} className="facebook-share-button">{children}</FacebookShareButton>
                    </div>
                }
            },
            {
                // Custom <FacebookShareCount> processing
                shouldProcessNode: function (node) {
                    return node.name === 'facebooksharecount';
                },
                processNode: function (node, children) {
                    return  <FacebookShareCount url={canonicalUrl} className="facebook-share-count" />
                }
            },
            {
                // Custom <Like> processing
                shouldProcessNode: function (node) {
                    return node.name === 'like';
                },
                processNode: function (node, children) {
                    return <FacebookProvider appId={Utils.getFacebookAppId()}>
                        <Like href={canonicalUrl} colorScheme="dark" showFaces size="small" layout="button_count"/>
                    </FacebookProvider>
                }
            },
            {
                // Custom <ImagePreview> processing
                shouldProcessNode: function (node) {
                    return node.name === 'imagepreview';
                },
                processNode: function (node, children) {
                    let attribs = node.attribs;

                    return <ImagePreview caption={attribs.caption} imagePath={attribs.imagepath} thumbnailPath={attribs.thumbnailpath} floatLeft={attribs.floatleft} floatRight={attribs.floatright}/>
                }
            },
            {
                // Custom <Link> processing
                shouldProcessNode: function (node) {
                    return node.name === 'link';
                },
                processNode: function (node, children) {
                    let attribs = node.attribs;

                    // This doesn't really work... There's something odd about it. It's fine if the
                    // <Link> contains only text. But if it contains a tag, this will break. It's
                    // good enough for what I need though...
                    return <Link {...attribs}>{node.next.data ? node.next.data : children}</Link>
                }
            },
            {
                // Anything else
                shouldProcessNode: function (node) {
                    return true;
                },
                processNode: processNodeDefinitions.processDefaultNode
            }
        ];

        console.log(outputPreview);

        // Convert the HTML into a React component
        return htmlToReactParser.parseWithInstructions(outputPreview, () => true,
            processingInstructions);
    }

    static logAndRethrow(err: Error, message: string) {
        let newError = new Error(message);

        let errorResponse = "";
        if (err.response && err.response.data) {
            newError.response = err.response;
            errorResponse = `Server Response:
${err.response.data}
`;
        }

        newError.stack = `${newError.stack}
${errorResponse}
Inner Error:
${err.message}
${err.stack}`;
        console.error(newError.stack);

        throw newError;
    }


    static notifyException(err: Error, message: string) {
        let newError = new Error(message);

        let errorResponse = "";
        if (err.response && err.response.data) {
            newError.response = err.response;
            errorResponse = `Server Response:
${err.response.data}
`;
        }

        newError.stack = `${newError.stack}
${errorResponse}   
Inner Error:
${err.message}
${err.stack}`;
        console.error(newError.stack);
        MessageProviderService.instance.notify(new AppMessageModel(`${message} - ${err.message}`, newError.stack, true));
    }

    static notifyError(message: string) {
        MessageProviderService.instance.notify(new AppMessageModel(message, null, true));
    }

    static notifyMessage(message: string) {
        MessageProviderService.instance.notify(new AppMessageModel(message, null, false));
    }

    static ajaxGet(path: string) {
        return axios.get(path, { withCredentials: true } );
    }

    static ajaxPost(path: string, payload: any) {
        let config = {
            method: 'post',
            url: path,
            data: payload,
            withCredentials: true
        };

        return axios.request(config);
    }
}

export default Utils;
