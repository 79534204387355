// @flow
import React, {Component} from 'react';
import './Privacy.css';
import PageLayout from '../../../Components/PageLayout/PageLayout'
import {DanGoyetteContactRoute, GraviaGameContactRoute} from '../../../routeConfig.js';
import Utils from "../../../utils";
import AppConstants from "../../../appConstants";
import {Link} from "react-router-dom";



class Error extends Component {
    render() {
        let contactUrl = Utils.getApplication() === AppConstants.Applications.DanGoyette ? DanGoyetteContactRoute.path : GraviaGameContactRoute.path;
        return (
            <PageLayout caption="Privacy" showBannerImage={false}>

                <h2>Privacy Notice</h2>

                <p>This site uses Facebook login only to allow site admins to authenticate and log in to the site.
                visitors to the site are not encouraged or required to log in, in any way, in order to view the content
                of this site. If a normal user does decide to log in using the Facebook login portal, this site does not
                use that information in any way, and does not retain any history of user logins.</p>

                <p>In short, the Facebook login here probably isn't intended for you, but we're not using any of the
                information it provides, even if you do decide to use it.</p>

                <p>If you have concerns that some of your personal information is being tracked by this site, you
                can contact me through any of the links on the <Link to={contactUrl}>Contact</Link> page.</p>

                <p>If you choose to contact this site either by sending an email to the Contact address, or by
                submitting feedback and providing your email address in-game, the name and email address you provide
                may be stored. Your email address is potentially used for only two purposes:</p>
                <ul>
                    <li>To send you specific, personal responses to emails and messages you have previously sent to me.
                    For example, if you submit your feedback on Gravia, and you provide your email address, I may write
                    you back at that email address to follow-up on your feedback either with some additional information
                    specifically related to what you wrote, or do answer questions you might have. These emails will
                    always be targetted only to you, and will never be included in any "bulk" email campaigns. In short,
                    if you write to me, there's a good chance I'll write back to you at least once.</li>
                    <li>If you submit feedback while playing Gravia, your email address may be used to identify you
                    internally so I can find and review other feedback you may have submitted.</li>
                </ul>

                <p>Your name and email address will never be sold or given to any other 3rd party, and it won't be used
                in any marketing campaigns for Gravia. If, at any time, you want your email address removed from the
                system, you can simply contact me to let me know, and I'll remove any of that information.</p>
            </PageLayout>
        );
    }

}


export default Error;