// @flow
import React, {Component} from 'react';
import './LinkPanel.css';
import {Link} from 'react-router-dom';
import {LinkPanelItem} from '../../Models/LinkPanelItem';
//import classNames from 'classnames';


class LinkPanel extends Component {
    props: {
        items: Array<LinkPanelItem>
    };

    state: {

    };

    constructor(props: any) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <div className="Support-link-panel-container">

                {this.props.items.map((linkPanelItem, i) => (
                    <Link key={i} rel="canonical" to={linkPanelItem.linkTo}>
                        <div className="LinkPanel-item-wrap">
                            <p className="LinkPanel-item-label">{linkPanelItem.title}</p>
                        </div>

                    </Link>
                ))}

            </div>
        );
    }
}

export default LinkPanel;