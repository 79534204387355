// @flow
import Utils from '../utils';
import PortfolioLib from 'goyette-portfolio-lib';

class MiscService {

    subscribeToGraviaNews( emailAddress : string ) : Promise<boolean> {
        let payload = {userEmailAddress: emailAddress};
        return Utils.ajaxPost(Utils.getApiRoute(PortfolioLib.SharedRoutes.Server.Misc.GraviaNewsSubscribe.Path), payload)
        .catch((err) => {
            Utils.logAndRethrow(err, "Error subscribing to Gravia News");
        });
    }



}

export default MiscService
