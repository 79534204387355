// @flow
import React, {Component} from 'react';
import './Playtesting.css';
import PageLayout from '../../../Components/PageLayout/PageLayout'
import {Link} from 'react-router-dom';
import Utils from '../../../utils';
import ExternalLink from '../../../Components/ExternalLink/ExternalLink';
import {default as bgImage} from './Images/Playtesting.jpg';
import {default as steamActivation} from './Images/SteamActivation.JPG';
import ReactDocMeta from 'react-document-meta';
import {GraviaGameContactRoute} from "../../../routeConfig";
import ImagePreview from '../../../Components/ImagePreview/ImagePreview';


class Playtesting extends Component {

    state: {};


    constructor(props: any) {
        super(props);


        this.state = {};
    }

    render() {
        let canonicalUrl = "https://www.graviagame.com/Playtesting";
        let metaDescription = "Info on the Gravia Playtesting program";
        let metaTags = [
            {name: "description", content: metaDescription},
            {property: "og:title", content: "Gravia - The Playtesting Program"},
            {property: "og:type", content: "website"},
            {property: "og:description", content: metaDescription},
            {property: "og:image", content: "https://www.graviagame.com" + bgImage},
            {property: "og:url", content: canonicalUrl},
            {property: "fb:app_id", content: Utils.getFacebookAppId()}
        ];


        return (
            <PageLayout caption="Playtesting" title="Playtesting" backgroundImage={bgImage}
                        opacity={0.1} grayscale={0.0}>
                <ReactDocMeta tags={metaTags}>
                    <div>
                        <p><em>This page is intended for current Playtesters. If you're interested in becoming an
                            Playtester, please <Link to={GraviaGameContactRoute.path}>Contact Me</Link>.</em></p>

                        <h2>What is Playtesting?</h2>
                        <p>Playtesting is a hugely important part of making Gravia better. It let's me see what people
                            really think of the game. Playtesting is mostly just you playing the game, and sharing your
                            gameplay and feedback with me. I'll watch your videos and use that to improve the game.
                        </p>
                        <p>You can share your gameplay pretty much however you like. Most playtesters either stream on
                            Twitch, upload to YouTube, or share videos files directly via Google Drive or some file
                            sharing service. Whatever you prefer, as long as I can access the videos. There's no NDA on
                            the game, so you're free to do whatever you want with your video content.
                        </p>
                        <p>As far as providing feedback goes, the best way is to just record your voice in the videos,
                            and say what you're thinking as you play the game. You can also share your feedback via
                            email, or on Discord.
                        </p>

                        <h2>How much content is there in Gravia?</h2>
                        <p>The main "Story" mode probably about 6-10 hours of content across about 80 levels. For
                            Playtesting, I'm only looking for videos of your experience in the main portion of the game.
                            But for people looking for more, almost every level contains optional secrets which are much
                            more challenging to find. I don't expect playtesters to provide gameplay videos for that,
                            though, since getting all the secrets probably takes another 20-40 hours (on average).
                        </p>
                        <p>But don't be scared off by big numbers. If you decide to be a playtester, but the game only
                            holds your interest for an hour or two, that's still useful feedback for me. Don't feel like
                            you need to commit to any particular length of time if you want to be a playtester.
                        </p>

                        <h2>Reward?</h2>
                        <p>All playtesters start with a temporary key to play the game, which expires after a few months. But
                            for testers who provide me with a good amount of content, your key will be permanent. So as
                            long as you do a bit of playtesting, you get a free copy of the game.</p>
                        <p>The other "intangible" reward is that your feedback will help to make the game better. Gravia
                            is full of features that simply wouldn't exist if a playtester hadn't shared a cool idea
                            with me. I know a lot of playtesters have enjoyed having their suggestions end up in the
                            game.</p>

                        <h2>How do I sign up?</h2>
                        <p>
                            If this all sounds good to you, click here to <Link to={GraviaGameContactRoute.path}>Contact
                            Me</Link>. You can get in touch via email or Discord, and I'll provide you with your key for
                            testing.
                        </p>

                        <h2>Rules &amp; Guidelines</h2>
                        <p>There are a few "rules" when it comes to Playtesting testing. Hopefully most of this will
                            just seem like common sense, but it's here to make sure we're all on the same page.</p>
                        <ul>
                            <li>Your Playtesting key is assigned only to you. Don't share it with anyone or try to sell
                                it. Playtesting keys just get deactivated when that round of playtesting is over, so
                                trying to sell them is a bad idea...
                            </li>
                            <li>There's no NDA or confidentiality agreement, so you can feel free to stream your
                                experience or share it with others, as long as you clearly identify the name of the
                                game, or include a link back to https://www.graviagame.com. If you end up editing the
                                video for your own purposes, I only ask that you share the original, raw video with me.
                            </li>
                            <li>If you record some cool footage while playing Gravia, I may ask to use it for social
                                media posts, or other publicity. None of your personal information will be revealed (nor
                                will your voice if you're recording your feedback), just the actual gameplay footage.
                            </li>
                        </ul>


                        <h2>Installing Gravia / Technical Details</h2>
                        <p>Start by installing <ExternalLink
                            url={"https://store.steampowered.com/about/"}>Steam</ExternalLink>, if it's not already
                            installed. Steam is free, but you'll need to install it and create an account before you can
                            try Gravia.</p>

                        <p>Open Steam and look at the bottom-left corner of the screen for the "Add a Game"
                            button. Click it, and choose "Activate a Product on Steam..."</p>

                        <div className="Alpha-images-container">
                            <ImagePreview thumbnailPath={steamActivation} imagePath={steamActivation}
                                          caption="Activating via Steam"/>
                        </div>

                        <div className="spacer-row"/>

                        <p>Click through a couple of screens until you reach the screen asking for your Product Code.
                            This is where you enter your "Beta Key". (If you don't have your key yet, message me on
                            Discord.) Enter the key, and you
                            should find that Gravia has been added to your steam Library. If it doesn't install
                            automatically, you can right-click on Gravia in your Library and install it.</p>

                        <h2>Screen Recording</h2>
                        <p>You can use whatever screen recording software you like. I use a free program
                            called <ExternalLink
                                url="https://obsproject.com/download">OBS Studio</ExternalLink> for screen recording.
                            It's quick to set up and easy to use. I can give you more details on getting this set up if
                            you need it.</p>

                        <p>It's up to you whether you want to record the video and upload it to a platform like YouTube,
                            or if you'd prefer to stream the video directly to a platform like Twitch. Either is fine,
                            as long as the video
                            is saved and viewable online for at least a few days so I have a chance to review it. If
                            you're streaming to Twitch, make sure you've checked the option to save your VODs, otherwise
                            they'll get deleted when your stream ends.</p>

                        <p>I <em>highly</em> recommend you do a test recording first to make sure your audio works
                            properly, and that the video saves correctly. One of the most common issues is that
                            playtesters' voices are not recorded with enough volume relative to the game, making it
                            really hard to hear your feedback. You don't want to record a long session only
                            to find out it didn't save, or your audio wasn't recording, or some other problem occurred.
                            So I'd recommend doing a very quick test that the gameplay audio is recording will along
                            with your vocals before recording a longer gameplay video.
                        </p>

                        <p>Discord has some streaming options, but I'm not sure those videos get saved for any
                            meaningful length of time. If this is your first playthrough of a level, please make sure
                            you're recording it in a way that allows you to share the video afterwards so I can watch
                            it.</p>

                    </div>
                </ReactDocMeta>
            </PageLayout>
        );
    }
}


export default Playtesting;