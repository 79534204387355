// @flow
import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom'
import './Blog.css';
import BlogHome from '../../Components/BlogHome/BlogHome';
import BlogAdmin from '../../Components/BlogAdmin/BlogAdmin';
import BlogViewPost from '../../Components/BlogViewPost/BlogViewPost';
import BlogEditPost from '../../Components/BlogEditPost/BlogEditPost';
import BlogAllPosts from '../../Components/BlogAllPosts/BlogAllPosts';
import PortfolioLib from 'goyette-portfolio-lib';


class Blog extends Component {
    props: {};


    static defaultProps = {};

    state: {};


    constructor(props: any) {
        super(props);

        this.state = {};
    }

    render() {
        return (

            <div>
                <Switch>
                    <Route
                        path={PortfolioLib.SharedRoutes.UI.Blog.AllPosts.Path}
                        component={BlogAllPosts}/>

                    <Route
                        path={PortfolioLib.SharedRoutes.UI.Blog.Admin.Path}
                        component={BlogAdmin}/>

                    <Route
                        path={PortfolioLib.SharedRoutes.UI.Blog.ViewPost.Path}
                        component={BlogViewPost}/>
                    <Route
                        path={PortfolioLib.SharedRoutes.UI.Blog.EditPost.Path}
                        component={BlogEditPost}/>
                    <Route exact path={PortfolioLib.SharedRoutes.UI.Blog.Home.Path} component={BlogHome}/>
                </Switch>

            </div>
        );
    }
}

export default Blog;