// @flow
import React, {Component} from 'react';
import './Media.css';
import PageLayout from '../../../Components/PageLayout/PageLayout'
import ImagePreview from '../../../Components/ImagePreview/ImagePreview';
import Utils from '../../../utils';
import YouTubeVideo from '../../../Components/YouTubeVideo/YouTubeVideo';
import {default as bgImage} from './Images/VRHeadset.jpg';
import ReactDocMeta from 'react-document-meta';


class Media extends Component {

    state: {
    };


    constructor(props: any) {
        super(props);


        this.state = {
        };
    }



    render() {
        let canonicalUrl = "https://www.graviagame.com/Media";
        let metaDescription= "Screenshots and Videos of Gravia";
        let metaTags = [
            {name: "description", content: metaDescription},
            {property: "og:title", content: "Gravia - Media"},
            {property: "og:type", content: "website"},
            {property: "og:description", content: metaDescription},
            {property: "og:image", content: "https://www.graviagame.com" + bgImage},
            {property: "og:url", content: canonicalUrl},
            {property: "fb:app_id", content: Utils.getFacebookAppId()}
        ];


        return (
            <PageLayout caption="Media" title="Media" backgroundImage={bgImage} opacity={0.1} grayscale={0.0}>
                <ReactDocMeta tags={metaTags}>
                    <div style={{"width": "100%"}}>

                        <h2>Screenshots</h2>


                        <div className="Media-images-container">
                            <ImagePreview thumbnailPath={require('./Images/EarlyAccess-0.7.0/Thumbs/AirGap_tn.jpg')} imagePath={require('./Images/EarlyAccess-0.7.0/AirGap.jpg')}
                                          caption="Sharp turn required"/>
                            <ImagePreview thumbnailPath={require('./Images/EarlyAccess-0.7.0/Thumbs/Anomaly_Close_tn.jpg')} imagePath={require('./Images/EarlyAccess-0.7.0/Anomaly_Close.jpg')}
                                          caption="The force isn't too bad when it's stable, but those pulses..."/>
                            <ImagePreview thumbnailPath={require('./Images/EarlyAccess-0.7.0/Thumbs/Anomaly_Far_tn.jpg')} imagePath={require('./Images/EarlyAccess-0.7.0/Anomaly_Far.jpg')}
                                          caption="Even far away, you can still feel it a bit."/>
                            <ImagePreview thumbnailPath={require('./Images/EarlyAccess-0.7.0/Thumbs/CenterOfGravity_tn.jpg')} imagePath={require('./Images/EarlyAccess-0.7.0/CenterOfGravity.jpg')}
                                          caption="Close up"/>
                            <ImagePreview thumbnailPath={require('./Images/EarlyAccess-0.7.0/Thumbs/Conveyor_tn.jpg')} imagePath={require('./Images/EarlyAccess-0.7.0/Conveyor.jpg')}
                                          caption="This probably isn't how this was intended to go..."/>
                            <ImagePreview thumbnailPath={require('./Images/EarlyAccess-0.7.0/Thumbs/Countermeasures_tn.jpg')} imagePath={require('./Images/EarlyAccess-0.7.0/Countermeasures.jpg')}
                                          caption="What is that shiny thing sitting there?"/>
                            <ImagePreview thumbnailPath={require('./Images/EarlyAccess-0.7.0/Thumbs/Decomissioned_tn.jpg')} imagePath={require('./Images/EarlyAccess-0.7.0/Decomissioned.jpg')}
                                          caption="Clearly you don't below here"/>
                            <ImagePreview thumbnailPath={require('./Images/EarlyAccess-0.7.0/Thumbs/Destroyer_tn.jpg')} imagePath={require('./Images/EarlyAccess-0.7.0/Destroyer.jpg')}
                                          caption="Rift destroyed..."/>
                            <ImagePreview thumbnailPath={require('./Images/EarlyAccess-0.7.0/Thumbs/DestroyerPosing_tn.jpg')} imagePath={require('./Images/EarlyAccess-0.7.0/DestroyerPosing.jpg')}
                                          caption="Might be useful..."/>
                            <ImagePreview thumbnailPath={require('./Images/EarlyAccess-0.7.0/Thumbs/Explosion_tn.jpg')} imagePath={require('./Images/EarlyAccess-0.7.0/Explosion.jpg')}
                                          caption="Whose idea was it to upt fuel drums in this level?"/>

                            <ImagePreview thumbnailPath={require('./Images/EarlyAccess-0.7.0/Thumbs/FatalRetraction_tn.jpg')} imagePath={require('./Images/EarlyAccess-0.7.0/FatalRetraction.jpg')}
                                          caption="If you can't go through, go around"/>
                            <ImagePreview thumbnailPath={require('./Images/EarlyAccess-0.7.0/Thumbs/FirstGen_tn.jpg')} imagePath={require('./Images/EarlyAccess-0.7.0/FirstGen.jpg')}
                                          caption="This doesn't look like it will end well..."/>
                            <ImagePreview thumbnailPath={require('./Images/EarlyAccess-0.7.0/Thumbs/FogWarning_tn.jpg')} imagePath={require('./Images/EarlyAccess-0.7.0/FogWarning.jpg')}
                                          caption="That steam really fogs up the place, but it makes it easier to see where the Rift is."/>
                            <ImagePreview thumbnailPath={require('./Images/EarlyAccess-0.7.0/Thumbs/Glove_tn.jpg')} imagePath={require('./Images/EarlyAccess-0.7.0/Glove.jpg')}
                                          caption="The Gravia Glove"/>
                            <ImagePreview thumbnailPath={require('./Images/EarlyAccess-0.7.0/Thumbs/GroupEffort_tn.jpg')} imagePath={require('./Images/EarlyAccess-0.7.0/GroupEffort.jpg')}
                                          caption="I'm sure someone will be by soon to fix that up"/>
                            <ImagePreview thumbnailPath={require('./Images/EarlyAccess-0.7.0/Thumbs/HardRain_tn.jpg')} imagePath={require('./Images/EarlyAccess-0.7.0/HardRain.jpg')}
                                          caption="I don't know what it does, but it must to _something_"/>
                            <ImagePreview thumbnailPath={require('./Images/EarlyAccess-0.7.0/Thumbs/HighSpeed_tn.jpg')} imagePath={require('./Images/EarlyAccess-0.7.0/HighSpeed.jpg')}
                                          caption="Beautiful, but dangerous"/>
                            <ImagePreview thumbnailPath={require('./Images/EarlyAccess-0.7.0/Thumbs/Launcher_tn.jpg')} imagePath={require('./Images/EarlyAccess-0.7.0/Launcher.jpg')}
                                          caption="Looks a bit old fashioned to me"/>
                            <ImagePreview thumbnailPath={require('./Images/EarlyAccess-0.7.0/Thumbs/MassDestruction_tn.jpg')} imagePath={require('./Images/EarlyAccess-0.7.0/MassDestruction.jpg')}
                                          caption="Is it me, or is this one a lot bigger than usual?"/>
                            <ImagePreview thumbnailPath={require('./Images/EarlyAccess-0.7.0/Thumbs/MovingTarget_tn.jpg')} imagePath={require('./Images/EarlyAccess-0.7.0/MovingTarget.jpg')}
                                          caption="A little target practice"/>

                            <ImagePreview thumbnailPath={require('./Images/EarlyAccess-0.7.0/Thumbs/Pair_tn.jpg')} imagePath={require('./Images/EarlyAccess-0.7.0/Pair.jpg')}
                                          caption="Two is very often not better than one"/>
                            <ImagePreview thumbnailPath={require('./Images/EarlyAccess-0.7.0/Thumbs/PairFormation_tn.jpg')} imagePath={require('./Images/EarlyAccess-0.7.0/PairFormation.jpg')}
                                          caption="It's been a while since the maintenance crew came through here"/>
                            <ImagePreview thumbnailPath={require('./Images/EarlyAccess-0.7.0/Thumbs/Pipes_tn.jpg')} imagePath={require('./Images/EarlyAccess-0.7.0/Pipes.jpg')}
                                          caption="Bending and breaking, Rifts even absorb the steam"/>
                            <ImagePreview thumbnailPath={require('./Images/EarlyAccess-0.7.0/Thumbs/RealityCheck_tn.jpg')} imagePath={require('./Images/EarlyAccess-0.7.0/RealityCheck.jpg')}
                                          caption="This is generally not something you want to see."/>
                            <ImagePreview thumbnailPath={require('./Images/EarlyAccess-0.7.0/Thumbs/Retro_tn.jpg')} imagePath={require('./Images/EarlyAccess-0.7.0/Retro.jpg')}
                                          caption="What even happened here?"/>
                            <ImagePreview thumbnailPath={require('./Images/EarlyAccess-0.7.0/Thumbs/RocketScience_tn.jpg')} imagePath={require('./Images/EarlyAccess-0.7.0/RocketScience.jpg')}
                                          caption="You could almost reach out and touch that missile... But don't."/>
                            <ImagePreview thumbnailPath={require('./Images/EarlyAccess-0.7.0/Thumbs/SuitYourself_tn.jpg')} imagePath={require('./Images/EarlyAccess-0.7.0/SuitYourself.jpg')}
                                          caption="Rifts can be very useful, if you find a way to control them"/>
                            <ImagePreview thumbnailPath={require('./Images/EarlyAccess-0.7.0/Thumbs/VelocityDampener_tn.jpg')} imagePath={require('./Images/EarlyAccess-0.7.0/VelocityDampener.jpg')}
                                          caption="Sometimes you need to take things slow"/>
                            <ImagePreview thumbnailPath={require('./Images/EarlyAccess-0.7.0/Thumbs/WrittenExam_tn.jpg')} imagePath={require('./Images/EarlyAccess-0.7.0/WrittenExam.jpg')}
                                          caption="Looks like class is cancelled."/>

                        </div>


                        <div className="spacer-row" />


                        <h2>Videos</h2>


                        <h3>Gravia Early Access Trailer (March, 2021)</h3>
                        <YouTubeVideo url="https://www.youtube.com/embed/pvzW-DiIC4k" title="Gravia Early Access Trailer"
                                      classes="Media-embedded-video"/>

                        <div className="spacer-row" />
                        <div className="spacer-row" />
                        <h3>Gravia Demo Trailer (Oct, 2020)</h3>
                        <YouTubeVideo url="https://www.youtube.com/embed/KShrSpoA5c0" title="Gravia Demo Trailer"
                                      classes="Media-embedded-video"/>

                    </div>
                </ReactDocMeta>
            </PageLayout>
        );
    }
}


export default Media;