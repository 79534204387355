// @flow
import React, {Component} from 'react';
import './CaseStudy_InvestmentCompany.css';
import PageLayout from '../../../Components/PageLayout/PageLayout'
import {default as bgImage} from './Images/Longfellow.jpg';
import ImagePreview from '../../../Components/ImagePreview/ImagePreview';
import ExternalLink from '../../../Components/ExternalLink/ExternalLink';

import {default as img1Thumb} from './Images/Thumbs/InvestmentCompany01_tn.jpg';
import {default as img1} from './Images/InvestmentCompany01.jpg';

import {default as img2Thumb} from './Images/Thumbs/InvestmentCompany02_tn.jpg';
import {default as img2} from './Images/InvestmentCompany02.jpg';

import {default as img4Thumb} from './Images/Thumbs/InvestmentCompany04_tn.jpg';
import {default as img4} from './Images/InvestmentCompany04.jpg';

import {default as img5Thumb} from './Images/Thumbs/InvestmentCompany05_tn.jpg';
import {default as img5} from './Images/InvestmentCompany05.jpg';


class CaseStudy_InvestmentCompany extends Component {
    render() {
        return (
            <div>
                <PageLayout caption="Case Study - Investment Management Company" backgroundImage={bgImage} opacity={0.5}
                            grayscale={0.1}>
                    <h2>Summary</h2>
                    <p>An Investment Management Company was maintaining hundreds of individual Excel workbooks to track
                        fund performance and analysis. They wanted an application to consolidate that data, streamline
                        the data entry process, and generate consistent reports. Later, the Company came to us for
                        another
                        major project to manage individual client investment transactions.</p>

                    <h2>Key Challenges</h2>
                    <ul>
                        <li>Complete analysis of fund data was computationally expensive, and required the
                            implementation of a long-running bulk recalculation process.
                        </li>
                        <li>The Workflow Engine needed to support more than twenty distinct workflows which could change
                            over time.
                        </li>
                    </ul>

                    <h2>Technologies</h2>
                    <ul>
                        <li>C# / .NET Framework 4.5.1</li>
                        <li>ASP.NET / MVC Framework / Razor</li>
                        <li>KnockoutJS</li>
                        <li>Entity Framework 5</li>
                        <li>SQL Server 2012</li>
                    </ul>

                    <h2>Approaches and Solutions</h2>

                    <ImagePreview thumbnailPath={img1Thumb} imagePath={img1} floatRight={true}
                                  caption="The primary data entry form for managing fund data."/>
                    <p>This project was divided into two major phases. During the first phase we built the data capture
                        and report generation system. We chose to implement the tool as a web application to
                        simplify deployment, and because we identified no requirements that were better suited for a
                        desktop application. The core system was built using ASP.NET and KnockoutJS for the front-end,
                        MVC Framework on the back-end, and Entity Framework connected to a SQL Server database.</p>

                    <ImagePreview thumbnailPath={img2Thumb} imagePath={img2} floatLeft={true}
                                  caption="Admin functionality to manage users and the very granular permissions the application supported."/>
                    <p>When the first phase concluded, the application supported the ability to search for and manage
                        the analysis of nearly one thousand individual funds. An advanced search interface allowed users to
                        locate funds that met complex criteria. The application was released and immediately adopted by
                        the entire research team at the Company, and was a very welcome improvement over their old
                        Excel-based process.</p>

                    <p>In addition to the funds the Company actively analyzed, they also received feeds from aggregators
                        like Bloomberg, providing details on approximately 50,000 other funds. We built importers to bring
                        this data into the application and used it to allow comparison of funds to their peer groups.
                        This analysis proved complex and computationally expensive. Although we had originally hoped to perform all
                        calculations in real-time as needed, the introduction of this additional data necessitated a
                        background processing task to recompute the results of the fund analysis. </p>

                    <p>The end result of the analysis was a set of reports, rendered as PDF, that were provided to
                        clients of the Company. All reports were generated in HTML and then converted to PDF.</p>

                    <ImagePreview thumbnailPath={img4Thumb} imagePath={img4} floatRight={true}
                                  caption="The dynamic data-entry form used to perform data entry for a transaction."/>
                    <p>Due to the success of the first phase, the Company came back to us about a year later to discuss
                        a new project. The new project involved managing the many workflows the Company followed when handling investor funds. Their
                        existing approach involved using a single shared Excel workbook to record progress on all
                        transactions the Company processed. Each workflow involved an average of 15-20 distinct steps
                        which users needed to perform and flag as complete.</p>

                    <p>We extended their existing web application to include the new functionality. The user interface
                        for the project was more complex than the original project, but we continued to use ASP.NET and
                        KnockoutJS to support it.</p>

                    <ImagePreview thumbnailPath={img5Thumb} imagePath={img5} floatLeft={true}
                                  caption="The Workflow Visualizer, showing a transaction with completed and pending steps."/>
                    <p>The Company had many different workflows depending on the task being performed (for example,
                        purchases, sales, transfers, disbursements, etc). Each workflow had a specific set of fields
                        that had to be provided, and a distinct set of steps that needed to be performed. We built a
                        custom workflow engine for this project. It allowed all workflows to be defined in XML format.
                        These definition files were used to generate dynamic forms through which users could provide
                        required data. We also built a custom workflow visualizer, which showed progress across all
                        steps in the workflow. This visualizer used <ExternalLink
                            url="https://github.com/Microsoft/automatic-graph-layout"
                            showIcon={true}>MSAGL</ExternalLink> to determine the graph layout, and javascript to
                        generate the visual display for each workflow step. Each step in the workflow was interactive,
                        and it was simple and intuitive for users to determine what still needed to be done for any
                        transaction.</p>

                    <p>The workflow engine allowed each workflow to support multiple concurrent versions of each
                        workflow. As changes were introduced to workflows over time, completed transactions remained
                        associated with the older versions of the workflow. This allowed workflows to evolve over time
                        without modifying any historical data associated with completed transactions. Upon releasing a
                        new version of a workflow, we could also choose to migrate any pending transactions to the new
                        workflow version, or keep it pinned to the old version.</p>

                    <p>Ultimately, completing a workflow transaction would trigger the transaction to be included in a
                        report that could be reconciled with the Company's accounting practices and stock purchase/sale
                        provider. The provider hosted a public API for data retrieval, which we used to synchronize all
                        client investment holdings within the system.</p>


                    <div className="clear-both"/>
                    <h2>Outcome</h2>

                    <p>Both projects we implemented for this Company were very successful, and were widely adopted
                        within the Company. The Company has processed over $2 billion in transactions using the workflow
                        engine we built, and continues to provide high quality fund analysis to their clients.</p>

                </PageLayout>
            </div>
        );
    }


}

export default CaseStudy_InvestmentCompany;