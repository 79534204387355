// @flow
import React, {Component} from 'react';
import './Music.css';
import PageLayout from '../../../Components/PageLayout/PageLayout'
import YouTubeVideo from '../../../Components/YouTubeVideo/YouTubeVideo'
import {default as bgImage} from './Images/Bubbles.jpg';
import {Link} from 'react-router-dom'
import {DanGoyetteGameDevRoute} from '../../../routeConfig.js';

class Music extends Component {
    render() {
        return (
            <PageLayout caption="Music" backgroundImage={bgImage} opacity={0.3} grayscale={0.2}>
                <p>I first took piano lessons some time around age 8. I took lessons off and on over the years, but from
                    an early age I was always more interested in writing my own music than I was in being a good
                    pianist. In my first semester of college I enrolled as a music major, intending to study music
                    theory and become a composer. That goal lasted only a few months, as I switched my major, but
                    writing music has been a part of my life since then.</p>

                <p>These days I write all of my music using FL Studio. I don't know if I have a particular musical
                    style. Inspiration comes mainly from humming or whistling to myself while I'm doing other things.
                    When I think of something that sounds interesting, I jot it down in FL Studio, and decide whether
                    to work on it to completion.</p>

                <p>Lately I've been writing music to accompany my <Link to={DanGoyetteGameDevRoute.path}>Video Game
                    Development</Link>, but I continue to work on independent pieces. I don't have any long-term musical
                    goals. It's simply a hobby of mine that I find rewarding.</p>

                <p>Below are some of the pieces and songs I've worked on in the last few years.</p>

                <h2>Instrumental</h2>

                <div className="Music-video-container">
                    <div className="Music-video">
                        <YouTubeVideo url="https://www.youtube.com/embed/6yLmWgwGkrI" title="Nocturne"
                                      classes="Music-embedded-video"/>
                    </div>
                    <div className="Music-video">
                        <YouTubeVideo url="https://www.youtube.com/embed/et3B174kBTE" title="Smooth2"
                                      classes="Music-embedded-video"/>
                    </div>
                    <div className="Music-video">
                        <YouTubeVideo url="https://www.youtube.com/embed/8wcfreOA9hQ" title="Loop5"
                                      classes="Music-embedded-video"/>
                    </div>
                    <div className="Music-video">
                        <YouTubeVideo url="https://www.youtube.com/embed/PKTXrGpNgz4" title="Loop1"
                                      classes="Music-embedded-video"/>
                    </div>


                </div>


                <div style={{marginTop: "20px"}}/>
                <h2>Soundtracks</h2>

                <div className="Music-video-container">

                    <div className="Music-video">
                        <YouTubeVideo url="https://www.youtube.com/embed/px3Eu-F6h6w" title="Cube-Drop"
                                      classes="Music-embedded-video"/>
                    </div>
                </div>


                <div style={{marginTop: "20px"}}/>
                <h2>Songs</h2>
                <div className="Music-video-container">
                    <div className="Music-video">
                        <YouTubeVideo url="https://www.youtube.com/embed/KaW0uZuWkmw" title="TheLastMile"
                                      classes="Music-embedded-video"/>
                    </div>

                    <div className="Music-video">
                        <YouTubeVideo url="https://www.youtube.com/embed/DaJtDL20Wy4" title="LetYouGo"
                                      classes="Music-embedded-video"/>
                    </div>

                </div>


            </PageLayout>
        );
    }
}


export default Music;